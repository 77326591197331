// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../Loader';
import classNames from '../../utils/classNames';

type Props = {
  classes: any,
  children: React.Node,
  isLoading?: boolean,
  hasFilterBar?: boolean,
};

const styles = () => ({
  root: {
    position: 'relative',
    maxHeight: '100%',
  },
  margin: {
    padding: '3.5rem',
  },
  hasFilterBar: {
    padding: '2.5rem 3.5rem',
  },
});

const BaseContentContainer = ({
  classes,
  children,
  isLoading = false,
  hasFilterBar = false,
}: Props) => (
  <div
    className={classNames([
      classes.root,
      hasFilterBar ? classes.hasFilterBar : classes.margin,
    ])}
  >
    {children}

    <Loader isLoading={isLoading} />
  </div>
);

export default withStyles(styles)(BaseContentContainer);
