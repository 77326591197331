// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from '../utils/classNames';

const styles = theme => ({
  circleWrapper: {
    position: 'relative',
    height: '200px',
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '50%',
    textAlign: 'center',
    background: theme.palette.turquoise.basePlus75,
    color: theme.typography.body.color,
    padding: '20px 20px 36px 20px',
    boxSizing: 'border-box',
    transition: 'color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  circlePinned: {
    color: '#ffffff',
    textShadow: '0 1px 0 rgba(0, 0, 0, .1)',
    background: 'linear-gradient(to bottom right, #2ADEC6, #01A1AF)',
    boxShadow: '6px 6px 10px 0 rgba(0, 0, 0, .1)',
  },
  isLoading: {
    color: theme.palette.ambient.basePlus25,
  },
  circleValue: {
    fontSize: '40px',
    fontWeight: '700',
  },
  circleText: {
    margin: '0',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '18px',
  },
  notification: {
    position: 'absolute',
    height: '35px',
    width: '35px',
    right: 0,
    marginTop: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    background: theme.palette.sun.base,
    color: '#fff',
    boxShadow: '0 1px 0 rgba(0, 0, 0, .1)',
    '& span': {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  notificationNumber: {
    fontSize: '12px',
    fontWeight: '500',
  },
});

type Props = {
  classes: Object,
  data: number,
  name?: string,
  unit?: string,
  pinned?: boolean,
  notificationNumber?: number,
  icon: React.Node,
  isLoading?: boolean,
};

const FeatureMonitorCircle = ({
  classes,
  data,
  name = '',
  unit = '',
  pinned = false,
  notificationNumber,
  icon,
  isLoading = false,
}: Props) => (
  <div
    className={classNames([
      classes.circleWrapper,
      pinned ? classes.circlePinned : undefined,
      isLoading ? classes.isLoading : undefined,
    ])}
  >
    {icon}
    {notificationNumber &&
      pinned && (
        <div className={classes.notification}>
          <span>{notificationNumber}</span>
        </div>
      )}
    <span className={classes.circleValue}>{data || 0}</span>
    {(name || unit) && (
      <p className={classes.circleText}>
        {name}
        <br />
        {unit}
      </p>
    )}
  </div>
);

export default withStyles(styles)(FeatureMonitorCircle);
