// @flow

import { sentinelTypes } from '../types/sentinel';
import type { SentinelType } from '../types/sentinel';

export function getSentinelDescription(type: SentinelType) {
  switch (type) {
    case sentinelTypes.TEMPERATURE:
      return 'Monitor the temperature of your equipment and spaces.';
    case sentinelTypes.DOOR:
      return 'Monitor the doors of your equipment and spaces.';
    default:
      throw new Error('Unknown Sentinel Type');
  }
}

export const getSentinelTip = (type: SentinelType) => {
  switch (type) {
    case sentinelTypes.DOOR:
      return 'This configuration requires access sensors.';
    case sentinelTypes.TEMPERATURE:
      return 'This configuration requires temperature sensors.';
    default:
      throw new Error('Unknown Sentinel Type');
  }
};
