// @flow

import type { Equipment } from '../types/equipment';
import {
  EQUIPMENT_BY_ID_SUCCESS,
  EQUIPMENT_SUCCESS,
} from '../actions/equipment';

const initialState: Equipment[] = [];

export default (
  state: Equipment[] = initialState,
  action: { type: string, payload: any }
) => {
  switch (action.type) {
    case EQUIPMENT_SUCCESS: {
      return [...action.payload];
    }

    case EQUIPMENT_BY_ID_SUCCESS: {
      return [...state.filter(e => e.id !== action.payload.id), action.payload];
    }

    /* case EQUIPMENT_STAR_SUCCESS: {
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          pinned: !state[action.payload].pinned,
        },
      };
    }

    case EQUIPMENT_SITE_ID_SUCCESS: {
      return {
        ...state,
        [action.payload.equipmentId]: {
          ...state[action.payload.equipmentId],
          parentSiteId: action.payload.siteId,
        },
      };
    } */

    default: {
      return state;
    }
  }
};
