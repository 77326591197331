// @flow

import * as React from 'react';
import type { UiIcon } from '../../../types/icon';

const ChevronRight = ({ classes = {}, size = 40 }: UiIcon) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={classes.icon}
    width={size}
    height={size}
    viewBox="0 0 12 12"
  >
    <path
      d="M5.6138357 8.85425l-5.4542975-5c-.2127176-.196-.2127176-.512 0-.708.2138085-.195.55852-.195.7723285 0L6 7.79325l5.0681333-4.647c.2138084-.195.55852-.195.7723285 0 .2127176.196.2127176.512 0 .708l-5.4542975 5c-.1069043.097-.2465343.146-.3861643.146s-.27926-.049-.3861643-.146z"
      fill="currentColor"
      transform="rotate(-90 6 6.000125)"
    />
  </svg>
);

export default ChevronRight;
