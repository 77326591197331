// @flow

/* eslint-disable */

import React from 'react';
import IconUiFactory from '../../icons/IconUiFactory';
import styles from './UiChip.module.css';
import className from '../../../utils/classNames';

type Props = {
  label: string,
  iconId: string,
  classes?: string | string[],
  customIconColor?: string,
  size?: number,
  onClick?: Function,
  labelStyle: string,
};

export default ({
  classes,
  label,
  iconId,
  customIconColor,
  onClick,
  size = 16,
  labelStyle,
}: Props) => (
  <div
    onClick={onClick || undefined}
    className={className(classes, styles.iconAndTextGrouping)}
  >
    <span className={className([styles.defaultIconStyle, customIconColor])}>
      <IconUiFactory size={size} id={iconId} />
    </span>
    <span className={labelStyle}> {label} </span>
  </div>
);
