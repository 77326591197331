// @flow

import * as React from 'react';
import Axis from '../Axis/Axis';

type Props = {
  scales: Object,
  margins: Object,
  svgDimensions: Object,
};

const Axes = ({ scales, margins, svgDimensions }: Props) => {
  const { height, width } = svgDimensions;

  const xProps = {
    orient: 'Bottom',
    scale: scales.x0Scale,
    translate: `translate(0, ${height - margins.bottom - margins.top})`,
    tickSize: 0,
  };

  const yProps = {
    orient: 'Left',
    scale: scales.yScale,
    translate: `translate(-8, 0)`,
    tickSize: -width,
  };

  return (
    <g transform={`translate(${margins.left}, ${margins.top})`}>
      <Axis {...xProps} />
      <Axis {...yProps} />
    </g>
  );
};

export default Axes;
