import * as React from 'react';
import { connect } from 'react-redux';
import { userLogout } from '../../actions/user';

export default function(WrappedComponent) {
  return connect(
    null,
    { userLogout }
  )(props => <WrappedComponent {...props} />);
}
