// @flow

import * as React from 'react';
import classNames from '../../utils/classNames';

import styles from './SearchResults.module.css';

type Props = {
  active: boolean,
  children: React.Node,
  color: string,
};

const SearchResults = ({ active, children, color }: Props) => {
  const renderContent = () => (
    <div
      className={classNames(
        !children || children.length === 0 ? styles.noResults : ''
      )}
    >
      {(!children || children.length === 0) && <h4>Sorry, no results found</h4>}
      {(!children || children.length === 0) && (
        <p>Why dont you try another keyword?</p>
      )}
      {children && children}
    </div>
  );

  return active ? (
    <div
      className={classNames(
        styles.wrapper,
        color === 'ambient' ? styles.ambient : null,
        color === 'accent' ? styles.accent : null,
        color === 'shade' ? styles.shade : null
      )}
    >
      {renderContent()}
    </div>
  ) : null;
};

export default SearchResults;
