// @flow

import React from 'react';
import { connect } from 'react-redux';
import { setSiteFilter } from '../../actions/sitesFilter';
import SitesFilterBar from '../../components/Filters/SitesFilterBar';

type Props = {
  error?: boolean,
  setSiteFilter: string => void,
  siteFilterValue?: string,
};

const SitesFilter = (props: Props) => <SitesFilterBar {...props} />;

const mapStateToProps = state => ({
  siteFilterValue: state.siteFilter,
});

const mapDispatchToProps = {
  setSiteFilter,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SitesFilter);
