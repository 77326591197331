// @flow

import type { Sensor } from '../types/sensors';
import { SENSOR_BY_ID_SUCCESS } from '../actions/sensors';

const initialState: Sensor[] = [];

export default (
  state: Sensor[] = initialState,
  action: { type: string, payload: any }
) => {
  switch (action.type) {
    case SENSOR_BY_ID_SUCCESS: {
      return [...state.filter(e => e.id !== action.payload.id), action.payload];
    }

    /* case EQUIPMENT_STAR_SUCCESS: {
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          pinned: !state[action.payload].pinned,
        },
      };
    }

    case EQUIPMENT_SITE_ID_SUCCESS: {
      return {
        ...state,
        [action.payload.equipmentId]: {
          ...state[action.payload.equipmentId],
          parentSiteId: action.payload.siteId,
        },
      };
    } */

    default: {
      return state;
    }
  }
};
