// @flow

import * as React from 'react';
import styles from './FilterMenuItem.module.css';
import Checkbox from '../../../ui/Checkbox';
import getHighlightedText from '../../../../utils/getHightlightedText';

type Props = {
  checked?: boolean,
  onClick: number => void,
  id: number,
  title: string,
  description?: string,
  highlight?: string,
};

const FilterMenuItem = ({
  id,
  title,
  onClick,
  description = '',
  checked = false,
  highlight = '',
}: Props) => (
  <li className={styles.listWrapper}>
    <Checkbox
      checked={checked}
      className={styles.checkbox}
      size={16}
      onChange={() => onClick(id)}
      fullWidth
    >
      <div className={styles.textWrapper}>
        <span className={styles.title}>
          {getHighlightedText(title, highlight, styles.highlightedText)}
        </span>
        {description && (
          <span className={styles.description}>
            {getHighlightedText(description, highlight, styles.highlightedText)}
          </span>
        )}
      </div>
    </Checkbox>
  </li>
);

export default FilterMenuItem;
