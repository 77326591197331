// @flow

import * as React from 'react';
import FeatureMonitorCircleList from '../../../components/lists/FeatureMonitorCircleList';
import Card from '../../../components/cards/Card';
import CardList from '../../../components/lists/CardList';
import CardTitle from '../../../components/cards/CardTitle';
import IconServicesSavings from '../../icons/IconServicesSavings';
import IconServicesEnergy from '../../icons/IconServicesEnergy';
import IconServicesTotalOperatingTime from '../../icons/IconServicesTotalOperatingTime';
import IconServicesCharge from '../../icons/IconServicesCharge';
import FeatureMonitorCircle from '../../FeatureMonitorCircle';
import SunBurst from '../../charts/SunBurst';
import Chart from '../../charts/Chart';
import PieChart from '../../charts/PieChart';

const ViewSiteMonitor = () => (
  <React.Fragment>
    <div>
      <FeatureMonitorCircleList>
        <FeatureMonitorCircle
          icon={<IconServicesEnergy />}
          data={37.2}
          name="Av. energy consumption"
          unit="kWh/month"
        />
        <FeatureMonitorCircle
          icon={<IconServicesCharge />}
          data={10.6}
          name="CO2 emissions"
          unit="kg / month"
        />
        <FeatureMonitorCircle
          icon={<IconServicesTotalOperatingTime />}
          data={87}
          name="of Tariff Limit"
          unit="March 2018"
        />
        <FeatureMonitorCircle
          icon={<IconServicesSavings />}
          data={124}
          name="Savings"
          unit="$/month"
        />
      </FeatureMonitorCircleList>

      <CardList>
        <Card flex>
          <CardTitle
            title="HVAC Ranking"
            description="Least energy consuming equipment"
          />
          <PieChart
            data={[
              {
                name: 'Dispenser',
                value: 111.25,
              },
              {
                name: 'Freezers',
                value: 111.25,
              },
              {
                name: 'HVAC',
                value: 318.5,
              },
              {
                name: 'Other',
                value: 222.5,
              },
              {
                name: 'Food Processing',
                value: 180.2,
              },
              {
                name: 'Lightings',
                value: 56.3,
              },
            ]}
          />
        </Card>
        <Card>
          <CardTitle title="Energy Consumption" description="2017 vs. 2018" />
          <Chart
            data={{
              Jan: {
                '2017': 255,
                '2018': 199,
              },
              Feb: {
                '2017': 119,
                '2018': 165,
              },
              Mar: {
                '2017': 288,
                '2018': 344,
              },
              Apr: {
                '2017': 110,
                '2018': 377,
              },
              May: {
                '2017': 233,
                '2018': 320,
              },
              Jun: {
                '2017': 384,
                '2018': 146,
              },
              Jul: {
                '2017': 123,
                '2018': 255,
              },
              Aug: {
                '2017': 213,
                '2018': 241,
              },
              Sep: {
                '2017': 243,
                '2018': 343,
              },
              Oct: {
                '2017': 225,
                '2018': 353,
              },
              Nov: {
                '2017': 215,
                '2018': 251,
              },
              Dec: {
                '2017': 143,
                '2018': 303,
              },
            }}
          />
        </Card>

        <Card>
          <CardTitle title="Energy Consumption" description="by Spaces" />
          <SunBurst
            data={{
              nodes: {
                name: 'root',
                children: [
                  {
                    name: 'Building B',
                    children: [
                      {
                        name: 'HVAC',
                        children: [
                          { name: 'Ceiling Fan', size: 256 },
                          { name: 'Other', size: 768 },
                        ],
                      },
                      {
                        name: 'Lighting',
                        size: 768,
                      },
                    ],
                  },
                  {
                    name: 'Building G',
                    children: [
                      {
                        name: 'HVAC',
                        size: 512,
                        children: [{ name: 'Ceiling Fan', size: 128 }],
                      },
                      { name: 'Lighting', size: 64 },
                    ],
                  },
                  {
                    name: 'Building R',
                    children: [
                      { name: 'HVAC', size: 64 },
                      { name: 'Lighting', size: 32 },
                    ],
                  },
                  {
                    name: 'Building YG',
                    children: [{ name: 'HVAC', size: 512 }],
                  },
                ],
              },
            }}
          />
        </Card>
      </CardList>
    </div>
  </React.Fragment>
);

export default ViewSiteMonitor;
