// @flow

import * as React from 'react';
import classNames from '../../../utils/classNames';
import styles from './SubTab.module.css';

type Props = {
  label: string,
  isActive?: boolean,
  disabled?: boolean,
  onClick: Function,
};

const SubTab = ({ onClick, label, isActive, disabled = false }: Props) => (
  <button
    className={classNames([
      styles.root,
      isActive ? styles.isActive : undefined,
      disabled ? styles.disabled : undefined,
    ])}
    role="tab"
    onClick={() => onClick()}
    disabled={disabled}
  >
    <span>{label}</span>
  </button>
);

export default SubTab;
