// @flow

import React from 'react';

import styles from './TitleChip.module.css';

type Props = {
  primaryText: string,
  secondaryText?: string,
};

export default ({ primaryText, secondaryText }: Props) => (
  <div className={styles.grouping}>
    <span className={styles.text}>
      <div>{primaryText}</div>
      {secondaryText && (
        <div className={styles.secondaryText}>{secondaryText}</div>
      )}
    </span>
  </div>
);
