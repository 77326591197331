// @flow

import { SET_SITE_FILTER } from '../actions/sitesFilter';

const initialState = '';
type State = string;

export default (
  state: State = initialState,
  action: { type: string, payload: any }
) => {
  switch (action.type) {
    case SET_SITE_FILTER: {
      return action.payload;
    }
    default:
      return state;
  }
};
