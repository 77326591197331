import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store';
import withTheme from './withTheme';
import { loadState } from './middleware/localStorage';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import App from './containers/App';
// import { serviceWorkerDidUpdate } from './actions/app';
import { userLoginFromLocalStorage } from './actions/user';
import './styles';

const preloadedState = loadState();

// eslint-disable-next-line import/prefer-default-export
export const store = configureStore(preloadedState);

// Enable this line to persist the redux-store in localStorage
// store.subscribe(() => saveState(store.getState()));

// login user on first load
try {
  store.dispatch(userLoginFromLocalStorage());
} catch (err) {
  // access to window is blocked?
}

const AppWithTheme = withTheme(App);

ReactDOM.render(
  <Provider store={store}>
    <AppWithTheme />
  </Provider>,
  document.getElementById('root')
);

unregister();
