// @flow

import React from 'react';
import IconUiFactory from '../../icons/IconUiFactory';
import styles from './ActivityChip.module.css';
import {
  fetchUnseenNotificationCountByResourceId,
  fetchAllUnseenNotificationCount,
} from '../../../actions/notifications';
import type { NotificationCountTypes } from '../../../types/notification';

type Props = {
  size?: number,
  type: NotificationCountTypes,
  resourceId: number,
};

type State = {
  count: number,
  isLoading: boolean,
};

export default class ActivityChip extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      count: 0,
    };
  }

  componentDidMount() {
    this.getCount();
    // this.intervalFunction = setInterval(this.getCount, 15000);
  }

  componentWillUnmount() {
    // window.clearInterval(this.intervalFunction);
  }

  getCount = () => {
    if (this.props.type === 'all') {
      fetchAllUnseenNotificationCount()
        .then(res => {
          this.setState({ count: res.count, isLoading: false });
        })
        .catch(() => {
          // console.log(err);
        });
    } else
      fetchUnseenNotificationCountByResourceId(
        this.props.resourceId,
        this.props.type
      )
        .then(res => {
          this.setState({ count: res.count, isLoading: false });
        })
        .catch(() => {
          // console.log(err);
        });
  };
  // intervalFunction: any;

  render() {
    const { size = 25 } = this.props;
    const { count, isLoading } = this.state;

    return (
      <div className={styles.iconAndTextGrouping}>
        <span className={styles.iconStyle}>
          <IconUiFactory size={size} id="notification" />
          {!isLoading && <div className={styles.numberCircle}>{count}</div>}
        </span>
      </div>
    );
  }
}
