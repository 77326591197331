// @flow

import * as React from 'react';
import Loadable from 'react-loadable';
import CircularProgress from '@material-ui/core/CircularProgress';
import { sentinelTypes } from '../../types/sentinel';
import type { SensorIcon } from '../../types/icon';
import { sensorType } from '../../types/sensors';

const Loading = ({ size = 40 }: { size: number }) => (
  <CircularProgress size={size} />
);

const IconHumiditySensor = Loadable({
  loader: () => import('./sensors/IconHumiditySensor'),
  loading: Loading,
});

const IconTemperatureSensor = Loadable({
  loader: () => import('./sensors/IconTemperatureSensor'),
  loading: Loading,
});

const IconDoorSensor = Loadable({
  loader: () => import('./sensors/IconDoorSensor'),
  loading: Loading,
});

const IconHubOffline = Loadable({
  loader: () => import('./sensors/IconHubOffline'),
  loading: Loading,
});

const IconAmbient = Loadable({
  loader: () => import('./sensors/IconAmbient'),
  loading: Loading,
});

export default ({ classes, size, id, parameters }: SensorIcon) => {
  switch (id) {
    case sentinelTypes.TEMPERATURE:
    case sensorType.TEMPERATURE:
      return <IconTemperatureSensor classes={classes} size={size} />;

    case sensorType.HUMIDITY:
    case sentinelTypes.HUMIDITY:
      return (
        <IconHumiditySensor
          classes={classes}
          size={size}
          parameters={parameters}
        />
      );

    case sensorType.BINARY:
    case sentinelTypes.DOOR:
      return (
        <IconDoorSensor classes={classes} size={size} parameters={parameters} />
      );

    case 'hubOffline':
      return <IconHubOffline classes={classes} size={size} />;

    case 'ambient':
      return <IconAmbient classes={classes} size={size} />;

    default:
      return null;
  }
};
