// @flow
import { normalize } from 'normalizr';
import fetchApi from '../middleware/api';
import { samplesSchema } from '../normalize';
import { services } from '../config';

export type GetSamples = {
  siteId?: string,
  equipmentId?: string,
  equipmentGroupIds?: string[],
  timeFrom: number,
  timeTo: number,
  aggregation: string,
  typesOfEnergy?: string[],
  mainDistributionPanelsOnly?: boolean,
};

const fetchSamples = async (queryParams: string) =>
  fetchApi({
    endpoint: `/api/v1/samples?${queryParams}`,
  });

const fetchLinkedSamples = async (
  queryParams: string,
  prevResponse: { data: [] },
  iteration: number
) => {
  const response = await fetchSamples(queryParams);
  if (response.links && response.links.next && iteration < 6) {
    const nextQueryParams = response.links.next.split('?')[1];
    return fetchLinkedSamples(
      nextQueryParams,
      {
        data: [...prevResponse.data, ...response.data],
      },
      iteration + 1
    );
  }

  return {
    data: [...prevResponse.data, ...response.data],
  };
};

export const fetchOperationalSamples = async (queryParams: string) =>
  fetchApi({
    endpoint: `/v3/operational_sensors?${queryParams}`,
    service: services.tsd,
  });

export const getLastSample = (samples: {
  data?: [{ attributes: any }],
}): any => {
  if (!samples.data) {
    return {};
  }

  const { data } = samples;

  if (data.length > 0) {
    return data[data.length - 1].attributes;
  }

  return {};
};

export const getSamples = ({
  siteId,
  equipmentId,
  equipmentGroupIds = [],
  mainDistributionPanelsOnly,
  timeFrom,
  timeTo,
  aggregation,
  typesOfEnergy = ['energy'],
}: GetSamples) => async (): Promise<any[]> => {
  try {
    const queryParams = new URLSearchParams();
    if (siteId) {
      queryParams.set('filter[site]', siteId);
    }

    if (equipmentGroupIds.length) {
      queryParams.set('filter[equipment_group]', equipmentGroupIds.join(','));
    }

    if (mainDistributionPanelsOnly) {
      queryParams.set(
        'filter[mdp]',
        (mainDistributionPanelsOnly === true).toString()
      );
    }

    if (equipmentId) {
      queryParams.set('filter[equipment]', equipmentId);
    }

    const responseData = { data: [] };

    queryParams.set('aggregation_level', aggregation);
    queryParams.set('filter[from]', timeFrom.toString());
    queryParams.set('filter[to]', timeTo.toString());
    queryParams.set('fields[samples]', `timestamp,${typesOfEnergy.join(',')}`);

    const response = await fetchLinkedSamples(
      queryParams.toString(),
      responseData,
      0
    );

    const samples =
      normalize(response.data, [samplesSchema]).entities.samples || {};
    return Object.values(samples);
  } catch (err) {
    // todo: log / return err
    return [];
  }
};

/*
const sensorsToEquipment = (equipment: Equipment[]) =>
  equipment.reduce((obj, next: Equipment) => {
    // $FlowFixMe
    next.sensors.forEach(id => {
      if (!obj[id]) {
        obj[id] = next.id; // eslint-disable-line no-param-reassign
      }
    });

    // $FlowFixMe
    next.value = 0; // eslint-disable-line no-param-reassign
    return obj;
  }, {});
*/

/*
export const getLastMonthBySiteIdAndEquipmentGroupIds = async (
  siteId: string,
  equipmentGroupIds: string[]
): Promise<any> => {
  const timeFrom = dayjs()
    .startOf('month')
    .subtract(1, 'month')
    .unix();
  const timeTo = dayjs()
    .startOf('month')
    .unix();

  const aggregation = 'months_1';

  const [samples, equipment] = await Promise.all([
    getSamples({
      siteId,
      equipmentGroupIds,
      timeFrom,
      timeTo,
      aggregation,
    })(), // $FlowFixMe
    getEquipmentBySiteIdAndEquipmentGroupIds(siteId, equipmentGroupIds),
  ]);

  // $FlowFixMe
  const sensorsToEquipmentRef = sensorsToEquipment(Object.values(equipment));

  samples.forEach(samplesGroup =>
    samplesGroup.energy.forEach(sample => {
      const equipmentId = sensorsToEquipmentRef[sample.sensor_id];
      equipment[equipmentId].value += sample.value;
    })
  );

  return equipment;
}; */

/*
export const sunburstDataBySiteId = async (siteId: string): Promise<any> => {
  const timeFrom = dayjs()
    .startOf('month')
    .subtract(1, 'month')
    .unix();
  const timeTo = dayjs()
    .startOf('month')
    .unix();

  const aggregation = 'months_1';

  const [samples, spacesAndEquipmentResponse] = await Promise.all([
    getSamples({
      siteId,
      timeFrom,
      timeTo,
      aggregation,
    })(),
    // $FlowFixMe
    fetchAllSpacesAndEquipmentBySiteId(siteId),
  ]);

  const { spaces, equipment } = getSpacesAndEquipmentFromResponse(
    spacesAndEquipmentResponse
  );

  // $FlowFixMe
  const sensorsToEquipmentRef = sensorsToEquipment(Object.values(equipment));

  samples.forEach(samplesGroup =>
    samplesGroup.energy.forEach(sample => {
      const equipmentId = sensorsToEquipmentRef[sample.sensor_id];
      equipment[equipmentId].value += sample.value;
    })
  );

  return { spaces, equipment };
}; */

/*

export const pieChartDataBySiteId = async (siteId: string): Promise<any> => {
  const timeFrom = dayjs()
    .startOf('month')
    .subtract(1, 'month')
    .unix();
  const timeTo = dayjs()
    .startOf('month')
    .unix();

  const aggregation = 'months_1';

  const { equipment } = getSpacesAndEquipmentFromResponse(
    await fetchAllSpacesAndEquipmentBySiteId(siteId)
  );

  const samples = await getSamples({
    siteId,
    timeFrom,
    timeTo,
    aggregation,
  })();

  // $FlowFixMe
  const sensorsToEquipmentRef = sensorsToEquipment(Object.values(equipment));

  samples.forEach(samplesGroup =>
    samplesGroup.energy.forEach(sample => {
      const equipmentId = sensorsToEquipmentRef[sample.sensor_id];
      equipment[equipmentId].value += sample.value;
    })
  );

  return { equipment };
};
*/
