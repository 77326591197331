// @flow

import * as React from 'react';
import classNames from '../../utils/classNames';

import styles from './SearchInput.module.css';
import SearchIcon from '../icons/ui/Search';

type Props = {
  active: boolean,
  color?: 'ambient' | 'accent' | 'shade',
  onFocus: Function,
  onChange: Function,
  value: string,
  placeholder: string,
  error?: boolean,
};

const SearchInput = ({
  active = false,
  color,
  onFocus,
  onChange,
  value,
  placeholder,
  error = false,
}: Props) => (
  <div
    className={classNames(
      styles.wrapper,
      color === 'ambient' ? styles.ambient : null,
      color === 'accent' ? styles.accent : null,
      color === 'shade' ? styles.shade : null,
      active ? styles.open : null,
      value && error ? styles.error : null,
      value && !error ? styles.valid : null
    )}
  >
    <div className={styles.iconWrapper}>
      <SearchIcon size={16} />
    </div>
    <input
      className={styles.searchInput}
      type="text"
      placeholder={placeholder}
      onFocus={onFocus}
      value={value}
      onChange={onChange}
    />
  </div>
);

export default SearchInput;
