// @flow

import * as React from 'react';

type Props = {
  size: number,
};

export default ({ size, ...rest }: Props) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    {...rest}
  >
    <path
      fill="currentColor"
      d="M8 1a7 7 0 1 1-7 7 7 7 0 0 1 7-7m0-1a8 8 0 1 0 8 8 8 8 0 0 0-8-8z"
    />
  </svg>
);
