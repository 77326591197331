// @flow

function rangeBetween(value: number, min: number, max: number): boolean {
  return value >= min && value < max;
}

export default function(volt: number) {
  if (typeof volt !== 'number' || isNaN(volt)) return undefined; // eslint-disable-line no-restricted-globals

  if (volt >= 2.58) {
    return 100;
  } else if (rangeBetween(volt, 2.42, 2.58)) {
    return 75;
  } else if (rangeBetween(volt, 2.19, 2.42)) {
    return 50;
  } else if (rangeBetween(volt, 1.45, 2.19)) {
    return 25;
  }

  return 0;
}
