// @flow

import * as React from 'react';
import styles from './Radio.module.css';
import classNames from '../../../utils/classNames';

import IconRadio from '../../icons/ui/IconRadio';
import IconUnchecked from '../../icons/ui/IconUnchecked';

type Props = {
  id?: string,
  name?: string,
  children?: React.Node,
  className?: string,
  onChange: any => void,
  checked?: boolean,
  label?: string,
  size?: number,
  fullWidthLabel?: boolean,
  fullWidth?: boolean,
  disabled?: boolean,
};

function Radio(props: Props) {
  const {
    id,
    name,
    children,
    className,
    label,
    onChange,
    checked = false,
    size = 16,
    fullWidthLabel = false,
    fullWidth = false,
    disabled = false,
  } = props;

  return (
    <label
      htmlFor={id}
      className={classNames(
        styles.labelWrapper,
        className,
        fullWidth ? styles.fullWidth : undefined,
        disabled ? styles.disabled : undefined
      )}
    >
      <input
        id={id}
        className={styles.input}
        type="checkbox"
        name={name}
        onChange={onChange}
        disabled={disabled}
        checked={checked}
      />

      {checked ? (
        <IconRadio size={size} className={styles.icon} />
      ) : (
        <IconUnchecked size={size} className={styles.icon} />
      )}

      {label && (
        <span
          className={classNames(
            styles.label,
            fullWidthLabel ? styles.fullWidthLabel : ''
          )}
        >
          {label}
        </span>
      )}

      {children}
    </label>
  );
}

export default Radio;
