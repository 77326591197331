// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';

type Props = {
  classes: any,
  children: React.Node,
};

const styles = () => ({
  root: {
    display: 'grid',
    gridGap: '2.5rem',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
});

const CardList = ({ classes, children }: Props) => (
  <div className={classes.root}>{children}</div>
);

export default withStyles(styles)(CardList);
