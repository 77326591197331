// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import type { User } from '../../../types/user';
import styles from './UserCard.module.css';
import { getRoleTitle } from '../../../types/user';
import { deriveDateValue } from '../../../utils/dates';

type Props = {
  user: User,
  currentUser: User,
};

const UserCard = ({ user, currentUser }: Props) => (
  <div className={styles.card}>
    <div className={styles.icon}>
      <svg
        viewBox="0 0 32 32"
        height="60"
        width="60"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="currentColor">
          <path d="m32 26.61a8.66 8.66 0 0 0 -7.06-6.1h-.33l-.17.29a7.47 7.47 0 0 1 -2 2.12 8.35 8.35 0 0 1 -1.78-3.72c0-.12-.06-.22-.08-.29a.5.5 0 0 0 -.09-.17 8 8 0 0 0 3.51-6.74v-4a8 8 0 0 0 -16 0v4a8.62 8.62 0 0 0 2.34 5.66 7.92 7.92 0 0 0 1.27 1 .5.5 0 0 0 -.09.17l-.08.29a8.33 8.33 0 0 1 -1.78 3.72 7.47 7.47 0 0 1 -2.05-2.12l-.17-.29h-.33a8.66 8.66 0 0 0 -7.06 6.1.5.5 0 0 0 1 .3 7.68 7.68 0 0 1 5.95-5.28c1.68 2.53 5.5 4.45 9 4.45s7.34-1.88 9.06-4.41a7.68 7.68 0 0 1 5.94 5.32.5.5 0 0 0 .48.35h.15a.5.5 0 0 0 .37-.65zm-23-14.61v-4a7 7 0 0 1 13.5-2.65 8.12 8.12 0 0 1 -.86.25l-.46.09c-2.88.46-6.23-.41-7.79-2a.5.5 0 0 0 -.72.69 9.93 9.93 0 0 0 6.9 2.46 11.14 11.14 0 0 0 1.77-.14l.51-.09a9.13 9.13 0 0 0 1-.28 7 7 0 0 1 .15 1.67v4a7 7 0 0 1 -7 7 8.23 8.23 0 0 1 -2.62-.51 7.14 7.14 0 0 1 -2.3-1.49 7.62 7.62 0 0 1 -2.08-5zm1.51 11.42a9.48 9.48 0 0 0 1.9-4l.06-.22c.24.12.43.2.54.24a9.18 9.18 0 0 0 2.99.56 8 8 0 0 0 3.53-.83l.06.22a9.5 9.5 0 0 0 1.9 4 11.19 11.19 0 0 1 -5.49 1.61 11.19 11.19 0 0 1 -5.46-1.58z" />
          <path d="m25 30h-5a.5.5 0 0 0 0 1h5a.5.5 0 1 0 0-1z" />
        </g>
      </svg>
    </div>

    <div className={styles.title}>
      {user.firstName} {user.lastName}
    </div>

    <div className={styles.role}>{user.role && getRoleTitle(user.role)}</div>
    <div className={styles.extra}>
      {`Last Login: ${
        user.lastLoginAt
          ? deriveDateValue(user.lastLoginAt, false, currentUser)
          : 'The user has not logged in yet'
      }`}
    </div>
  </div>
);

const mapStateToProps = state => ({
  currentUser: state.app.user,
});

export default connect(
  mapStateToProps,
  null
)(UserCard);
