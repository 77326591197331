// @flow

import * as React from 'react';

type Props = {
  classes: any,
  height: number,
  width: number,
};

export default ({ classes = {}, height = 40, width = 40 }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    className={classes.icon}
    width={height}
    height={width}
  >
    <g>
      <path
        style={{ opacity: '0.1' }}
        d="M36.71,18.42a1.0078,1.0078,0,0,0-1.42,0l-.47.47A14.9929,14.9929,0,0,0,5,21.12a15.1965,15.1965,0,0,0,1.24,6.06,6.9854,6.9854,0,0,0,10.88,8.67,15.123,15.123,0,0,0,2.88.27c2.8,0,9.02-1.34,12.53-6.43a1,1,0,1,0-1.65-1.13c-3.03,4.4-8.44,5.56-10.88,5.56a14.1845,14.1845,0,0,1-1.61-.1c.02-.04.05-.08.07-.13A6.99,6.99,0,0,0,7.85,25.44a.9021.9021,0,0,1-.1.09A13.2976,13.2976,0,0,1,7,21.12a12.9964,12.9964,0,0,1,25.71-2.7,1.0141,1.0141,0,0,0-1.42,0,.9959.9959,0,0,0,0,1.41l2,2,.71.71,2.71-2.71A.9957.9957,0,0,0,36.71,18.42ZM10.68,30.26l-1.45-2.4a.5078.5078,0,0,1-.1-.55A.4886.4886,0,0,1,9.59,27h5a.5081.5081,0,0,1,.5.5v.84a.5081.5081,0,0,1-.5.5.5014.5014,0,0,1-.5-.5V28h-3.3l1.39,2.65a.495.495,0,0,1,0,.71l-.07.14-.95,1.8-.37.7h3.3v-.33a.4951.4951,0,0,1,.5-.5.5015.5015,0,0,1,.5.5v.83a.5015.5015,0,0,1-.5.5h-5a.5033.5033,0,0,1-.36-.85l.96-1.6L11.12,31Z"
      />
      <path
        style={{ opacity: '0.1' }}
        d="M20.19,10.938a.5.5,0,0,0-.5.5v7.55a2.5,2.5,0,1,0,2.95,2.95h5.5654a.485.485,0,0,0,.485-.485v-.03a.485.485,0,0,0-.485-.485H22.64a2.5023,2.5023,0,0,0-1.95-1.95v-7.55A.5.5,0,0,0,20.19,10.938Zm1.5,10.5a1.5,1.5,0,1,1-1.5-1.5A1.5017,1.5017,0,0,1,21.69,21.438Z"
      />
    </g>
    <g>
      <path
        style={{ fill: 'currentColor' }}
        d="M33.71,15.29a1.0078,1.0078,0,0,0-1.42,0l-.47.48A14.9929,14.9929,0,0,0,2,18a15.1873,15.1873,0,0,0,1.68,6.99A7.0656,7.0656,0,0,0,3,28a6.9874,6.9874,0,0,0,12.02,4.86A16.0425,16.0425,0,0,0,17,33c2.8,0,9.02-1.34,12.53-6.43a1.0028,1.0028,0,0,0-1.65-1.14C24.85,29.84,19.44,31,17,31c-.23,0-.45-.02-.67-.03.01-.03.03-.05.04-.08a6.985,6.985,0,0,0-10.6-8.45,6.8736,6.8736,0,0,0-.76.67A13.268,13.268,0,0,1,4,18a12.9976,12.9976,0,0,1,25.71-2.71,1.0041,1.0041,0,0,0-1.42,1.42l2,2,.71.7.71-.7,2-2A1.0079,1.0079,0,0,0,33.71,15.29ZM6.93,24.67a.5078.5078,0,0,1-.1-.55.4886.4886,0,0,1,.46-.31h5a.5081.5081,0,0,1,.5.5v.84a.5081.5081,0,0,1-.5.5.5014.5014,0,0,1-.5-.5v-.34H8.49l1.39,2.65a.495.495,0,0,1,0,.71L8.49,30.81h3.3v-.33a.4951.4951,0,0,1,.5-.5.5015.5015,0,0,1,.5.5v.83a.5015.5015,0,0,1-.5.5h-5a.5033.5033,0,0,1-.36-.85l1.89-3.15Z"
      />
      <path
        style={{ fill: 'currentColor' }}
        d="M17.1907,7.8135a.5.5,0,0,0-.5.5v7.55a2.5,2.5,0,1,0,2.95,2.95h5.5653a.4851.4851,0,0,0,.4851-.485v-.03a.485.485,0,0,0-.4851-.485H19.64a2.5022,2.5022,0,0,0-1.95-1.95v-7.55A.5.5,0,0,0,17.1907,7.8135Zm1.5,10.5a1.5,1.5,0,1,1-1.5-1.5A1.5017,1.5017,0,0,1,18.6907,18.3135Z"
      />
    </g>
  </svg>
);
