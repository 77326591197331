// @flow
import * as React from 'react';

import styles from './BaseFilterBar.module.css';

type Props = {
  children: React.Node,
};

const BaseFilterBar = ({ children }: Props) => (
  <div className={styles.root}>{children}</div>
);

export default BaseFilterBar;
