// @flow

import * as React from 'react';
import styles from './BaseModal.module.css';
import CloseButton from '../CloseButton';
import classNames from '../../utils/classNames';

type Props = {
  title: string,
  onClose: Function,
  children: any,
  classes?: string[],
  displayCloseButton?: boolean,
};

class BaseModal extends React.Component<Props> {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  modalContainerRef: any = React.createRef();

  handleClickOutside = (event: MouseEvent) => {
    if (this.modalContainerRef.current.contains(event.target)) {
      return;
    }

    this.props.onClose();
  };

  render() {
    const { classes = [], displayCloseButton = false } = this.props;

    return (
      <div className={styles.modalContainer}>
        <div
          ref={this.modalContainerRef}
          className={classNames([styles.modalContent, ...classes])}
        >
          {displayCloseButton ? (
            <CloseButton onClick={this.props.onClose} />
          ) : null}
          <div className={styles.content}>
            <div className={styles.title}>{this.props.title}</div>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
export default BaseModal;
