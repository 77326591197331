import React from 'react';
import PropTypes from 'prop-types';
import ServiceWorkerDidUpdate from '../containers/helper/ServiceWorkerDidUpdate';
import MenuSidebar from './MenuSidebar/MenuSidebar';

const Navigation = ({ isAuthenticated, classes }) => (
  <nav className={classes.nav}>
    {isAuthenticated && <MenuSidebar />}

    <ServiceWorkerDidUpdate />
  </nav>
);

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default Navigation;
