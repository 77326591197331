// @flow

import { navigate } from '@reach/router';
import decode from 'jwt-decode';
import {
  getUserInfo,
  USER_AUTH_FROM_LOCAL_STORAGE,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  userLogout,
} from '../actions/user';
import { appSetToken } from '../actions/app';
import { fetchEquipmentTypes } from '../actions/equipmentFilter';

// const API_AUTH_ROOT = process.env.REACT_APP_SERVICE_IAM_ROOT;
// export const loginUrl = `${API_AUTH_ROOT}/cas/login?service=${encodeURIComponent(window.location.origin)}`;

const tokenHasExpired = (expiresIn: number) =>
  expiresIn !== null && new Date().getTime() > expiresIn * 1000;

const saveTokensToLocalStorage = async ({ accessToken }) => {
  try {
    window.localStorage.setItem('access_token', accessToken);
  } catch (err) {
    // Access to localStorage might be denied by some browser plugins
  }

  return {
    accessToken,
  };
};

const loadTokensFromLocalStorage = async () => {
  try {
    const accessToken = window.localStorage.getItem('access_token');
    return Promise.resolve({ accessToken });
  } catch (err) {
    // Access to localStorage might be denied by some browser plugins
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject();
  }
};

const clearLocalStorage = () => {
  try {
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('state');
  } catch (err) {
    // Access to localStorage might be denied by some browser plugins
  }
};

function login(store: any, accessToken: string, redirect: boolean = false) {
  const decodedAccessToken = decode(accessToken);
  if (tokenHasExpired(decodedAccessToken.exp) === false) {
    store.dispatch(appSetToken({ decodedAccessToken, accessToken }));
    store.dispatch(getUserInfo);
    store.dispatch(fetchEquipmentTypes);

    if (redirect) {
      navigate('/');
    }
  } else {
    store.dispatch(userLogout);
  }
}

export default (store: { dispatch: any => Promise<any> }) => (
  next: any => any
) => (action: { type: string, payload: { accessToken: string } }) => {
  if (action.type === USER_LOGIN_SUCCESS) {
    const { accessToken } = action.payload;
    saveTokensToLocalStorage({ accessToken }).then(() =>
      login(store, accessToken, true)
    );
  }

  if (action.type === USER_AUTH_FROM_LOCAL_STORAGE) {
    loadTokensFromLocalStorage()
      .then(({ accessToken }) => login(store, accessToken))
      .catch(() => {});
  }

  if (action.type === USER_LOGOUT) {
    clearLocalStorage();
  }

  next(action);
};
