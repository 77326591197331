// @flow

import { APP_SERVICE_WORKER_DID_UPDATE, APP_SET_TOKEN } from '../actions/app';
import { USER_INFO_SUCCESS, USER_LOGOUT } from '../actions/user';
import type { App } from '../types/app';
import { measurementSystem } from '../types/shared';

export const dateTimeWorldFormat = 'DD.MM.YYYY HH:mm';
export const dateTimeUsFormat = 'MM.DD.YYYY h:mm a';

export const datePickerWorldFormat = 'yyyy-MM-dd';
export const datePickerUsFormat = 'MM-dd-yyyy';

export const datePickerDateFormatMap = {
  IMPERIAL: datePickerUsFormat,
  METRIC: datePickerWorldFormat,
};

const initialState: App = {
  decodedAccessToken: null,
  accessToken: '',
  refreshToken: '',
  isAuthenticated: false,
  serviceWorkerDidUpdate: false,
  user: undefined,
  dateFormatWithTime: dateTimeWorldFormat,
  measurementSystem: 'METRIC',
};

export default (
  state: App = initialState,
  action: { type: string, payload: any }
) => {
  switch (action.type) {
    case APP_SERVICE_WORKER_DID_UPDATE: {
      return {
        ...state,
        serviceWorkerDidUpdate: true,
      };
    }
    case APP_SET_TOKEN: {
      return {
        ...state,
        isAuthenticated: true,
        ...action.payload,
      };
    }
    case USER_INFO_SUCCESS: {
      return {
        ...state,
        dateFormatWithTime:
          action.payload.user.measurementSystem === measurementSystem.metric
            ? dateTimeWorldFormat
            : dateTimeUsFormat,
        measurementSystem: action.payload.user.measurementSystem,
      };
    }
    case USER_LOGOUT:
      return initialState;

    default: {
      return state;
    }
  }
};
