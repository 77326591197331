// @flow

import * as React from 'react';
import styles from './PathBreadCrumbs.module.css';
import SpaceBreadCrumbs from './SpaceBreadCrumbs';
// import type { Space } from '../../types/space';
// import type { Site } from '../../types/site';

type Props = {
  collection: any[],
  fullPath?: boolean,
};

/* eslint-disable */

const deriveTitleFromEntityType = (e: Object) => {
  return e._entity === 'Site' ? `${e.title}: ` : e.title;
};

const PathBreadCrumbs = ({ collection = [], fullPath }: Props) => (
  <div>
    {collection.length && collection[0]._entity === 'Site' ? (
      <div className={styles.wrapper}>
        <span>{deriveTitleFromEntityType(collection[0])} </span>
        <span> &nbsp; </span>
        <SpaceBreadCrumbs
          fullPath={fullPath}
          collection={collection.slice(1)}
        />
      </div>
    ) : (
      <SpaceBreadCrumbs fullPath={fullPath} collection={collection} />
    )}
  </div>
);
/* eslint-enable */

export default PathBreadCrumbs;
