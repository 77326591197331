// @flow

import * as React from 'react';
import styles from './SentinelWizard.module.css';
import WizardStepper from './WizardStepper';
import StepType from './steps/StepType';
import StepCondition from './steps/StepCondition/StepCondition';
import StepSchedule from './steps/StepSchedule';
import SentinelWizardNavigation from './SentinelWizardNavigation';
import type {
  Parameters,
  Sentinel,
  SentinelHandler,
  SentinelType,
} from '../../types/sentinel';
import {
  defaultDoorParameters,
  defaultTemperatureParams,
  sentinelHandlerChannel,
  sentinelTypes,
  targetType,
  sentinelTypeToSensorType,
} from '../../types/sentinel';
import type { Step, Steps } from '../../types/sentinelWizard';
import StepHandling from './steps/StepHandling';
import StepRollout from './steps/StepRollout';
import SentinelTypeFlag from './SentinelTypeFlag';

import SentinelMetaInputs from './SentinelMetaInputs';
import { fetchSentinelsCreate } from '../../actions/sentinels';
import type { Site } from '../../types/site';
import EquipmentSelector from './EquipmentSelector/EquipmentSelector';
import SubTabs from '../Tabs/SubTabs';
import SubTab from '../Tabs/SubTabs/SubTab';
import { UiChip } from '../Tables/Cells';
import Button from '../ui/Button';
import CloseButton from '../CloseButton';
import classNames from '../../utils/classNames';
import { fetchSites } from '../../actions/sites';
import { fetchEquipment } from '../../actions/equipment';
import CancelConfigurationModal from '../Modals/CancelConfigurationModal';
import SaveConfigurationModal from '../Modals/SaveConfigurationModal';
import { getSentinelTip } from '../../constants/sentinels';

const stepKeys = {
  type: 'type',
  condition: 'condition',
  schedule: 'schedule',
  handling: 'handling',
  rollout: 'rollout',
};

// todo: refactor
const steps: Steps = {
  [stepKeys.type]: {
    key: stepKeys.type,
    title: 'Type',
    isValid: false,
  },
  [stepKeys.condition]: {
    key: stepKeys.condition,
    title: 'Condition',
    isValid: false,
  },
  [stepKeys.schedule]: {
    key: stepKeys.schedule,
    title: 'Schedule',
    isValid: false,
  },
  [stepKeys.handling]: {
    key: stepKeys.handling,
    title: 'Handling',
    isValid: false,
  },
  [stepKeys.rollout]: {
    key: stepKeys.rollout,
    title: 'Rollout',
    isValid: false,
  },
};

type State = {
  id: string,
  title: string,
  description: string,
  sentinelType: SentinelType,
  targets: any,
  handlers: SentinelHandler[],
  tipMessage: string,
  parameters: Parameters,
  schedule: {
    from: string,
    to: string,
  },
  muted?: boolean,
  reason?: string,

  sentinelConditionValue: any,
  sentinelSchedule: {
    from: string,
    to: string,
  },

  sentinelGroups: any,
  steps: Steps,
  currentStep: string,

  sites: Site[],
  selectedSites: Set<number>,
  selectedEquipment: Set<number>,
  error: string | boolean,
  loading: boolean,

  cancelConfigurationModalOpen: boolean,
  saveConfigurationModalOpen: boolean,
};

const initialState: State = {
  id: '',
  title: '',
  description: '',
  sentinelType: '',
  targets: [],
  parameters: {},
  handlers: [
    {
      ix: 0,
      delay: 0,
      recipients: [],
    },
  ],
  schedule: {
    from: '',
    to: '',
  },

  sentinelConditionValue: '',
  sentinelSchedule: {
    from: '',
    to: '',
  },
  sentinelGroups: [],
  steps,
  currentStep: '',
  tipMessage: '',
  sites: [],
  selectedSites: new Set(),
  selectedEquipment: new Set(),
  error: false,
  loading: false,

  cancelConfigurationModalOpen: false,
  saveConfigurationModalOpen: false,
};

type Props = {
  sentinel?: Sentinel,
  onUpdateOrCreate: void => Promise<void>,
  onExit: Function,
  onDelete?: Function,
  extraClassNames?: string[],
};

class SentinelWizard extends React.Component<Props, State> {
  state = { ...initialState };

  componentDidMount() {
    const { sentinel } = this.props;

    if (sentinel) {
      this.handleLoadSentinelData(sentinel).catch(err =>
        this.setState({ error: err.toString() })
      );
    } else {
      this.setState({ currentStep: stepKeys.type });
    }
  }

  handleLoadSentinelData = async (sentinel: Sentinel) => {
    try {
      this.setState({ loading: true });
      const selectedEquipment = sentinel.targets
        .filter(t => t.targetType === targetType.EQUIPMENT)
        .map(t => t.targetId);

      const sites = await fetchSites({
        sensorTarget: ['EQUIPMENT'],
        equipmentIds: selectedEquipment.map(id => id),
      });

      // we add equipment to the site object here
      const sensorType = sentinelTypeToSensorType(sentinel.sentinelType);

      const sitesWithEquipment = await Promise.all(
        sites.map(async site => ({
          ...site,
          equipment: await fetchEquipment({
            siteIds: [site.id],
            sensorTypes: [sensorType],
          }),
        }))
      );

      const selectedSites = sites.map(s => s.id);

      const handlers =
        sentinel.handlers && sentinel.handlers.length
          ? sentinel.handlers
          : [
              {
                channel: sentinelHandlerChannel.USER_GROUP_EMAIL,
                ix: 0,
                delay: 0,
                recipients: [],
              },
            ];

      this.setState({
        ...sentinel,
        handlers,
        sites: sitesWithEquipment,
        selectedEquipment: new Set(selectedEquipment),
        selectedSites: new Set(selectedSites),
        currentStep: stepKeys.condition,
        tipMessage: getSentinelTip(sentinel.sentinelType),
        loading: false,
      });

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  handleSetStep = (nextStep: string) =>
    this.setState({ currentStep: nextStep });

  handleNameChange = (name: string) => (ev: any) =>
    this.setState({
      [name]: ev.target.value,
    });

  handleSetStepType = (sentinelType: SentinelType) => {
    switch (sentinelType) {
      case sentinelTypes.TEMPERATURE:
        this.setState({
          sentinelType,
          parameters: { ...defaultTemperatureParams },
          tipMessage: getSentinelTip(sentinelType),
        });
        break;

      case sentinelTypes.DOOR:
        this.setState({
          sentinelType,
          parameters: { ...defaultDoorParameters },
          tipMessage: getSentinelTip(sentinelType),
        });
        break;

      default:
        throw new Error('Unknown sentinel type');
    }
  };

  handleSetStepCondition = (name: string, value: string): void =>
    this.setState({
      parameters: {
        ...this.state.parameters,
        [name]: value,
      },
    });

  handleStepHandling = (updatedHandler: SentinelHandler, index: number) =>
    this.setState({
      handlers: this.state.handlers.map(
        (handler, handlerIndex) =>
          handlerIndex === index ? updatedHandler : handler
      ),
    });

  handleSetStepSchedule = ({ from, to }: { from: string, to: string }) =>
    this.setState({
      sentinelSchedule: { from, to },
    });

  handleStepRollOutSiteSelect = (site: Site) => {
    const { sites } = this.state;
    this.setState({
      sites: sites.find(s => s.id === site.id) ? sites : [...sites, site],
    });
  };

  handleStepRolloutSiteRemove = (site: Site) => {
    const { sites, selectedEquipment } = this.state;
    const index = sites.findIndex(s => s.id === site.id);
    if (index !== -1) {
      const site = sites[index];
      // REMOVE ALL EQUIPMENT IF YOU REMOVE THE SITE FROM SELECTION.
      (site.equipment || []).forEach(equipment => {
        if (selectedEquipment.has(equipment.id)) {
          selectedEquipment.delete(equipment.id);
        }
      });

      this.setState({
        sites: [...sites.slice(0, index), ...sites.slice(index + 1)],
        selectedEquipment,
      });
    }
  };

  handleStepRollOutSiteCollapse = (siteId: number) => {
    const { selectedSites } = this.state;

    if (selectedSites.has(siteId)) {
      selectedSites.delete(siteId);
    } else {
      selectedSites.add(siteId);
    }

    this.setState({
      selectedSites,
    });
  };

  handleStepRollOutEquipmentSelect = (equipmentId: number) => {
    const { selectedEquipment } = this.state;

    if (selectedEquipment.has(equipmentId)) {
      selectedEquipment.delete(equipmentId);
    } else {
      selectedEquipment.add(equipmentId);
    }

    this.setState({
      selectedEquipment,
    });
  };

  removeStepRollOutEquipment = (equipmentId: number) => {
    const { selectedEquipment } = this.state;

    if (selectedEquipment.has(equipmentId)) {
      selectedEquipment.delete(equipmentId);
    }

    this.setState({
      selectedEquipment,
    });
  };

  handleDelete = () => {
    const deleteFn = this.props.onDelete ? this.props.onDelete : () => {};
    deleteFn();
  };

  handleSentinelSubmit = async () => {
    const {
      id,
      title,
      description,
      sentinelType,
      parameters,
      selectedEquipment,
      handlers,
    } = this.state;

    this.setState({ error: false, loading: true });

    const sentinel: Sentinel = {
      id,
      title,
      description,
      sentinelType,
      targets: [...selectedEquipment].map(id => ({
        targetId: id,
        targetType: targetType.EQUIPMENT,
      })),
      parameters,
      handlers,
    };

    fetchSentinelsCreate(sentinel)
      .then(() => this.props.onUpdateOrCreate())
      .catch(err => {
        this.setState({ error: err.message || true, loading: false });
      });
  };

  handleCancelConfiguration = () => {
    this.setState({
      cancelConfigurationModalOpen: true,
    });
  };

  handleSaveConfiguration = () => {
    this.setState({
      saveConfigurationModalOpen: true,
    });
  };

  validateStep = (step?: Step): boolean => {
    const {
      sentinelType,
      parameters,
      sentinelSchedule,
      selectedEquipment,
      handlers,
    } = this.state;

    switch (step) {
      case stepKeys.type:
        return Object.values(sentinelTypes).includes(sentinelType) === true;
      case stepKeys.condition:
        if (sentinelType === sentinelTypes.DOOR) {
          return (
            parameters.openClosed === 'OPEN' ||
            parameters.openClosed === 'CLOSED'
          );
        }

        // $FlowFixMe
        return (
          parameters.threshold !== '' &&
          parameters.threshold !== undefined &&
          parameters.comparisonType !== '' &&
          parameters.comparisonType !== undefined
        );
      case stepKeys.schedule:
        return sentinelSchedule.from !== '' && sentinelSchedule.to !== '';
      case stepKeys.handling:
        return handlers.length > 0 && handlers[0].recipients.length > 0;

      case stepKeys.rollout:
        return [...selectedEquipment].length > 0;
      default:
        return false;
    }
  };

  generateTip = () => (
    <UiChip
      customIconColor={styles.forceBlack}
      classes={styles.forceBlack}
      label={this.state.tipMessage}
      iconId="info"
    />
  );

  handleFinishLater = () => {};

  render() {
    const {
      steps,
      currentStep,

      id,
      title,
      description,
      sentinelType,
      parameters,
      handlers,

      sites,
      selectedSites,
      selectedEquipment,

      loading,
      error,
      tipMessage,
    } = this.state;

    const { extraClassNames = [] } = this.props;

    const stepsCompleted = {
      [stepKeys.type]: this.validateStep(stepKeys.type),
      [stepKeys.condition]: this.validateStep(stepKeys.condition),
      [stepKeys.schedule]: this.validateStep(stepKeys.schedule),
      [stepKeys.handling]: this.validateStep(stepKeys.handling),
      [stepKeys.rollout]: this.validateStep(stepKeys.rollout),
    };

    const wizardIsActive = id === '';
    const stepIsSet = currentStep !== '';

    return (
      <div className={classNames(styles.root, ...extraClassNames)}>
        <div className={styles.formWrap}>
          {stepIsSet && (
            <SentinelMetaInputs
              handleNameChange={this.handleNameChange}
              title={title}
              description={description}
            />
          )}

          {!this.props.sentinel && <CloseButton onClick={this.props.onExit} />}

          <SentinelTypeFlag sentinelType={sentinelType} />

          {stepIsSet &&
            wizardIsActive && (
              <WizardStepper
                steps={Object.values(steps)}
                currentStep={currentStep}
                setStep={this.handleSetStep}
                stepsCompleted={stepsCompleted}
              />
            )}

          {wizardIsActive === false && (
            <div className={styles.tabsContainer}>
              <SubTabs>
                <SubTab
                  onClick={() => this.handleSetStep(stepKeys.condition)}
                  label="Conditions"
                  isActive={currentStep === stepKeys.condition}
                />
                <SubTab
                  onClick={() => this.handleSetStep(stepKeys.schedule)}
                  label="Schedule"
                  isActive={currentStep === stepKeys.schedule}
                />
                <SubTab
                  onClick={() => this.handleSetStep(stepKeys.handling)}
                  label="Handling"
                  isActive={currentStep === stepKeys.handling}
                />
                <SubTab
                  onClick={() => this.handleSetStep(stepKeys.rollout)}
                  label={`Rollout (${selectedEquipment.size})`}
                  isActive={currentStep === stepKeys.rollout}
                />
              </SubTabs>
            </div>
          )}

          <div className={styles.steps}>
            {currentStep === stepKeys.type &&
              wizardIsActive && (
                <StepType
                  onChange={this.handleSetStepType}
                  sentinelType={sentinelType}
                />
              )}

            {currentStep === stepKeys.condition && (
              <StepCondition
                onChange={this.handleSetStepCondition}
                parameters={parameters}
                sentinelType={sentinelType}
              />
            )}

            {currentStep === stepKeys.handling && (
              <StepHandling
                onChange={this.handleStepHandling}
                handlers={handlers}
              />
            )}

            {currentStep === stepKeys.schedule && (
              <StepSchedule onChange={this.handleSetStepSchedule} />
            )}

            {currentStep === stepKeys.rollout && (
              <div>
                <div className={styles.bottomLine}>
                  <EquipmentSelector
                    sentinelType={sentinelType}
                    handleSelectSite={this.handleStepRollOutSiteSelect}
                    selectedSites={new Set(sites.map(s => s.id))}
                  />
                </div>
                <div className={styles.rolloutContainer}>
                  <StepRollout
                    sites={sites}
                    handleCollapseSite={this.handleStepRollOutSiteCollapse}
                    handleEquipmentSelect={
                      this.handleStepRollOutEquipmentSelect
                    }
                    removeSite={this.handleStepRolloutSiteRemove}
                    selectedSites={selectedSites}
                    selectedEquipment={selectedEquipment}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {stepIsSet &&
          wizardIsActive && (
            <SentinelWizardNavigation
              setStep={this.handleSetStep}
              steps={steps}
              stepsKeys={Object.keys(stepKeys)}
              currentStep={currentStep}
              stepsCompleted={stepsCompleted}
              handleSubmit={this.handleSentinelSubmit}
              loading={loading}
              tipComponent={
                tipMessage &&
                (currentStep === 'type' || currentStep === 'rollout')
                  ? this.generateTip()
                  : undefined
              }
              handleFinishLater={this.handleFinishLater}
            />
          )}

        {wizardIsActive === false && (
          <React.Fragment>
            <div className={styles.bottomGroup}>
              <div className={styles.deleteChip}>
                <UiChip
                  onClick={this.handleDelete}
                  classes={styles.forceRedAndNoSelect}
                  label="Delete configuration"
                  iconId="trash"
                />
              </div>
              {tipMessage && this.generateTip()}
              <div className={styles.bottomGroupButtons}>
                <Button
                  secondary
                  onClick={this.handleCancelConfiguration}
                  isLoading={loading}
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.handleSaveConfiguration}
                  primary
                  isLoading={loading}
                >
                  Save
                </Button>
              </div>
            </div>
            {error && error}
            {this.state.cancelConfigurationModalOpen ? (
              <CancelConfigurationModal
                onClose={() =>
                  this.setState({ cancelConfigurationModalOpen: false })
                }
                onSubmit={this.props.onExit}
              />
            ) : null}
            {this.state.saveConfigurationModalOpen ? (
              <SaveConfigurationModal
                onClose={() =>
                  this.setState({ saveConfigurationModalOpen: false })
                }
                onSubmit={this.handleSentinelSubmit}
              />
            ) : null}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default SentinelWizard;
