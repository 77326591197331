// @flow

import fetchApi from '../middleware/api';
import type { Equipment, EquipmentApiFilter } from '../types/equipment';
import type { SensorType } from '../types/sensors';

export const EQUIPMENT_REQUEST = 'EQUIPMENT_REQUEST';
export const EQUIPMENT_SUCCESS = 'EQUIPMENT_SUCCESS';
export const EQUIPMENT_FAILURE = 'EQUIPMENT_FAILURE';

export const fetchEquipment = (
  filter?: EquipmentApiFilter
): Promise<Equipment[]> => {
  const queryParams = new URLSearchParams();

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && filter.equipmentTypeIds) {
    queryParams.set('equipmentTypeIds', filter.equipmentTypeIds.join(','));
  }

  if (filter && filter.siteIds && filter.siteIds.length > 0) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.sensorTypes) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  queryParams.set('withPath', 'true');
  return fetchApi({
    endpoint: `/api/v1/equipment?${queryParams.toString()}`,
  });
};

export const getEquipment = (filter?: EquipmentApiFilter) => async (
  dispatch: any => any
): Promise<any> => {
  dispatch({ type: EQUIPMENT_REQUEST });
  try {
    const equipment = await fetchEquipment(filter);
    dispatch({ type: EQUIPMENT_SUCCESS, payload: equipment });
    return equipment;
  } catch (err) {
    dispatch({ type: EQUIPMENT_FAILURE });
    return Promise.reject(new Error(err));
  }
};

export const EQUIPMENT_BY_ID_REQUEST = 'EQUIPMENT_BY_ID_REQUEST';
export const EQUIPMENT_BY_ID_SUCCESS = 'EQUIPMENT_BY_ID_SUCCESS';
export const EQUIPMENT_BY_ID_FAILURE = 'EQUIPMENT_BY_ID_FAILURE';

export const fetchEquipmentById = (equipmentId: number): Promise<Equipment> =>
  fetchApi({
    endpoint: `/api/v1/equipment/${equipmentId}/?withPath=true`,
  });

export const getEquipmentById = (equipmentId: number) => async (
  dispatch: any
) => {
  dispatch({ type: EQUIPMENT_BY_ID_REQUEST });

  try {
    const equipment = await fetchEquipmentById(equipmentId);

    equipment.hasFullPath = true;

    dispatch({ type: EQUIPMENT_BY_ID_SUCCESS, payload: equipment });
    return Promise.resolve();
  } catch (err) {
    dispatch({ type: EQUIPMENT_BY_ID_FAILURE });
    return Promise.resolve();
  }
};

export const EQUIPMENT_STAR_REQUEST = 'EQUIPMENT_STAR_REQUEST';
export const EQUIPMENT_STAR_SUCCESS = 'EQUIPMENT_STAR_SUCCESS';
export const EQUIPMENT_STAR_FAILURE = 'EQUIPMENT_STAR_FAILURE';

export const starEquipment = (equipmentId: string) => async (
  dispatch: Function
) => {
  dispatch({ type: EQUIPMENT_STAR_REQUEST });
  try {
    dispatch({ type: EQUIPMENT_STAR_SUCCESS, payload: equipmentId });
    return Promise.resolve();
  } catch (err) {
    dispatch({ type: EQUIPMENT_STAR_FAILURE });
    return Promise.resolve();
  }
};

export const fetchEquipmentBySiteId = (siteId: number) =>
  fetchApi({
    endpoint: `/api/v1/equipment/site/${siteId.toString()}/`,
  });

export const getFetchEquipmentBySiteId = (siteId: number) => async (
  dispatch: Function
) => {
  dispatch({ type: EQUIPMENT_REQUEST });
  try {
    const equipment = await fetchEquipmentBySiteId(siteId);

    equipment.forEach(equip => {
      if (equip._path && equip._path.length > 0 && equip.space === null) {
        /* eslint-disable */
        equip.space = equip._path[0];
        /* eslint-enable */
      }
    });

    dispatch({ type: EQUIPMENT_SUCCESS, payload: equipment });
    return equipment;
  } catch (err) {
    dispatch({ type: EQUIPMENT_FAILURE });
    return Promise.reject(new Error(err));
  }
};

export const fetchEquipmentBySiteIdAndSensorType = (
  siteId: number,
  sensorType: SensorType
): Promise<Equipment[]> =>
  fetchApi({
    endpoint: `/api/v1/equipment/site/${siteId.toString()}/sensors?sensorTypes=${sensorType}`,
  });

export const clearEquipment = () => (dispatch: Function) => {
  dispatch({ type: EQUIPMENT_SUCCESS, payload: [] });
};
