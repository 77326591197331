// @flow

import * as React from 'react';
import Button from '../ui/Button';
import styles from './SentinelWizardNavigation.module.css';
import type { Steps } from '../../types/sentinelWizard';

type Props = {
  setStep: string => void,
  handleSubmit: void => Promise<any>,
  currentStep: string,
  steps: Steps,
  stepsKeys: any,
  stepsCompleted: { [key: string]: boolean },
  loading?: boolean,
  tipComponent?: any,
  handleFinishLater: void => void,
};

const SentinelWizardNavigation = ({
  setStep,
  currentStep,
  steps,
  stepsKeys,
  stepsCompleted,
  handleSubmit,
  loading = false,
  tipComponent,
  handleFinishLater,
}: Props) => {
  const currentStepIndex = stepsKeys.findIndex(s => s === currentStep);

  const prevStepKeyIndex = currentStepIndex - 1;
  const nextStepKeyIndex = currentStepIndex + 1;

  const prevStep = steps[stepsKeys[prevStepKeyIndex]];
  const nextStep = steps[stepsKeys[nextStepKeyIndex]];
  return (
    <div className={styles.root}>
      <div className={styles.tip}>{tipComponent}</div>
      <div className={styles.controls}>
        <div className={styles.button}>
          {prevStep ? (
            <Button
              primary
              onClick={() => setStep(prevStep.key)}
              isLoading={loading}
            >
              Previous
            </Button>
          ) : (
            <span />
          )}
        </div>

        <div className={styles.button}>
          <Button
            primary
            onClick={() => (nextStep ? setStep(nextStep.key) : handleSubmit())}
            disabled={stepsCompleted[currentStep] === false}
            isLoading={loading}
          >
            {nextStep ? `Next` : `Finish`}
          </Button>
        </div>
      </div>
      <div
        onClick={handleFinishLater}
        onKeyPress={handleFinishLater}
        role="button"
        tabIndex={0}
        className={styles.finishLaterButton}
      >
        Finish later
      </div>
    </div>
  );
};

export default SentinelWizardNavigation;
