// @flow

import * as React from 'react';
import classNames from '../../../../utils/classNames';
import EquipmentMenuItem, {
  ListItem,
} from '../../FilterItems/EquipmentMenuItem';
import MenuFilterButton from '../../../ui/FilterButton';
import type { EquipmentGroup, EquipmentType } from '../../../../types/tags';
import styles from './EquipmentMenuFilter.module.css';
import Button from '../../../ui/Button/Button';
import FilterMenuFooter from '../../FilterMenuFooter';
import FilterMenuContent from '../../FilterMenuContent';

type Props = {
  label: string,
  filterGroupItems: EquipmentGroup[],
  filterTypeItems: EquipmentType[],
  activeFilter: number[],
  setTypeFilter: (number[]) => void,
};

type State = {
  open: boolean,
  activeFilter: number[],
  groups: Map<string, EquipmentGroup>,
  types: Map<string, EquipmentType[]>,
};

class EquipmentMenuFilter extends React.Component<Props, State> {
  state = {
    open: false,
    activeFilter: [], // $FlowFixMe
    groups: new Map(), // $FlowFixMe
    types: new Map(),
  };

  componentDidMount() {
    this.updateFilter(this.props.activeFilter);
    this.groupEquipmentTypeByGroup();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.filtersHaveUpdated(prevProps.activeFilter, this.props.activeFilter)
    ) {
      this.updateFilter(this.props.activeFilter);
    }

    if (
      prevProps.filterTypeItems.length !== this.props.filterTypeItems.length
    ) {
      this.groupEquipmentTypeByGroup();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  groupEquipmentTypeByGroup = () => {
    const groups: Map<string, EquipmentGroup> = new Map();
    const types: Map<string, EquipmentType[]> = new Map();

    // eslint-disable-next-line no-restricted-syntax
    for (const equipmentType of this.props.filterTypeItems) {
      const groupId = equipmentType.groupId.toString();
      const existingItems = types.get(groupId) || [];
      types.set(groupId, existingItems.concat(equipmentType));
      if (equipmentType.group) {
        groups.set(groupId, equipmentType.group);
      }
    }

    this.setState({ groups, types });
  };

  wrapperRef = React.createRef();

  updateFilter = (activeFilter: number[]) => this.setState({ activeFilter });

  filtersHaveUpdated = (prev: number[], next: number[]) =>
    JSON.stringify(prev.sort()) !== JSON.stringify(next.sort());

  handleClickOutside = (event: MouseEvent) => {
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      this.wrapperRef.current.contains(event.target) === false
    ) {
      this.setState(() => ({ open: false }));
    }
  };

  handleGroupItemsClick = (typeIds: number[]) => {
    const { activeFilter } = this.state;

    this.setState({
      activeFilter: activeFilter.some(i =>
        typeIds.some(filterItem => i === filterItem)
      )
        ? activeFilter.filter(item => typeIds.includes(item) === false)
        : [...activeFilter, ...typeIds],
    });
  };

  handleTypeItemsClick = (typeId: number) => {
    const { activeFilter } = this.state;

    this.setState({
      activeFilter: activeFilter.includes(typeId)
        ? activeFilter.filter(f => f !== typeId)
        : [...activeFilter, typeId],
    });
  };

  handleMenuFilterButtonClick = () =>
    this.setState(prevState => ({ open: !prevState.open }));

  handleMenuFilterButtonSubmitClick = () => {
    this.setState({ open: false });
    this.props.setTypeFilter(this.state.activeFilter);
  };

  render() {
    const { label } = this.props;
    const { open, activeFilter, groups, types } = this.state;

    const canSubmit = this.filtersHaveUpdated(
      activeFilter,
      this.props.activeFilter
    );

    return (
      <div className={styles.wrapper} ref={this.wrapperRef}>
        <MenuFilterButton
          onClick={this.handleMenuFilterButtonClick}
          open={open}
        >
          {label}
        </MenuFilterButton>

        {open && (
          <div className={styles.dropDownWrapper}>
            <FilterMenuContent>
              <ul className={styles.listWrapper}>
                {Array.from(groups.values()).map(group => {
                  const equipmentTypesByGroup = types.get(group.id.toString());

                  if (!equipmentTypesByGroup) {
                    return null;
                  }

                  const typeIds = equipmentTypesByGroup.map(
                    equipmentType => equipmentType.id
                  );

                  const activeItems = typeIds.reduce(
                    (state, id) =>
                      activeFilter.includes(id) ? state + 1 : state,
                    0
                  );

                  return (
                    <EquipmentMenuItem
                      alias={group.alias}
                      key={`EquipmentMenuItem${group.id}`}
                      title={group.title}
                      onClick={() => this.handleGroupItemsClick(typeIds)}
                      activeItems={activeItems}
                      maxItems={typeIds.length}
                    >
                      {equipmentTypesByGroup.map(equipmentType => (
                        <ListItem
                          key={`EquipmentMenuListItem${equipmentType.id}`}
                          onClick={() =>
                            this.handleTypeItemsClick(equipmentType.id)
                          }
                          label={equipmentType.title}
                          checked={activeFilter.includes(equipmentType.id)}
                        />
                      ))}
                    </EquipmentMenuItem>
                  );
                })}
              </ul>
            </FilterMenuContent>

            <FilterMenuFooter>
              <Button
                secondary
                size="small"
                onClick={this.handleMenuFilterButtonClick}
              >
                Cancel
              </Button>

              <Button
                size="small"
                disabled={canSubmit === false}
                className={classNames(styles.link)}
                onClick={this.handleMenuFilterButtonSubmitClick}
              >
                Apply
              </Button>
            </FilterMenuFooter>
          </div>
        )}
      </div>
    );
  }
}

export default EquipmentMenuFilter;
