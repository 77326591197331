import { schema } from 'normalizr';
import { equipmentStatus } from './types/equipment';
import { reduceSensorValue } from './utils/timeSeriesHelper';

export const equipmentSchema = new schema.Entity('equipment', undefined, {
  idAttribute: document => document.id,
  processStrategy: document => ({
    id: document.id,
    name: document.attributes._name, // eslint-disable-line no-underscore-dangle
    description: document.attributes._description, // eslint-disable-line no-underscore-dangle
    created: document.attributes._created, // eslint-disable-line no-underscore-dangle
    status: equipmentStatus.on, // eslint-disable-line no-underscore-dangle
    spaceId: document.relationships._parent, // eslint-disable-line no-underscore-dangle
    typeId: document.attributes.equipment.typeId,
    groupId: document.attributes.equipment.groupId,
    sensors: document.attributes.measurements
      ? document.attributes.measurements.reduce(
          (list, next) => [...list, ...next.sensors.map(s => s.sensorId)],
          []
        )
      : [],
  }),
});

export const spacesSchema = new schema.Entity('spaces', undefined, {
  idAttribute: document => document.id,
  processStrategy: document => ({
    id: document.id,
    name: document.attributes._name, // eslint-disable-line no-underscore-dangle
    description: document.attributes._description, // eslint-disable-line no-underscore-dangle
    created: document.attributes._created, // eslint-disable-line no-underscore-dangle
    parentId: document.relationships._parent, // eslint-disable-line no-underscore-dangle
  }),
});

export const sitesSchema = new schema.Entity('sites', undefined, {
  idAttribute: document => document.id,
  processStrategy: document => ({
    id: document.id,
    name: document.attributes._name, // eslint-disable-line no-underscore-dangle
    description: document.attributes._description, // eslint-disable-line no-underscore-dangle
    created: document.attributes._created, // eslint-disable-line no-underscore-dangle
    parentId: document.relationships._parent, // eslint-disable-line no-underscore-dangle
  }),
});

export const equipmentTypeSchema = new schema.Entity(
  'equipmentTypes',
  undefined,
  {
    idAttribute: document => document.id, // eslint-disable-line no-underscore-dangle
    processStrategy: (document, parent) => ({
      ...document,
      equipmentGroup: parent.id,
    }),
  }
);

export const notificationSchemea = new schema.Entity(
  'notification',
  undefined,
  {
    idAttribute: document => document.id,
    processStrategy: document => ({
      id: document.id,
      ownerId: document.ownerId,
      createdAt: document.createdAt,
      dismissedBy: document.dismissedBy,
      dismissedComment: document.dismissedComment,
      sensorParams: [...document.sensorParams],
      sentinel: document.sentinel,
    }),
  }
);

export const notificationsSchema = new schema.Entity(
  'notifications',
  { notifications: [notificationSchemea] },
  {}
);

export const equipmentGroupsSchema = new schema.Entity(
  'equipmentGroups',
  { relations: [equipmentTypeSchema] },
  {
    idAttribute: document => document._id, // eslint-disable-line no-underscore-dangle
    processStrategy: document => ({
      id: document._id, // eslint-disable-line no-underscore-dangle
      name: document._name, // eslint-disable-line no-underscore-dangle
      relations: document._childs.map(t => ({ id: t._id, name: t._name })), // eslint-disable-line no-underscore-dangle
      types: document._childs.map(t => ({ id: t._id, name: t._name })), // eslint-disable-line no-underscore-dangle
    }),
  }
);

export const sensorSchema = new schema.Entity('sensors', undefined, {
  idAttribute: document => document.sensorId,
  processStrategy: (document, parent) => ({
    id: document.sensorId,
    name: document.info,
    gatewayId: parent.id,
    panelId: parent.panelId,
  }),
});

export const gatewaySchema = new schema.Entity(
  'gateways',
  { sensors: [sensorSchema] },
  {
    idAttribute: document => document.tsd.dataLogger,
    processStrategy: document => ({
      id: document.tsd.dataLogger,
      panelId: document.panelId,
      sensors: document.tsd.sensors,
    }),
  }
);

export const samplesSchema = new schema.Entity('samples', undefined, {
  processStrategy: document => ({
    gatewayId: document.id.split('_')[0],
    timestamp: document.attributes.timestamp,
    energy: document.attributes.energy,
    aggregation: {
      energy: reduceSensorValue(document.attributes.energy || []),
    },
  }),
});
