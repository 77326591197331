// @flow

import React, { Component } from 'react';
import TextField from '../ui/TextField';
import styles from './MuteSentinelModal.module.css';
import Button from '../ui/Button';
import BaseModal from './BaseModal';
// import DropDown from '../DropDown';
import type { Sentinel } from '../../types/sentinel';

import Checkbox from '../ui/Checkbox';

type Props = {
  sentinel: Sentinel,
  onSubmit: Function,
  onClose: Function,
};

type State = {
  selectedOption: string,
  comment: string,
  view: 'initial' | 'confirm',
};

// const dropDownValues = [
//   {
//     text: 'Mute until',
//     value: 'until',
//   },
// ];
const labels = ['Maintenance Work'];

// TODO THIS IS STILL MISSING THE DATE RANGE COMPONENT
class MuteSentinelModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedOption: '',
      comment: '',
      view: 'initial',
    };
  }

  handleSubmit = () => {
    const { selectedOption, comment } = this.state;
    this.props.onSubmit({
      reason: selectedOption === 'comment' ? comment : selectedOption,
      sentinelId: this.props.sentinel.id,
    });
  };

  handleCommentChange = (e: any) => {
    const comment = e.currentTarget.value;
    this.setState({ comment });
  };

  selectOption = (selectedOption: string) => this.setState({ selectedOption });

  // handleDropdownChange = () => {};

  switchView = () => {
    this.setState({ view: 'confirm' });
  };

  renderCheckBoxes = (): any =>
    labels.map((label: string) => (
      <Checkbox
        className={styles.borderBottom}
        onChange={() => this.selectOption(label)}
        checked={label === this.state.selectedOption}
        size={20}
        label={label}
        name={label}
        key={label}
      />
    ));

  render() {
    // sentinel, comment, onSubmit, onClose
    const { sentinel, onClose } = this.props;
    const { view, selectedOption, comment } = this.state;

    const commentSelected = selectedOption === 'comment';
    const onInitialView = view === 'initial';

    const formIsValid =
      (selectedOption === 'comment' && comment !== '') ||
      labels.includes(selectedOption);

    return (
      <BaseModal
        onClose={this.props.onClose}
        title={
          onInitialView
            ? `Mute Notifications for ${sentinel.title}`
            : `The notifications for ${sentinel.title} will be paused.`
        }
      >
        {view !== 'confirm' ? (
          <div>
            <div className={styles.actionGroup}>
              <div className={styles.reasonText}> The reason </div>
              <div className={styles.radioGroup}>
                {this.renderCheckBoxes()}
                <Checkbox
                  className={styles.checkboxWithComment}
                  onChange={() => this.selectOption('comment')}
                  checked={commentSelected}
                  size={20}
                  name="comment"
                  fullWidthLabel
                  label={
                    <TextField
                      placeholder="Other"
                      value={comment}
                      disabled={!commentSelected}
                      handleChange={this.handleCommentChange}
                      fullWidth
                    />
                  }
                />
              </div>
            </div>
            {
              //  <div>
              //             <DropDown
              //               onChange={this.handleDrawdownChange}
              //               sortItems={dropDownValues}
              //               labelText="Label Text"
              //             />
              //           </div>
            }
            <div className={styles.buttonGroup}>
              <Button disabled={!formIsValid} onClick={this.switchView} primary>
                Mute
              </Button>
              <Button onClick={onClose} secondary>
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div className={styles.buttonGroup}>
              <Button onClick={this.handleSubmit} primary>
                OK
              </Button>
              <Button onClick={onClose} secondary>
                Cancel
              </Button>
            </div>
          </div>
        )}
      </BaseModal>
    );
  }
}

export default MuteSentinelModal;
