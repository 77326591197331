// @flow

import * as React from 'react';
import FeatureMonitorCircleList from '../../../components/lists/FeatureMonitorCircleList';
import Card from '../../../components/cards/Card';
import CardList from '../../../components/lists/CardList';
import CardTitle from '../../../components/cards/CardTitle';
import IconServicesAverageOperatingTime from '../../icons/IconServicesAverageOperatingTime';
import IconServicesCurrentOperatingTime from '../../icons/IconServicesCurrentOperatingTime';
import IconServicesEnergy from '../../icons/IconServicesEnergy';
import IconServicesTotalOperatingTime from '../../icons/IconServicesTotalOperatingTime';
import IconServicesCharge from '../../icons/IconServicesCharge';
import FeatureMonitorCircle from '../../FeatureMonitorCircle';
import Activity from '../../charts/Activity/Activity';
import Chart from '../../charts/Chart';

const ViewEquipmentMonitor = () => (
  <React.Fragment>
    <div>
      <FeatureMonitorCircleList>
        <FeatureMonitorCircle
          icon={<IconServicesEnergy />}
          data={37.2}
          name="Av. energy consumption"
          unit="kWh/month"
        />
        <FeatureMonitorCircle
          icon={<IconServicesCharge />}
          data={10.6}
          name="CO2 emissions"
          unit="kg / month"
        />
        <FeatureMonitorCircle
          icon={<IconServicesCurrentOperatingTime />}
          data={7}
          name="of Site En. Consumption"
          unit="March 2018"
        />
        <FeatureMonitorCircle
          icon={<IconServicesAverageOperatingTime />}
          data={124}
          name="Av. Operating Time"
          unit="hours/month"
        />
        <FeatureMonitorCircle
          icon={<IconServicesTotalOperatingTime />}
          data={160}
          name="Total Operating Time"
          unit="hours"
        />
      </FeatureMonitorCircleList>

      <CardList>
        <Card flex>
          <CardTitle
            title="HVAC Ranking"
            description="Least energy consuming equipment"
          />
        </Card>
        <Card>
          <CardTitle title="Operating Hours" description="By month" />
          <Chart
            data={{
              Jan: {
                '2017': 255,
                '2018': 199,
              },
              Feb: {
                '2017': 119,
                '2018': 165,
              },
              Mar: {
                '2017': 288,
                '2018': 344,
              },
              Apr: {
                '2017': 110,
                '2018': 377,
              },
              May: {
                '2017': 233,
                '2018': 320,
              },
              Jun: {
                '2017': 384,
                '2018': 146,
              },
              Jul: {
                '2017': 123,
                '2018': 255,
              },
              Aug: {
                '2017': 213,
                '2018': 241,
              },
              Sep: {
                '2017': 243,
                '2018': 343,
              },
              Oct: {
                '2017': 225,
                '2018': 353,
              },
              Nov: {
                '2017': 215,
                '2018': 251,
              },
              Dec: {
                '2017': 143,
                '2018': 303,
              },
            }}
          />
        </Card>

        <Card>
          <CardTitle
            title="Activity Graph"
            description="Energy Consumption & Notifications"
          />
          <Activity />
        </Card>
      </CardList>
    </div>
  </React.Fragment>
);

export default ViewEquipmentMonitor;
