// @flow
import * as React from 'react';

import SearchBox from '../search/SearchBox';
import styles from './SitesFilterBar.module.css';
import BaseFilterBar from './BaseFilterBar';

type Props = {
  setSiteFilter: string => void,
  siteFilterValue?: string,
  error?: boolean,
};

const SitesFilterBar = ({ setSiteFilter, siteFilterValue, error }: Props) => (
  <BaseFilterBar>
    <div className={styles.wrapper}>
      <SearchBox
        placeholder="Search by site title"
        width="15.625rem"
        widthActive="15.625rem"
        query={siteFilterValue}
        onChange={value => setSiteFilter(value)}
        error={error}
      />
    </div>
  </BaseFilterBar>
);

export default SitesFilterBar;
