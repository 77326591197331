// @flow

// todo: refactor

export const services = {
  cas: 'SERVICE_CAS',
  iam: 'SERVICE_IAM',
  sentinel: 'SERVICE_SENTINELS',
  tsd: 'SERVICE_TSD_ROOT',
};

export function getConfig() {
  const serviceUrls = {
    [services.cas]:
      process.env.REACT_APP_SERVICE_CAS_ROOT ||
      'https://cas.staging.energybox.com',
    [services.iam]:
      process.env.REACT_APP_SERVICE_IAM_ROOT ||
      'https://iam.staging.energybox.com',
    [services.sentinel]:
      process.env.REACT_APP_SERVICE_SENTINEL_ROOT ||
      'https://sentinel.staging.energybox.com',
    [services.tsd]:
      process.env.REACT_APP_SERVICE_TSD_ROOT ||
      'https://eb-api-staging.meso.net',
  };

  if (
    process.env.ENVIRONMENT === 'production' &&
    Object.values(serviceUrls).includes('')
  ) {
    throw new Error('env vars incomplete');
  }

  return { serviceUrls };
}
