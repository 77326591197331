// @flow

import * as React from 'react';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';
import styles from './ViewSitesList.module.css';
import BaseContentContainer from '../../layout/BaseContentContainer';
import type { Site } from '../../../types/site';
import { getSites } from '../../../actions/sites';
import SiteCard from '../../cards/SiteCard';
import SiteCardList from '../../lists/SiteCardList';
import withPageUtils from '../../../containers/views/withPageUtils';
import SitesFilter from '../../../containers/filter/SitesFilter';
import { filterSites } from '../../../utils/textFilters';

type Props = {
  setPageTitle: string => void,
  setPageIcon: string => void,
  getSites: void => void,
  sites: Site[],
  isLoading: boolean,
  siteFilterValue?: string,
  setSiteFilter: Function,
};

type State = {
  isFetching: boolean,
};

class ViewSitesList extends React.Component<Props, State> {
  state = {
    isFetching: false,
  };

  componentDidMount() {
    this.props.setPageTitle('Sites');
    this.props.setPageIcon('Sites');
    this.loadSites();
  }

  loadSites() {
    this.setState({ isFetching: true });
    this.props
      .getSites()
      .then(() => {
        this.setState({ isFetching: false });
      })
      .catch(() => {
        this.setState({
          isFetching: false,
        });
      });
  }
  // TODO had to move this up out of the list because the list is using grid, and not sure how to escape out of the grid styling.
  // Perhaps arne / stanly you guys can figure this out. Ideally this logic would exist in the SiteCard List similiar to the notification list.
  // Otherwise we may have to duplicate this logic

  renderSiteCardList = (sites: any[]) =>
    sites.length ? (
      <SiteCardList>{sites}</SiteCardList>
    ) : (
      <div className={styles.messageContainer}>
        <div>
          <h5>Oops! We can’t seem to find the result you’re looking for.</h5>
        </div>
        <div>
          <span>No sites found</span>
        </div>
      </div>
    );
  render() {
    const { isFetching } = this.state;
    const { sites, siteFilterValue = '' } = this.props;
    const lowerCaseFilterValue = siteFilterValue.toLowerCase();

    const filteredSites = sites
      .filter(filterSites.bind(this, lowerCaseFilterValue))
      .map(s => (
        <SiteCard
          onClick={() => navigate(`sites/${s.id.toString()}/monitor`)}
          key={`site_list_${s.id.toString()}`}
          site={s}
          highlight={lowerCaseFilterValue}
        />
      ));

    return (
      <React.Fragment>
        <SitesFilter error={!filteredSites.length} />
        <BaseContentContainer hasFilterBar isLoading={this.props.isLoading}>
          {isFetching && <div>...loading</div>}

          {!isFetching && this.renderSiteCardList(filteredSites)}
        </BaseContentContainer>
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = {
  getSites,
};

const mapStateToProps = state => ({
  siteFilterValue: state.siteFilter,
  sites: state.sites,
  isLoading: state.loading.sites,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPageUtils(ViewSitesList));
