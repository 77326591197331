// @flow

import * as React from 'react';
import classNames from '../../../utils/classNames';
import styles from './Button.module.css';

type Props = {
  children: React.Node,
  onClick?: any => void,
  isLoading?: boolean,
  disabled?: boolean,
  secondary?: boolean,
  width?: string,
  fullWidth?: boolean,
  type?: 'button' | 'submit' | 'reset',
  size?: 'small' | 'regular' | 'large',
  icon?: boolean,
  className?: string[],
};

export const Button = ({
  children,
  onClick,
  disabled = false,
  secondary = false,
  fullWidth = false,
  isLoading = false,
  type = 'button',
  width = 'auto',
  size = 'regular',
  icon = false,
  className = [],
}: Props) => (
  <button
    style={{ width }}
    type={type}
    className={classNames(
      styles.button,
      fullWidth ? styles.fullWidth : null,
      !secondary && !icon ? styles.primary : null,
      secondary ? styles.secondary : null,
      disabled ? styles.disabled : null,
      !icon ? styles[size] : null,
      isLoading ? styles.isLoading : null,
      icon ? styles.icon : null,
      className
    )}
    disabled={disabled || isLoading}
    onClick={typeof onClick === 'function' ? onClick : undefined}
  >
    {children}
  </button>
);

export default Button;
