// @flow

import * as React from 'react';

type Props = {
  size: number,
};

export default ({ size, ...rest }: Props) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    {...rest}
  >
    <path
      d="M8 0a8 8 0 1 0 8 8 8 8 0 0 0-8-8zm0 15a7 7 0 1 1 5.55-11.25L8 10.26 4.35 6.65a.5.5 0 0 0-.71.71l4 4a.5.5 0 0 0 .36.14.5.5 0 0 0 .36-.17l5.75-6.7A7 7 0 0 1 8 15z"
      fill="currentColor"
    />
  </svg>
);
