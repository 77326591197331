// @flow

import * as React from 'react';
import styles from './DropDownItem.module.css';

type Props = {
  children: React.Node,
};

const dropDownItem = ({ children, ...props }: Props) => (
  <li className={styles.wrapper} {...props}>
    {children}
  </li>
);

export default dropDownItem;
