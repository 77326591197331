// @flow

import React from 'react';
import styles from './RemoveSiteModal.module.css';
import type { Site } from '../../types/site';
import Button from '../ui/Button';
import BaseModal from './BaseModal';

type Props = {
  sitePrimedForRemove?: Site | Object,
  onSubmit: Function,
  onClose: Function,
};

export default ({ sitePrimedForRemove = {}, onSubmit, onClose }: Props) => (
  <BaseModal onClose={onClose} title="Remove site">
    <div className={styles.infoText}>
      Are you sure you would like to remove{' '}
      <strong>
        {sitePrimedForRemove.title} with {sitePrimedForRemove.equipment.length}{' '}
        Equipment
      </strong>{' '}
      from this configuration?
    </div>
    <div className={styles.buttonGroup}>
      <Button onClick={() => onSubmit(sitePrimedForRemove)} primary>
        Yes
      </Button>
      <Button onClick={onClose} secondary>
        No
      </Button>
    </div>
  </BaseModal>
);
