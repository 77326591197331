// @flow

import React from 'react';
import styles from './SaveConfigurationModal.module.css';
import Button from '../ui/Button';
import BaseModal from './BaseModal';

type Props = {
  onSubmit: Function,
  onClose: Function,
};

export default ({ onSubmit, onClose }: Props) => (
  <BaseModal onClose={onClose} title="Save Changes">
    <div className={styles.infoText}>
      Are you sure you would like to save the changes on configuration?
    </div>
    <div className={styles.buttonGroup}>
      <Button onClick={() => onSubmit()} primary>
        Yes
      </Button>
      <Button onClick={onClose} secondary>
        No
      </Button>
    </div>
  </BaseModal>
);
