// @flow

import * as React from 'react';
import styles from './StepRollout.module.css';
import Checkbox from '../../ui/Checkbox';
import classNames from '../../../utils/classNames';
import PathBreadCrumbs from '../../breadcrumbs/PathBreadCrumbs';
import IconEquipmentFactory from '../../icons/IconEquipmentFactory';
import IconUiFactory from '../../icons/IconUiFactory';
import type { Site } from '../../../types/site';
import type { Equipment } from '../../../types/equipment';
import { UiChip } from '../../Tables/Cells';
import RemoveSiteModal from '../../Modals/RemoveSiteModal';

type Props = {
  sites: Site[],
  handleCollapseSite: number => void,
  handleEquipmentSelect: number => void,
  selectedSites: Set<number>,
  selectedEquipment: Set<number>,
  removeSite: Site => void,
};

type State = {
  removeSiteModalOpen: boolean,
  sitePrimedForRemove?: Site,
};

class StepRollout extends React.Component<Props, State> {
  state = {
    removeSiteModalOpen: false,
    sitePrimedForRemove: undefined,
  };

  handleRemoveSite = (sitePrimedForRemove: Site) => {
    this.setState({
      removeSiteModalOpen: true,
      sitePrimedForRemove,
    });
  };

  renderSpaceRows = () => (
    <tr>
      <td>
        <div>Currently cannot rollout configuration on spaces</div>
      </td>
    </tr>
  );

  renderEquipmentRows(site: Site) {
    const { handleEquipmentSelect, selectedEquipment } = this.props;

    const { equipment } = site;

    if (equipment === undefined) {
      return (
        <tr>
          <td>
            <div>This site has no equipment.</div>
          </td>
        </tr>
      );
    }

    return equipment.map((item: Equipment) => (
      <tr
        key={`renderEquipmentRows${item.id}`}
        onClick={() => handleEquipmentSelect(item.id)}
        className={styles.filterRow}
      >
        <td>
          <IconEquipmentFactory
            id={item.type.alias}
            size={16}
            classes={{ icon: styles.icon }}
          />
          {item.title}
        </td>
        <td>
          {item._path !== undefined && (
            <PathBreadCrumbs collection={item._path || []} />
          )}
        </td>
        <td className={styles.action}>
          <Checkbox
            checked={selectedEquipment.has(item.id)}
            onChange={() => handleEquipmentSelect(item.id)}
            id={`checkbox_${site.id}_${item.id}`}
            size={16}
          />
        </td>
      </tr>
    ));
  }

  render() {
    const {
      handleCollapseSite,
      selectedEquipment,
      sites,
      selectedSites,
    } = this.props;

    const { removeSiteModalOpen, sitePrimedForRemove } = this.state;

    if (!sites.length) {
      return (
        <div className={styles.messageContainer}>
          <div>
            <h5>
              {' '}
              Please search for sites to rollout their equipment and spaces
            </h5>
          </div>
          <div>
            <span>No sites selected</span>
          </div>
        </div>
      );
    }

    return (
      <div>
        {sites.map(site => {
          const collapsed = selectedSites.has(site.id);
          const selectedEquipmentBySite = site.equipment
            ? site.equipment.reduce(
                (sum, equipment) =>
                  selectedEquipment.has(equipment.id) ? sum + 1 : sum,
                0
              )
            : 0;

          const totalEquipment = site.equipment ? site.equipment.length : 0;

          const selectedSpacesBySite = 0;
          const totalSpaces = 0;

          return (
            <div
              key={site.id}
              className={classNames(
                styles.siteListItem,
                collapsed
                  ? styles.siteListItemNotCollapsed
                  : styles.siteListItemCollapsed
              )}
            >
              <div>
                <div
                  className={styles.siteTitle}
                  onClick={() => handleCollapseSite(site.id)}
                  onKeyPress={() => handleCollapseSite(site.id)}
                  role="button"
                  tabIndex="0"
                >
                  <IconUiFactory
                    size={20}
                    id={collapsed ? 'chevronDown' : 'chevronRight'}
                    classes={{ icon: styles.siteTitleIcon }}
                  />
                  <div> {site.title} </div>
                </div>
                <div className={styles.collapsedInfo}>
                  <div className={styles.siteAddress}>{site.address}</div>
                  <div
                    className={styles.removeSite}
                    onClick={() => this.handleRemoveSite(site)}
                    onKeyPress={() => this.handleRemoveSite(site)}
                    role="button"
                    tabIndex="0"
                  >
                    <UiChip
                      classes={styles.removeSiteIconColor}
                      label="remove site"
                      iconId="close"
                    />
                  </div>
                </div>
              </div>

              <table className={styles.table}>
                <thead>
                  <tr>
                    <th width="30%">Equipment</th>
                    <th width="50%">{collapsed && 'Location'}</th>
                    <th
                      width="20%"
                      className={classNames([
                        styles.actionHeader,
                        styles.action,
                      ])}
                    >
                      <span className={styles.filterSelected}>
                        {selectedEquipmentBySite}
                      </span>
                      /
                      <span className={styles.filterTotal}>
                        {totalEquipment}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>{this.renderEquipmentRows(site)}</tbody>
              </table>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th width="30%">Spaces</th>
                    <th width="50%">{collapsed && 'Location'}</th>
                    <th
                      width="20%"
                      className={classNames([
                        styles.actionHeader,
                        styles.action,
                      ])}
                    >
                      <span className={styles.filterSelected}>
                        {selectedSpacesBySite}
                      </span>
                      /<span className={styles.filterTotal}>{totalSpaces}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>{this.renderSpaceRows(site)}</tbody>
              </table>
            </div>
          );
        })}
        {removeSiteModalOpen ? (
          <RemoveSiteModal
            sitePrimedForRemove={sitePrimedForRemove}
            onSubmit={() => {
              this.props.removeSite(sitePrimedForRemove);
              this.setState({
                removeSiteModalOpen: false,
                sitePrimedForRemove: undefined,
              });
            }}
            onClose={() =>
              this.setState({
                removeSiteModalOpen: false,
                sitePrimedForRemove: undefined,
              })
            }
          />
        ) : null}
      </div>
    );
  }
}

export default StepRollout;
