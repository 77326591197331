// @flow

import * as React from 'react';
import IconSensorTypeFactory from '../../../icons/IconSensorTypeFactory';
import Switch from '../../../Switch';
import classNames from '../../../../utils/classNames';
import {
  defaultDoorParameters,
  sentinelTypes,
} from '../../../../types/sentinel';
import styles from './ConditionDoor.module.css';
import type { ParametersSwitch } from '../../../../types/sentinel';
import { UiChip } from '../../../Tables/Cells';

type Props = {
  onChange: (string, string) => void,
  parameters?: ParametersSwitch,
};

const ConditionDoor = ({
  onChange,
  parameters = defaultDoorParameters,
}: Props) => (
  <div className={styles.wrapper}>
    <div className={styles.accessDoor}>
      <div
        className={classNames(
          styles.left,
          parameters && parameters.openClosed === 'CLOSED' && styles.doorClosed
        )}
      >
        <IconSensorTypeFactory
          size={80}
          id={sentinelTypes.DOOR}
          parameters={parameters}
        />
      </div>
      <div className={styles.right}>
        <div className={styles.switchWrapper}>
          <Switch
            id="doorAccess"
            onChange={() =>
              onChange(
                'openClosed',
                parameters && parameters.openClosed === 'OPEN'
                  ? 'CLOSED'
                  : 'OPEN'
              )
            }
            checked={parameters && parameters.openClosed === 'OPEN'}
          />
          <span className={styles.switchLabel}>
            {`Door ${parameters.openClosed === 'OPEN' ? 'opened' : 'closed'}`}
          </span>
        </div>
        <p>
          The notifications will be triggered when the door is{' '}
          {parameters && parameters.openClosed === 'OPEN' ? 'opened' : 'closed'}
        </p>
      </div>
    </div>
    <div className={styles.reactionTime}>
      <div className={styles.left}>
        <span className={styles.conditionLabel}>Reaction time</span>
      </div>
      <div className={classNames(styles.right, styles.helpText)}>
        <UiChip
          customIconColor={styles.infoIcon}
          classes={styles.infoIcon}
          label="Specify the time after which the first notification will be generated."
          iconId="info"
        />
      </div>
    </div>
  </div>
);

export default ConditionDoor;
