// @flow

import * as React from 'react';
import SensorsList from '../../Tables/SensorsList';
import { fetchSensorsByResource } from '../../../actions/sensors';
import type { Sensor } from '../../../types/sensors';
import type { Equipment } from '../../../types/equipment';

type Props = {
  equipment: Equipment,
};

type State = {
  sensorsError: boolean,
  sensorsLoading: boolean,
  sensors: Sensor[],
};

class ViewEquipmentSensors extends React.Component<Props, State> {
  state = {
    sensors: [],
    sensorsLoading: false,
    sensorsError: false,
  };

  componentDidMount() {
    this.setState({ sensorsLoading: true });
    fetchSensorsByResource(this.props.equipment.id)
      .then(sensors =>
        this.setState({ sensors, sensorsError: false, sensorsLoading: false })
      )
      .catch(() =>
        this.setState({ sensorsError: true, sensorsLoading: false })
      );
  }

  renderItems = (
    type: string,
    isLoading: boolean,
    hasError: boolean,
    component: any
  ) => {
    if (hasError) {
      return <p>...Error during {type} loading</p>;
    }

    if (isLoading) {
      return <p>...Loading</p>;
    }

    return component;
  };

  render() {
    const { sensors, sensorsLoading, sensorsError } = this.state;
    const { equipment } = this.props;

    return (
      <React.Fragment>
        {this.renderItems(
          'Sensors',
          sensorsLoading,
          sensorsError,
          <SensorsList equipment={equipment} sensors={sensors} />
        )}
      </React.Fragment>
    );
  }
}

export default ViewEquipmentSensors;
