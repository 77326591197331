// @flow

import * as React from 'react';
import { Router } from '@reach/router';
import MenuTabBar from '../../MenuTabBar';
import Tabs from '../../Tabs/Tabs';
import TabLink from '../../Tabs/TabLink';
import BaseContentContainer from '../../layout/BaseContentContainer';
import ViewSiteMonitor from './ViewSiteMonitor';
import ViewSiteSensorsAndGateways from './ViewSiteSensorsAndGateways';
// import ViewSiteNotifications from './ViewSiteNotifications';
// import ViewGeneralInformation from './ViewGeneralInformation';
import type { Site } from '../../../types/site';
import { fetchSiteById } from '../../../actions/sites';
import withPageUtils from '../../../containers/views/withPageUtils';
import ViewSiteEquipment from './ViewSiteEquipment';
import ViewSiteSpaces from './ViewSiteSpaces';
import ViewSiteSchedules from './ViewSiteSchedules';

type State = {
  site: ?Site,
};

type Props = {
  siteId?: number,
  setPageTitle: string => void,
  setPageIcon: string => void,
  setPageSubtitle: string => void,
  setPageType: string => void,
};

class ViewSiteIndex extends React.Component<Props, State> {
  state = {
    site: null,
  };

  componentDidMount() {
    const { siteId } = this.props;

    if (!siteId) {
      return;
    }

    this.loadSite(siteId);
  }
  // Still need to figure out getting site list from here.
  componentDidUpdate(prevProps) {
    // Logic for when the siteId switches due to route change coming from the connected header
    if (this.props.siteId !== prevProps.siteId) {
      if (!this.props.siteId) {
        return;
      }

      this.loadSite(this.props.siteId);
    }
  }

  componentWillUnmount() {
    this.props.setPageType('');
    this.props.setPageSubtitle('');
  }

  async loadSite(siteId: number) {
    try {
      const site = await fetchSiteById(siteId);
      this.setState({ site });

      // Sets header information for site.
      this.props.setPageTitle(site.title);
      this.props.setPageType('sites');
      this.props.setPageSubtitle(site.address);
    } catch (err) {
      // eslint-disable-next-line
      console.log(err);
    }
  }

  render() {
    const { site } = this.state;

    return (
      <React.Fragment>
        <MenuTabBar>
          <Tabs>
            <TabLink to="monitor">Monitor</TabLink>
            <TabLink to="equipment">Equipment</TabLink>
            <TabLink to="sensors-gateways">Sensors & Gateways</TabLink>
            <TabLink to="schedules">Schedules</TabLink>
            {/* <TabLink to="notifications">Notifications</TabLink> */}
            {/* <TabLink to="information">General Information</TabLink> */}
          </Tabs>
        </MenuTabBar>

        <BaseContentContainer>
          {site && site.id ? (
            <Router primary={false}>
              <ViewSiteMonitor path="monitor" site={site} />
              <ViewSiteEquipment path="equipment" site={site} />
              <ViewSiteSpaces path="spaces" site={site} />
              <ViewSiteSensorsAndGateways path="sensors-gateways" site={site} />
              <ViewSiteSchedules path="schedules" />
              {/* <ViewSiteNotifications path="notifications" site={site} /> */}
              {/* <ViewGeneralInformation path="information" site={site} /> */}
            </Router>
          ) : null}
        </BaseContentContainer>
      </React.Fragment>
    );
  }
}

export default withPageUtils(ViewSiteIndex);
