// @flow

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */

import * as React from 'react';
import styles from './SelectField.module.css';
import Checkbox from '../Checkbox/Checkbox';

type Props = {
  label?: string,
  setValue: any,
  options: { id: number, title: string }[],
  defaultValue: number[],
  valueRenderer?: any => string,
};

type State = {
  menuIsOpen: boolean,
};

class SelectField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      menuIsOpen: false,
    };

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  wrapperRef: any;

  handleClickOutside = (event: MouseEvent) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState(() => ({ menuIsOpen: false }));
    }
  };

  handleOnFocus = () => this.setState({ menuIsOpen: !this.state.menuIsOpen });

  handleAddSelection = (id: number) => this.props.setValue(id);

  render() {
    const { label, options, defaultValue } = this.props;
    const { menuIsOpen } = this.state;

    const filteredSelection = options.filter(option =>
      defaultValue.includes(option.id)
    );

    const defaultRenderer = option => option.title;

    const valueRenderer = this.props.valueRenderer || defaultRenderer;

    return (
      <div className={styles.group} ref={this.wrapperRef}>
        {label !== undefined && (
          <label htmlFor="" className={styles.label}>
            {label}
          </label>
        )}

        <div className={styles.selectContainer}>
          <div
            onClick={this.handleOnFocus}
            role="button"
            className={styles.root}
            tabIndex="0"
          >
            {filteredSelection.length} selected groups
          </div>

          {menuIsOpen ? (
            <div className={styles.menu}>
              {options.map(option => (
                <div
                  key={`filterOption${option.id.toString()}`}
                  className={`${styles.selectItems}`}
                >
                  <Checkbox
                    onChange={() => this.handleAddSelection(option.id)}
                    checked={defaultValue.includes(option.id)}
                    label={valueRenderer(option)}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default SelectField;
