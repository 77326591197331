// @flow

import * as React from 'react';
import TextField from '../../ui/TextField';
import styles from './StepHandling.module.css';
import type { SentinelHandler } from '../../../types/sentinel';
import { sentinelHandlerChannel } from '../../../types/sentinel';
import SelectField from '../../ui/SelectField';
import { fetchGroups } from '../../../actions/groups';
import type { Group } from '../../../types/group';

type Props = {
  onChange: (SentinelHandler, number) => void,
  handlers: SentinelHandler[],
};

type State = {
  groups: Group[],
};

class StepHandling extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      groups: [],
    };
  }

  componentDidMount() {
    this.loadGroups();
  }

  handleChange = (name: string, index: number) => (event: any) => {
    const { value } = event.target;
    const { handlers = [] } = this.props;

    const handler = handlers.length > index - 1 ? handlers[index] : null;

    if (!handler) {
      return;
    }

    if (name === 'recipients') {
      handler.recipients = [value.trim()];
      handler.channel = sentinelHandlerChannel.USER_GROUP_EMAIL;
    }

    this.props.onChange(handler, index);
  };

  handleGroupChange = (handlerIndex: number) => (id: number) => {
    const { handlers = [] } = this.props;

    const handler =
      handlers.length > handlerIndex - 1 ? handlers[handlerIndex] : null;

    if (!handler) {
      return;
    }

    handler.channel = sentinelHandlerChannel.USER_GROUP_EMAIL;
    const recipients = handler.recipients.map(r => parseInt(r, 10));
    this.props.onChange(
      {
        ...handler,
        recipients: recipients.includes(id)
          ? recipients.filter(recipientId => recipientId !== id)
          : [...recipients, id],
      },
      handlerIndex
    );
  };

  loadGroups = () => {
    fetchGroups().then(groups => this.setState({ groups }));
  };

  render() {
    const { handlers = [] } = this.props;

    return (
      <div className={styles.stepWrapper}>
        <h5 className={styles.stepWrapperTitle}>
          Please define the actions and the staff handling the triggered
          notifications.
        </h5>

        {handlers.map((handler, index) => (
          <form
            className={styles.paddingTop}
            // eslint-disable-next-line react/no-array-index-key
            key={`StepHandlingElement${index}`}
          >
            <div className={styles.formHeading}>
              <div>Action Name *</div>
              <div>Assign *</div>
              <div>Channels</div>
              <div>Message</div>
            </div>
            <p>Once the first notification is triggered.</p>
            <div className={styles.formContent}>
              <div>
                <TextField
                  isTitle
                  value="First Action"
                  handleChange={this.handleChange('actionName', index)}
                />
              </div>

              <div>
                <SelectField
                  options={this.state.groups}
                  defaultValue={handler.recipients.map(r => parseInt(r, 10))}
                  setValue={this.handleGroupChange(index)}
                  valueRenderer={(group: Group) =>
                    `${group.title} (${group.organization.title})`
                  }
                />
              </div>

              <div>Email</div>
              <div>
                <TextField
                  placeholder="Optional message"
                  handleChange={this.handleChange('optionalMessage', index)}
                  value=""
                />
              </div>
            </div>
          </form>
        ))}
      </div>
    );
  }
}

export default StepHandling;
