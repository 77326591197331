// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';

type Props = {
  scales: Object,
  hoveredBar: Object,
  classes: any,
};

const styles = () => ({
  toolTipWrapper: {
    fontSize: '12px',
    lineHeight: '16px',
    fontStyle: 'italic',
    color: 'rgba(255, 255, 255, 0.19)',
    fontWeight: 'bold',
    padding: '6px 12px',
    backgroundColor: '#333e48',
    pointerEvents: 'none',
    whiteSpace: 'nowrap',
    transform: 'translate(-23%, -100%)',
    textAlign: 'center',
    '&:after': {
      content: '""',
      boxSizing: 'border-box',
      display: 'inline',
      fontSize: '10px',
      width: 0,
      height: 0,
      lineHeight: 1,
      color: 'rgba(0, 0, 0, 0.8)',
      position: 'absolute',
      pointerEvents: 'none',
      margin: '-1px 0 0 0',
      top: '100%',
      left: '50%',
      transform: 'translateX(-50%)',
      textAlign: 'center',
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      borderTop: '5px solid #333e48',
    },
    '& strong': {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '19px',
      color: '#ffffff',
      paddingBottom: '8px',
      display: 'block',
      fontStyle: 'normal',
    },
  },
  firstYear: {
    color: '#3fb4f4',
  },
  secondYear: {
    color: '#72f482',
  },
});

const ToolTip = ({ scales, hoveredBar, classes }: Props) => {
  if (!hoveredBar) {
    return null;
  }

  const { x0Scale, yScale } = scales;

  let topPosition;

  if (hoveredBar.years[0].value && hoveredBar.years[1].value) {
    if (hoveredBar.years[0].value > hoveredBar.years[1].value) {
      topPosition = hoveredBar.years[0].value;
    } else {
      topPosition = hoveredBar.years[1].value;
    }
  } else if (hoveredBar.years[0].value) {
    topPosition = hoveredBar.years[0].value;
  } else {
    topPosition = hoveredBar.years[1].value;
  }

  const styles = {
    top: `${yScale(topPosition) + 42}px`,
    left: `${x0Scale(hoveredBar.Month)}px`,
    position: 'absolute',
  };

  const firstYear = hoveredBar.years[0] ? hoveredBar.years[0].value : null;
  const secondYear = hoveredBar.years[1] ? hoveredBar.years[1].value : null;
  let difference = 0;

  if (firstYear && secondYear) {
    if (firstYear > secondYear) {
      difference = Math.round(
        ((firstYear.toFixed(2) / secondYear.toFixed(2)) * 100 - 100) * -1
      );
    } else if (firstYear < secondYear) {
      difference = Math.round(
        (secondYear.toFixed(2) / firstYear.toFixed(2)) * 100 - 100
      );
    } else {
      difference = 0;
    }
    difference = `${difference}%`;
  } else {
    difference = 'Not Available';
  }

  return (
    <div style={styles} className={classes.toolTipWrapper}>
      <strong>{difference}</strong>
      <span className={classes.firstYear}>
        {firstYear ? firstYear.toFixed(2) : 0}
      </span>{' '}
      vs{' '}
      <span className={classes.secondYear}>
        {secondYear ? secondYear.toFixed(2) : 0}
      </span>
      <br />
    </div>
  );
};

export default withStyles(styles)(ToolTip);
