// @flow

import type { MeasurementSystem } from './shared';

type Role = 'VIEWER' | 'MANAGER' | 'ADMIN';

export function getRoleTitle(role: Role | string): string {
  return role.charAt(0) + role.slice(1).toLowerCase();
}

export type User = {
  id: number,
  createdAt: string,
  updatedAt?: string,
  lastLoginAt?: string,
  organizationId: number,
  title: string,
  role: Role,
  firstName?: string,
  lastName?: string,
  email: string,
  measurementSystem?: MeasurementSystem,
  // scopes
  // organization
};
