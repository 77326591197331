// @flow

import { SET_EQUIPMENT_GROUPING } from '../actions/equipmentGrouping';

export const EquipmentGrouping = {
  ALPHABETICAL: 'GROUPING_ALPHABETICAL',
  EQUIPMENT_GROUP: 'GROUPING_EQUIPMENT_GROUP',
  EQUIPMENT_TYPE: 'GROUPING_EQUIPMENT_TYPE',
  SPACE: 'GROUPING_SPACE',
  STATUS: 'GROUPING_EQUIPMENT_STATUS',
};

const initialState = EquipmentGrouping.ALPHABETICAL;

export default (
  state: string = initialState,
  action: { type: string, payload: any }
) => {
  switch (action.type) {
    case SET_EQUIPMENT_GROUPING:
      return action.payload;
    default:
      return state;
  }
};
