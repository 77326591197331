// @flow

import type { User } from '../types/user';
import { USER_INFO_SUCCESS, USER_LOGOUT } from '../actions/user';

type UserState = User | {};

const initialState: UserState = {};

export default (
  state: UserState = initialState,
  action: { type: string, payload: { user: User } }
) => {
  switch (action.type) {
    case USER_INFO_SUCCESS: {
      return {
        ...state,
        ...action.payload.user,
      };
    }
    case USER_LOGOUT:
      return initialState;

    default: {
      return state;
    }
  }
};
