// @flow

import * as React from 'react';
import SensorsList from '../../Tables/SensorsList';
import { fetchSensorsByResource } from '../../../actions/sensors';
import type { Sensor } from '../../../types/sensors';
import GatewaysList from '../../Tables/GatewaysList/GatewaysList';
import { fetchGatewaysByResource } from '../../../actions/gateways';
import type { Gateway } from '../../../types/gateway';
import type { Site } from '../../../types/site';

type Props = {
  site: Site,
};

type State = {
  sensorsError: boolean,
  gatewaysError: boolean,
  gatewaysLoading: boolean,
  sensorsLoading: boolean,
  sensors: Sensor[],
  gateways: Gateway[],
};

class ViewSiteSensorsAndGateways extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      sensors: [],
      gateways: [],
      gatewaysLoading: false,
      sensorsLoading: false,
      sensorsError: false,
      gatewaysError: false,
    };
  }

  componentDidMount() {
    this.refreshSensorAndGateWayData();
  }

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.site.id !== prevProps.site.id) {
      this.refreshSensorAndGateWayData();
    }
  };

  refreshSensorAndGateWayData = () => {
    this.setState({ sensorsLoading: true, gatewaysLoading: true });
    fetchSensorsByResource(this.props.site.id, true)
      .then(sensors =>
        this.setState({ sensors, sensorsError: false, sensorsLoading: false })
      )
      .catch(() =>
        this.setState({ sensorsError: true, sensorsLoading: false })
      );

    fetchGatewaysByResource(this.props.site.id, true)
      .then(gateways =>
        this.setState({
          gateways,
          gatewaysError: false,
          gatewaysLoading: false,
        })
      )
      .catch(() =>
        this.setState({ gatewaysError: true, gatewaysLoading: false })
      );
  };

  renderItems = (
    type: string,
    isLoading: boolean,
    hasError: boolean,
    component: any
  ) => {
    if (hasError) {
      return <p>...Error during {type} loading</p>;
    }

    if (isLoading) {
      return <p>...Loading</p>;
    }

    return component;
  };

  render() {
    const {
      gateways,
      sensors,
      gatewaysError,
      gatewaysLoading,
      sensorsLoading,
      sensorsError,
    } = this.state;
    const { site } = this.props;

    return (
      <React.Fragment>
        <h4>Gateways</h4>
        {this.renderItems(
          'Gateways',
          gatewaysLoading,
          gatewaysError,
          <GatewaysList gateways={gateways} />
        )}

        <h4>Sensors</h4>
        {this.renderItems(
          'Sensors',
          sensorsLoading,
          sensorsError,
          <SensorsList site={site} sensors={sensors} />
        )}
      </React.Fragment>
    );
  }
}

export default ViewSiteSensorsAndGateways;
