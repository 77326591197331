// @flow

import * as React from 'react';
import styles from './SentinelTypeFlag.module.css';
import IconSensorTypeFactory from '../icons/IconSensorTypeFactory';

import type { SentinelType } from '../../types/sentinel';

type Props = {
  sentinelType?: SentinelType,
};

export default ({ sentinelType }: Props) =>
  sentinelType ? (
    <div className={styles.sentinelTypeIconContainer}>
      {IconSensorTypeFactory({ id: sentinelType, size: 20 })}
    </div>
  ) : null;
