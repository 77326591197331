// @flow

import * as React from 'react';
import type { User } from '../../../types/user';
import { fetchUsers } from '../../../actions/user';
import UserCard from '../../cards/UserCard';
import CardList from '../../lists/CardList';
import withPageUtils from '../../../containers/views/withPageUtils';

type Props = {
  setPageTitle: string => void,
};

type State = {
  users: User[],
  error: boolean,
};

class ViewUsersList extends React.Component<Props, State> {
  state = {
    users: [],
    error: false,
  };

  componentDidMount() {
    this.props.setPageTitle('Users');

    fetchUsers()
      .then(users => this.setState({ users, error: false }))
      .catch(() => this.setState({ error: true }));
  }

  render() {
    const { users, error } = this.state;

    if (error) {
      return <div>Error while loading users</div>;
    }

    return (
      <CardList>
        {users.map(user => (
          <UserCard key={user.id} user={user} />
        ))}
      </CardList>
    );
  }
}

export default withPageUtils(ViewUsersList);
