// @flow

import * as React from 'react';
import classNames from '../../../utils/classNames';
import styles from './AuthForm.module.css';
import TextField from '../../ui/TextField';
import Button from '../../ui/Button';
import Logo from '../../icons/energybox/Logo';
import LoginBackground from '../../../static/patterns/login_background.jpg';

type Props = {
  loginUser: ({ email: string, password: string }) => Promise<Error>,
};

type State = {
  email: string,
  password: string,
  error: boolean,
  loading: boolean,
};

class AuthForm extends React.Component<Props, State> {
  state = {
    email: '',
    password: '',
    error: false,
    loading: false,
  };

  handleInput = (name: string) => (e: any) =>
    this.setState({ [name]: e.target.value });

  validateForm = () => {
    const { email, password } = this.state;

    return email !== '' && password !== '';
  };

  handleLogin = (e: any) => {
    e.preventDefault();

    const { email, password } = this.state;

    this.setState({ loading: true });

    this.props
      .loginUser({ email, password })
      .catch(() => this.setState({ error: true, loading: false }));
  };

  render() {
    const { email, password, loading, error } = this.state;

    const formValid = this.validateForm();

    return (
      <React.Fragment>
        <header className={styles.header}>
          <Logo type="inverted" />
          <h1>Energybox</h1>
        </header>
        <section
          style={{ backgroundImage: `url(${LoginBackground})` }}
          className={styles.wrapper}
        >
          <form className={styles.form} onSubmit={this.handleLogin}>
            <div className={styles.formInnerWrapper}>
              <h2>Welcome</h2>

              <p>Login to your account</p>

              <div className={styles.formField}>
                <TextField
                  disabled={loading}
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={this.handleInput('email')}
                />
              </div>

              <div className={styles.formField}>
                <TextField
                  disabled={loading}
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={this.handleInput('password')}
                />
              </div>

              <div
                className={classNames(
                  styles.formField,
                  styles.loginButtonWrapper
                )}
              >
                <Button
                  type="submit"
                  width="100%"
                  disabled={loading || formValid === false}
                  primary
                >
                  Login
                </Button>
              </div>

              {error && (
                <div className={styles.formField}>
                  <p>Error during login</p>
                </div>
              )}
            </div>
          </form>
        </section>
      </React.Fragment>
    );
  }
}

export default AuthForm;
