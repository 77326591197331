// @flow

import * as React from 'react';
import { Router } from '@reach/router';
import ViewMonitoringEquipmentList from '../../containers/views/monitoring/equipment/ViewMonitoringEquipmentList';
import ViewEquipmentIndex from './equipment/ViewEquipmentIndex';

const ViewEquipment = () => (
  <Router>
    <ViewMonitoringEquipmentList path="/" />
    <ViewEquipmentIndex path=":equipmentId/*" />
  </Router>
);

export default ViewEquipment;
