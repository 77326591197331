// @flow

import {
  EQUIPMENT_FAILURE,
  EQUIPMENT_REQUEST,
  EQUIPMENT_SUCCESS,
} from '../actions/equipment';
import { SITES_FAILURE, SITES_REQUEST, SITES_SUCCESS } from '../actions/sites';
import {
  NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_FAILURE,
  NOTIFICATIONS_SUCCESS,
} from '../actions/notifications';

const initialState = {
  equipment: false,
  sites: false,
};

export default (state: any = initialState, action: { type: string }) => {
  switch (action.type) {
    case EQUIPMENT_REQUEST: {
      return {
        ...state,
        equipment: true,
      };
    }
    case EQUIPMENT_SUCCESS:
    case EQUIPMENT_FAILURE:
      return {
        ...state,
        equipment: false,
      };

    case SITES_REQUEST: {
      return {
        ...state,
        sites: true,
      };
    }

    case SITES_SUCCESS:
    case SITES_FAILURE:
      return {
        ...state,
        sites: false,
      };

    case NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        notifications: true,
      };
    }

    case NOTIFICATIONS_SUCCESS:
    case NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notifications: false,
      };

    default: {
      return state;
    }
  }
};
