// @flow
import * as React from 'react';
import {
  NotificationInactive,
  NotificationActive,
  NotificationDismissed,
  Condition,
  Time,
  Message,
} from './notifications';
import type { IconFactory } from '../../types/icon';

export default ({ classes, size, id }: IconFactory) => {
  switch (id) {
    case 'inactive':
      return <NotificationInactive classes={classes} size={size} />;
    case 'active':
      return <NotificationActive classes={classes} size={size} />;
    case 'dismissed':
      return <NotificationDismissed classes={classes} size={size} />;
    case 'condition':
      return <Condition classes={classes} size={size} />;
    case 'time':
      return <Time classes={classes} size={size} />;
    case 'chat':
    case 'rollout':
      return <Message classes={classes} size={size} />;
    default:
      return null;
  }
};
