// @flow

import * as React from 'react';
import styles from './InfoChip.module.css';

type Props = {
  title: string,
  description?: string | number | string[],
};

const InfoChip = ({ title, description }: Props) => (
  <div className={styles.root}>
    <h5 className={styles.title}>{title}</h5>
    {description && Array.isArray(description) ? (
      description.map(e => (
        <p key={e} className={styles.description}>
          {e}
        </p>
      ))
    ) : (
      <p className={styles.description}>{description}</p>
    )}
  </div>
);

export default InfoChip;
