// @flow

import React from 'react';
import styles from './DeleteSentinelModal.module.css';
import type { Sentinel } from '../../types/sentinel';
import Button from '../ui/Button';
import BaseModal from './BaseModal';

type Props = {
  sentinelPrimedForDelete?: Sentinel | Object,
  onSubmit: Function,
  onClose: Function,
};

export default ({ sentinelPrimedForDelete = {}, onSubmit, onClose }: Props) => (
  <BaseModal onClose={onClose} title="Delete Configuration">
    <div className={styles.infoText}>
      This configuration is applied on{' '}
      <strong>{sentinelPrimedForDelete.targets.length} sites</strong>. Are you
      sure you want to delete the{' '}
      <strong>{sentinelPrimedForDelete.title}</strong>?
    </div>
    <div className={styles.buttonGroup}>
      <Button onClick={() => onSubmit(sentinelPrimedForDelete)} primary>
        Yes
      </Button>
      <Button onClick={onClose} secondary>
        Cancel
      </Button>
    </div>
  </BaseModal>
);
