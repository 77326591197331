import Loadable from 'react-loadable';
import Loader from './Loader';

export const ViewMonitoringEquipmentList = Loadable({
  loader: () =>
    import('../containers/views/monitoring/equipment/ViewMonitoringEquipmentList'),
  loading: Loader,
});

export const ViewMonitoringSingleEquipment = Loadable({
  loader: () =>
    import('../containers/views/monitoring/equipment/ViewMonitoringSingleEquipment'),
  loading: Loader,
});

export const ViewNotifications = Loadable({
  loader: () => import('../components/views/ViewNotifications'),
  loading: Loader,
});

export const ViewLogin = Loadable({
  loader: () => import('../components/views/ViewLogin'),
  loading: Loader,
});

export const ViewDashboard = Loadable({
  loader: () => import('../components/views/ViewDashboard'),
  loading: Loader,
});

export const ViewProfile = Loadable({
  loader: () => import('../components/views/ViewProfile'),
  loading: Loader,
});
