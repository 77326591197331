/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
// @flow

import * as React from 'react';
import styles from './ListRow.module.css';
import classNames from '../../../utils/classNames';

type Props = {
  children: React.Node,
  disabled?: boolean,
  autoHeight?: boolean,
  extraClassNames?: string[],
  size?: 'small' | 'medium' | 'large' | 'auto',
  onClick?: any => void,
  extraStyles: any,
};

const ListRow = ({
  children,
  onClick,
  disabled = false,
  extraClassNames = [],
  size = 'medium',
  autoHeight = false,
  extraStyles = {},
}: Props) => (
  <div
    onClick={typeof onClick === 'function' ? onClick : undefined}
    className={classNames(
      styles.row,
      autoHeight ? undefined : styles[size],
      disabled ? styles.disabled : undefined,
      ...extraClassNames
    )}
    style={extraStyles}
  >
    {children}
  </div>
);

export default ListRow;
