// @flow

import type { Notification } from '../types/notification';
import {
  DISMISS_NOTIFICATION_SUCCESS,
  NOTIFICATIONS_CLEAR,
  NOTIFICATIONS_SUCCESS,
} from '../actions/notifications';

const initialState: Notification[] = [];

export default (
  state: Notification[] = initialState,
  action: { type: string, payload: any }
) => {
  switch (action.type) {
    case NOTIFICATIONS_SUCCESS: {
      return [...action.payload];
    }

    case DISMISS_NOTIFICATION_SUCCESS: {
      return [...state.filter(e => e.id !== action.payload.id), action.payload];
    }

    case NOTIFICATIONS_CLEAR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
