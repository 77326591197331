// @flow

import React from 'react';
import IconSensorTypeFactory from '../../icons/IconSensorTypeFactory';

import styles from './NotificationConfigurationChip.module.css';

type Props = {
  title: string,
  description?: string,
  sentinelType: string,
};

const NotificationConfigurationChip = ({
  title,
  description = '',
  sentinelType,
}: Props) => (
  <div className={styles.iconAndTextGrouping}>
    <IconSensorTypeFactory size={20} id={sentinelType} />
    <div className={styles.textGrouping}>
      <span className={styles.titleText}> {title} </span>
      {description && <span className={styles.pathText}> {description} </span>}
    </div>
  </div>
);

export default NotificationConfigurationChip;
