// @flow

import React from 'react';

import IconNotificationFactory from '../../icons/IconNotificationFactory';
import styles from './SentinelConfigurationDetailsChip.module.css';
import type {
  Parameters,
  Sentinel,
  SentinelType,
} from '../../../types/sentinel';
import {
  comparisonTypesToSymbol,
  sentinelTypes,
} from '../../../types/sentinel';

import formatTemperature from '../../../containers/helper/formatTemperature';

function paramToText(paramObject: Parameters, sentinelType: SentinelType) {
  switch (sentinelType) {
    case sentinelTypes.TEMPERATURE: {
      const value = parseFloat(paramObject.threshold);

      return `${
        comparisonTypesToSymbol[paramObject.comparisonType || 'default']
      } ${formatTemperature(value)}`;
    }

    case sentinelTypes.DOOR: {
      let str = '';

      if (paramObject.openClosed) {
        str = paramObject.openClosed;
      }

      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    default:
      return '';
  }
}

function handlersToText(handlerSize: number = 0) {
  return `${handlerSize} Group${handlerSize !== 1 ? 's' : ''}`;
}

type Props = {
  sentinel: Sentinel,
};

const SentinelConfigurationDetailsChip = ({ sentinel }: Props) => (
  <div className={styles.horizontalGrouping}>
    <div className={styles.textAndIconGroupingVertical}>
      <span>{paramToText(sentinel.parameters, sentinel.sentinelType)}</span>
      <span className={styles.iconStyling}>
        <IconNotificationFactory size={16} id="condition" />
      </span>
    </div>

    <div className={styles.textAndIconGroupingVertical}>
      <span>{`${'24'} hr`}</span>
      <span className={styles.iconStyling}>
        <IconNotificationFactory size={16} id="time" />
      </span>
    </div>

    <div className={styles.textAndIconGroupingVertical}>
      <span>{handlersToText(sentinel.handlers.length)}</span>
      <span className={styles.iconStyling}>
        <IconNotificationFactory size={16} id="rollout" />
      </span>
    </div>
  </div>
);

export default SentinelConfigurationDetailsChip;
