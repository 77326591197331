// @flow

import type { Site } from '../types/site';
import { SITE_SUCCESS, SITES_SUCCESS } from '../actions/sites';

const initialState: Site[] = [];

export default (
  state: Site[] = initialState,
  action: { type: string, payload: any }
) => {
  switch (action.type) {
    case SITES_SUCCESS:
      return [...action.payload];

    case SITE_SUCCESS:
      return [...state.filter(s => s.id !== action.payload.id), action.payload];

    default: {
      return state;
    }
  }
};
