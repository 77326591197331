// @flow

import * as React from 'react';
import { Link } from '@reach/router';
import * as routes from '../../routes';

import MenuSidebarItem from './MenuSidebarItem';
import Icon from '../Icon';
import classNames from '../../utils/classNames';
import styles from './MenuSidebar.module.css';
import LogoutModal from '../Modals/LogoutModal';
import ActivityChip from '../../components/Tables/Cells/ActivityChip';

const MenuItems = [
  {
    to: routes.HOME,
    icon: 'dashboard',
  },
  {
    to: routes.NOTIFICATIONS,
    custom: <ActivityChip type="all" resourceId={0} />,
  },
  {
    to: routes.MONITORING_LIST_EQUIPMENT,
    icon: 'equipment',
  },
  {
    to: routes.MONITORING_LIST_SITES,
    icon: 'site',
  },
  {
    to: routes.USERS,
    icon: 'user',
  },
];

type Props = {
  isFixed?: boolean,
};

type State = {
  logoutModalOpen: boolean,
};

// = ({ isFixed = false }: Props) =>
class MenuSidebar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      logoutModalOpen: false,
    };
  }

  toggleLogoutModal = () => {
    this.setState({ logoutModalOpen: !this.state.logoutModalOpen });
  };

  render() {
    return (
      <ul
        className={classNames(
          styles.nav,
          this.props.isFixed ? styles.navFixed : undefined
        )}
      >
        {this.state.logoutModalOpen && (
          <LogoutModal onClose={this.toggleLogoutModal} />
        )}

        <li className={styles.navItem}>
          <Link to="/" className={styles.logoLink}>
            <Icon size={25} icon="logo" />
          </Link>
        </li>
        {MenuItems.map(item => (
          <MenuSidebarItem
            custom={item.custom}
            key={item.to}
            to={item.to}
            icon={item.icon}
          />
        )).concat(
          <MenuSidebarItem
            key="MenuSidebarItemLogout"
            classes={[styles.topPadding]}
            icon="logout"
            onClick={this.toggleLogoutModal}
          />
        )}
      </ul>
    );
  }
}

export default MenuSidebar;
