import { connect } from 'react-redux';
import { setEquipmentTypeListFilter } from '../../actions/equipmentFilter';
import EquipmentMenuFilter from '../../components/Filters/FilterMenus/EquipmentMenuFilter';
import { EquipmentFilters } from '../../reducers/equipmentFilter';

const mapStateToProps = state => ({
  filterGroupItems: state.equipmentTags.equipmentGroups,
  filterTypeItems: state.equipmentTags.equipmentTypes,
  activeFilter: state.equipmentFilter[EquipmentFilters.TYPE],
});

const mapDispatchToProps = {
  setTypeFilter: setEquipmentTypeListFilter,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipmentMenuFilter);
