// @flow

import React from 'react';
import NotificationIconFactory from '../../icons/IconNotificationFactory';
import styles from './AlertChip.module.css';

type Props = {
  status: 'active' | 'inactive',
};

const AlertChip = ({ status }: Props) => (
  <div className={styles.iconAndTextGrouping}>
    <div className={status === 'active' ? styles.iconStyle : ''}>
      <NotificationIconFactory size={20} id={status} />
    </div>
  </div>
);

export default AlertChip;
