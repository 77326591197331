import { navigate } from '@reach/router';
import { fetchApi } from '../middleware/api';

const USER_LOGIN = 'USER_LOGIN';
export const userLogin = () => ({
  type: USER_LOGIN,
});

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

const fetchLoginUser = payload =>
  fetchApi({
    endpoint: '/api/v1/auth/login',
    method: 'POST',
    payload,
  });

export const loginUser = payload => async dispatch => {
  dispatch({ type: USER_LOGIN_REQUEST });

  try {
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: await fetchLoginUser(payload),
    });
    return Promise.resolve();
  } catch (err) {
    dispatch({ type: USER_LOGIN_FAILURE });
    return Promise.reject(err);
  }
};

export const USER_AUTH_FROM_LOCAL_STORAGE = 'USER_AUTH_FROM_LOCAL_STORAGE';
export const userLoginFromLocalStorage = () => ({
  type: USER_AUTH_FROM_LOCAL_STORAGE,
});

export const USER_LOGOUT = 'USER_LOGOUT';
export const userLogout = () => async dispatch => {
  await dispatch({ type: USER_LOGOUT });
  await dispatch(() => navigate('/login'));
};

// refresh token
export const USER_TOKEN_REFRESH_REQUEST = 'USER_TOKEN_REFRESH_REQUEST';
export const USER_TOKEN_REFRESH_SUCCESS = 'USER_TOKEN_REFRESH_SUCCESS';
export const USER_TOKEN_REFRESH_FAILURE = 'USER_TOKEN_REFRESH_FAILURE';

export const fetchRefreshToken = refreshToken => async dispatch => {
  dispatch({ type: USER_TOKEN_REFRESH_REQUEST });

  try {
    const payload = await fetchApi({
      endpoint: '/api/v1/oauth/refresh/',
      method: 'POST',
      payload: {
        data: {
          type: 'refresh',
          attributes: {
            refreshToken,
          },
        },
      },
    });

    dispatch({ type: USER_TOKEN_REFRESH_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: USER_TOKEN_REFRESH_FAILURE });
    dispatch(userLogout());
  }
};

// User Info Request
export const USER_INFO_REQUEST = 'USER_INFO_REQUEST';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_FAILURE = 'USER_INFO_FAILURE';

export const getUserInfo = async dispatch => {
  dispatch({ type: USER_INFO_REQUEST });

  try {
    const payload = await fetchApi({
      endpoint: '/api/v1/auth/user/?depth=0',
    });

    const {
      id,
      createdAt,
      updatedAt,
      lastLoginAt,
      organizationId,
      title,
      role,
      firstName,
      lastName,
      email,
      measurementSystem,
    } = payload;

    dispatch({
      type: USER_INFO_SUCCESS,
      payload: {
        user: {
          id,
          createdAt,
          updatedAt,
          lastLoginAt,
          organizationId,
          title,
          role,
          firstName,
          lastName,
          email,
          measurementSystem: measurementSystem || 'METRIC',
        },
      },
    });
  } catch (err) {
    dispatch({ type: USER_INFO_FAILURE });
  }
};

export const USER_LOGIN_FROM_TOKEN = 'USER_LOGIN_FROM_TOKEN';
export const loginUserFromToken = payload => async dispatch => {
  await dispatch({ type: USER_LOGIN_FROM_TOKEN, payload });
  await dispatch(() => navigate('/'));
};

export const fetchUsers = () =>
  fetchApi({
    endpoint: '/api/v1/users/',
  });
