// @flow

import * as React from 'react';
import classNames from '../../../utils/classNames';
import styles from './TextField.module.css';
import InputField from '../InputField/InputField';

type Props = {
  isMainTitle?: boolean,
  isTitle?: boolean,
  type?: string,
  placeholder?: string,
  disabled?: boolean,
  value: string,
  label?: string,
  fullWidth?: boolean,
  handleChange?: Function,
  error?: boolean,
  onChange?: string => void,
  onFocus?: any => any,
  extraClassName?: string,
};

const TextField = ({
  isMainTitle = false,
  isTitle = false,
  type = 'text',
  label,
  fullWidth,
  value,
  handleChange,
  placeholder = '',
  disabled = false,
  error = false,
  onChange,
  onFocus,
  extraClassName = '',
}: Props) => (
  <div className={classNames(fullWidth ? styles.fullWidth : null)}>
    {label && <span className={styles.label}> {label}</span>}
    <InputField
      type={type}
      onChange={handleChange || onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      error={error}
      extraClassName={extraClassName}
      isMainTitle={isMainTitle}
      isTitle={isTitle}
    />
  </div>
);

export default TextField;
