// @flow

import * as React from 'react';
import styles from './Checkbox.module.css';
import classNames from '../../../utils/classNames';
import IconCheckboxContained from '../../icons/ui/IconCheckboxContained';
import IconCheckboxOutlined from '../../icons/ui/IconCheckboxOutlined';
import IconCheckboxUnchecked from '../../icons/ui/IconUnchecked';

type Variant = 'outlined' | 'contained' | 'indeterminate';

type Props = {
  id?: string,
  name?: string,
  children?: React.Node,
  className?: string,
  onChange: any => void,
  checked?: boolean,
  label?: string,
  size?: number,
  fullWidthLabel?: boolean,
  fullWidth?: boolean,
  variant?: Variant,
  disabled?: boolean,
};

function getCheckboxIcon(
  size,
  variant: Variant,
  checked: boolean,
  className: string
) {
  if (checked === false) {
    return <IconCheckboxUnchecked size={size} className={className} />;
  }

  if (variant === 'outlined') {
    return <IconCheckboxOutlined size={size} className={className} />;
  }

  if (variant === 'indeterminate') {
    // TODO: update indeterminate icon
    return <IconCheckboxOutlined size={size} className={className} />;
  }

  // default
  return <IconCheckboxContained size={size} className={className} />;
}

function Checkbox(props: Props) {
  const {
    id,
    name,
    children,
    className,
    label,
    checked = false,
    onChange,
    size = 16,
    fullWidthLabel = false,
    fullWidth = false,
    variant = 'contained',
    disabled = false,
  } = props;

  return (
    <label
      htmlFor={id}
      className={classNames(
        styles.labelWrapper,
        className,
        fullWidth ? styles.fullWidth : undefined,
        disabled ? styles.disabled : undefined
      )}
    >
      <input
        id={id}
        className={styles.input}
        type="checkbox"
        name={name}
        onChange={disabled === false ? onChange : null}
        disabled={disabled}
        checked={checked}
      />

      {getCheckboxIcon(size, variant, checked, styles.icon)}

      {label && (
        <span
          className={classNames(
            styles.label,
            fullWidthLabel ? styles.fullWidthLabel : ''
          )}
        >
          {label}
        </span>
      )}

      {children}
    </label>
  );
}

export default Checkbox;
