// @flow

import * as React from 'react';
import { arc, pie } from 'd3-shape';
import { sum } from 'd3-array';
import { scaleOrdinal } from 'd3-scale';
import classNames from '../../utils/classNames';
import PieSlices from './PieSlices/PieSlices';
import ChartLegend from './ChartLegend';
import styles from './Chart.module.css';

const MARGINS = { top: 0, right: 20, bottom: 0, left: 20 };

const svgDimensions = { width: 300, height: 300 };

const radius = Math.min(
  svgDimensions.width - MARGINS.left - MARGINS.right,
  svgDimensions.height - MARGINS.top - MARGINS.bottom
);

const colorScale = scaleOrdinal().range([
  '#2BDDCF',
  '#3FBCF4',
  '#626DFA',
  '#B873E4',
  '#FF9248',
  '#77EB85',
]);

type Props = {
  data: any[],
};

const PieChart = ({ data = [] }: Props) => {
  const arcGenerator = arc()
    .innerRadius(70)
    .outerRadius(radius / 2)
    .padAngle(0.01);

  const arcHover = arc()
    .innerRadius(70 - 5)
    .outerRadius(radius / 2 + 5)
    .padAngle(0.01);

  const pieGenerator = pie()
    .sort(null)
    .value(d => d.value);

  const dataSetKeys = data.map(item => item.name);

  const dataSum = sum(data.map(item => item.value));

  const percentage = value => `${Math.round((value / dataSum) * 100)}%`;

  const pieData = pieGenerator(data);

  return (
    <div className={styles.container}>
      <svg height={svgDimensions.height} width={svgDimensions.width}>
        <PieSlices
          arc={arcGenerator}
          arcHover={arcHover}
          colorScale={colorScale}
          data={pieData}
          svgDimensions={svgDimensions}
          margins={MARGINS}
          percentage={percentage}
        />
        <g
          transform={`translate(${svgDimensions.width /
            2}, ${(svgDimensions.height - MARGINS.top) / 2})`}
        >
          <text
            className={classNames([styles.circleText, styles.circleTextTop])}
          >
            Total
          </text>
          <text className={styles.totalSumText}>{dataSum}</text>
          <text
            className={classNames([styles.circleText, styles.circleTextBottom])}
          >
            kWh
          </text>
        </g>
      </svg>
      <ChartLegend items={dataSetKeys} colorScale={colorScale} />
    </div>
  );
};

export default PieChart;
