// @flow

import * as React from 'react';
import type { BatteryIcon } from '../../../types/icon';

const Battery0 = ({ size, classes = {} }: BatteryIcon) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    className={classes.icon}
    viewBox="0 0 16 16"
  >
    <path
      d="M14 5v6H1V5h13m0-1H1c-.6 0-1 .4-1 1v6c0 .6.4 1 1 1h13c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1zM15 6h1v4h-1z"
      fill="currentColor"
    />
  </svg>
);

export default Battery0;
