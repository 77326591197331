// @flow

import * as React from 'react';
import styles from './IconButton.module.css';

type Props = {
  children: React.Node,
  onClick: void => void,
  rest?: any,
};

const IconButton = ({ children, onClick, ...rest }: Props) => (
  <button className={styles.root} onClick={onClick} {...rest}>
    {children}
  </button>
);

export default IconButton;
