// @flow

export function toCelsius(fahrenheit: number): number {
  return ((fahrenheit - 32) * 5) / 9;
}

export function toFahrenheit(celsius: number): number {
  return (celsius * 9) / 5 + 32;
}

export default function convertTemp(
  value: number,
  from: 'c' | 'f',
  decimals?: number = 2
) {
  if (typeof value !== 'number') {
    return 'n/a';
  }

  if (from === 'c') {
    return +toFahrenheit(value).toFixed(decimals);
  }

  return +toCelsius(value).toFixed(decimals);
}
