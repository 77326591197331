// @flow

import * as React from 'react';
import { sentinelTypes as type } from '../../../../types/sentinel';
import ConditionTemperature from './ConditionTemperature';
import ConditionDoor from './ConditionDoor';
import styles from './StepCondition.module.css';
import type { Parameters, SentinelType } from '../../../../types/sentinel';

type Props = {
  onChange: (string, string) => void,
  sentinelType: SentinelType,
  parameters?: Parameters,
};

const getCondition = (sentinelType, parameters, onChange) => {
  switch (sentinelType) {
    case type.TEMPERATURE:
      return (
        <ConditionTemperature parameters={parameters} onChange={onChange} />
      );
    case type.DOOR:
      return <ConditionDoor parameters={parameters} onChange={onChange} />;

    default:
      return null;
  }
};

const StepCondition = ({ onChange, parameters, sentinelType }: Props) => (
  <div className={styles.stepWrapper}>
    <h5>
      Please define the condition for your configuration, as well as the
      reaction time, after which the first notification will be triggered.
    </h5>
    <div className={styles.conditionalsWrapper}>
      {getCondition(sentinelType, parameters, onChange)}
    </div>
  </div>
);

export default StepCondition;
