// @flow

import * as React from 'react';
import type { NotificationIcon } from '../../../types/icon';

const IconSize = () => (
  <g>
    <path
      d="M9 1.3v13.4l-5-4.1c-.4-.4-1-.6-1.6-.6H1V6h1.4C3 6 3.6 5.8 4 5.4l5-4.1M9.3 0c-.2 0-.3 0-.5.2L3.4 4.6c-.3.3-.7.4-1 .4H1c-.6 0-1 .4-1 1v4c0 .6.4 1 1 1h1.4c.4 0 .7.1 1 .4l5.4 4.5c.2.1.3.1.5.1.4 0 .7-.3.7-.7V.7c0-.4-.4-.7-.7-.7zM11.3 10.8c-.1 0-.2 0-.2-.1-.1-.1 0-.3.1-.3.8-.6 1.3-1.5 1.3-2.4 0-.9-.5-1.8-1.3-2.3-.1-.1-.2-.2-.1-.3.1-.1.2-.2.3-.1 1 .5 1.6 1.6 1.6 2.7s-.6 2.2-1.6 2.7c0 0 0 .1-.1.1z"
      fill="currentColor"
    />
    <path
      d="M12.8 13.2c-.1 0-.2 0-.2-.1-.1-.1 0-.3.1-.3 1.7-1 2.8-2.8 2.8-4.8s-1.1-3.8-2.8-4.8c-.1-.1-.2-.2-.1-.3.1-.1.2-.2.3-.1 1.9 1.1 3 3.1 3 5.2s-1.2 4.1-3 5.2h-.1zM4 5H3v6h1V5z"
      fill="currentColor"
    />
  </g>
);

const getIconBySize = (size: number) => {
  switch (size) {
    default:
      return { viewBox: '0 0 16 16', icon: <IconSize /> };
  }
};
// TODO THIS IS A PLACE HOLDER FOR THE REAL ICON LOGIC

export default ({ classes = {}, size = 40 }: NotificationIcon) => {
  const { viewBox, icon } = getIconBySize(size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={classes.icon}
      width={size}
      height={size}
    >
      {icon}
    </svg>
  );
};
