// @flow

import React from 'react';

import classNames from '../../../utils/classNames';
import { deriveDateValue } from '../../../utils/dates';

type Props = {
  value: string,
  isTimeFrom?: boolean,
  prefixText?: string,
  suffixText?: string,
  extraClassNames?: string[],
};

const DateChip = ({
  value = '',
  prefixText = '',
  suffixText = '',
  isTimeFrom = false,
  extraClassNames = [],
}: Props) => (
  <div className={classNames(...extraClassNames)}>
    {`${prefixText} ${deriveDateValue(value, isTimeFrom)} ${suffixText}`}
  </div>
);

export default DateChip;
