export const HOME = '/';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const PROFILE = '/profile';

export const USERS = '/users';

export const NOTIFICATIONS = '/notifications';
export const NOTIFICATIONS_CONFIGURATION = '/notifications/configuration';

export const MONITORING_LIST_EQUIPMENT = '/monitoring/equipment';
export const MONITORING_SINGLE_EQUIPMENT = `${MONITORING_LIST_EQUIPMENT}/:equipmentId/:view`;
export const MONITORING_LIST_SITES = '/monitoring/sites';
export const MONITORING_SINGLE_SITE = `${MONITORING_LIST_SITES}/:siteId`;
export const MONITORING_SINGLE_SITE_VIEWS = `${MONITORING_LIST_SITES}/:siteId/:view`;
