// @flow

import * as React from 'react';
import ListHeader from '../ListHeader';
import ListCell from '../ListCell';
import ListRow from '../ListRow';
import {
  DateChip,
  TitleChip,
  SignalChip,
  StatusChip,
  ActivityChip,
} from '../Cells';
import IconUiFactory from '../../icons/IconUiFactory';
import IconGatewayFactory from '../../icons/IconGatewayFactory';
import Tooltip from '../../Tooltip';
import BaseList from '../BaseList';
import type { Equipment } from '../../../types/equipment';
import type { Gateway } from '../../../types/gateway';
import styles from './GatewaysList.module.css';
import ListContainer from '../ListContainer';
import Button from '../../ui/Button';
import InfoChip from '../Cells/InfoChip';
import signalToValue from '../../../utils/signalToValue';
import { gatewayLocationMapping } from '../../../utils/locationMapping';
import ChevronDown from '../../icons/ui/ChevronDown';
import ChevronRight from '../../icons/ui/ChevronRight';
import SpaceBreadCrumbs from '../../breadcrumbs/SpaceBreadCrumbs';

type Props = {
  gateways: Gateway[],
  equipment?: Equipment,
  site?: Equipment,
};

type State = {
  expanded: Set<number>,
};

const extraStyles = {
  gridTemplateColumns:
    '1.25rem 3rem .14fr .12fr .11fr .18fr .16fr .15fr .05fr .05fr .05fr',
};

class GatewaysList extends React.Component<Props, State> {
  state = {
    // $FlowFixMe
    expanded: new Set(),
  };

  handleExpandClick = (id: number) => {
    const expanded = new Set(this.state.expanded);

    if (expanded.has(id)) {
      expanded.delete(id);
    } else {
      expanded.add(id);
    }

    this.setState({ expanded });
  };

  gatewayConnectionTypeInfo = (gatewayStatus: Object, gatewayInfo: Object) => {
    if (!gatewayStatus || !gatewayInfo) return '';

    const networkTitle = gatewayStatus.networkOperator ? '4G' : 'LAN';

    return (
      <InfoChip
        title={networkTitle}
        description={[
          gatewayInfo ? `IMEI: ${gatewayInfo.imei}` : 'n/a',
          gatewayInfo ? `IMSI: ${gatewayInfo.imsi}` : 'n/a',
        ]}
      />
    );
  };

  networkOperatorInfo = (gatewayStatus: Object) => {
    const networkOperator = gatewayStatus.networkOperator || 'LAN';

    return <InfoChip title="Network Operator" description={networkOperator} />;
  };

  renderLastCheckInChip = (lastCheckIn: 'string', isOffline: boolean) => (
    <Tooltip
      arrowDirection="top"
      content={
        <DateChip
          extraClassNames={[styles.tooltipTimeText]}
          value={lastCheckIn}
          isTimeFrom={false}
        />
      }
    >
      <DateChip
        extraClassNames={[
          styles.timeText,
          isOffline ? styles['icon-offline'] : '',
        ]}
        prefixText={`${isOffline ? 'Since ' : ''}`}
        value={lastCheckIn}
        isTimeFrom
      />
    </Tooltip>
  );

  render() {
    const { expanded } = this.state;

    const { gateways } = this.props;

    if (gateways.length === 0) {
      return <div>No connected Gateways found.</div>;
    }

    return (
      <BaseList>
        <ListHeader extraStyles={extraStyles}>
          <ListCell isControlCell />
          <ListCell />
          <ListCell>Name</ListCell>
          <ListCell />
          <ListCell>Status</ListCell>
          <ListCell>Located In</ListCell>
          <ListCell>Last check-in</ListCell>
          <ListCell>Signal</ListCell>
          <ListCell />
          <ListCell />
          <ListCell>Activity</ListCell>
        </ListHeader>

        {gateways.map((item: Gateway) => {
          const { gatewayStatus = {}, gatewayInfo = {} } = item;
          const itemExpanded = expanded.has(item.id);
          const gatewayOnlineStatus = item.gatewayOnlineStatus || {};
          const onlineStatus = gatewayOnlineStatus.onlineState
            ? 'online'
            : 'offline';
          const lastCheckIn = gatewayOnlineStatus.timestamp;
          let networkType = '';

          const locationMapping = gatewayLocationMapping(item);

          if (gatewayStatus) {
            networkType = gatewayStatus.networkOperator ? 'cellular' : 'LAN';
          }

          return (
            <ListContainer key={`SensorsListRow${item.id}`}>
              <ListRow
                extraClassNames={[styles[`row-${onlineStatus}`]]}
                extraStyles={extraStyles}
              >
                <ListCell isControlCell>
                  <div className={styles.flex}>
                    <Button
                      onClick={() => this.handleExpandClick(item.id)}
                      icon
                    >
                      {itemExpanded ? (
                        <div className={styles[`icon-${onlineStatus}`]}>
                          <ChevronDown size={12} />
                        </div>
                      ) : (
                        <div className={styles[`icon-${onlineStatus}`]}>
                          <ChevronRight size={12} />
                        </div>
                      )}
                    </Button>
                  </div>
                </ListCell>
                <ListCell>
                  <div className={styles.iconFlexCenter}>
                    <IconGatewayFactory id="" size={20} />
                  </div>
                </ListCell>
                <ListCell>
                  <TitleChip
                    primaryText={item.title ? item.title : 'No title defined'}
                    type
                    // secondaryText={item.description}
                  />
                </ListCell>
                <ListCell />
                <ListCell extraClassNames={[styles.showOverflow]}>
                  <StatusChip
                    text={
                      onlineStatus.charAt(0).toUpperCase() +
                      onlineStatus.slice(1)
                    }
                    status={onlineStatus}
                  />
                </ListCell>
                <ListCell>
                  {item.space ? (
                    <SpaceBreadCrumbs collection={locationMapping.path} />
                  ) : (
                    'n/a'
                  )}
                </ListCell>
                <ListCell extraClassNames={[styles.showOverflow]}>
                  {lastCheckIn
                    ? this.renderLastCheckInChip(lastCheckIn, false)
                    : 'Not checked in yet'}
                </ListCell>
                <ListCell>
                  <div className={styles.blackIcon}>
                    {networkType === 'cellular' ? (
                      <SignalChip
                        level={
                          (item.gatewayStatus &&
                            signalToValue(item.gatewayStatus.signalQuality)) ||
                          0
                        }
                        network
                      />
                    ) : (
                      <IconUiFactory id="ethernet" size={16} />
                    )}
                  </div>
                </ListCell>
                <ListCell />
                <ListCell />
                <ListCell extraClassNames={[styles.borderLeft]}>
                  <ActivityChip type="gateway" resourceId={item.id} />
                </ListCell>
              </ListRow>
              {itemExpanded && (
                <ListRow
                  extraClassNames={[styles[`row-${onlineStatus}`]]}
                  extraStyles={extraStyles}
                >
                  <ListCell isControlCell />
                  <ListCell />
                  <ListCell>
                    <div>
                      <InfoChip title="UUID" description={item.uuid} />
                      <InfoChip
                        title="Model"
                        description={item.modelId || 'n/a'}
                      />
                    </div>
                  </ListCell>
                  <ListCell />
                  <ListCell />
                  <ListCell>
                    <div className={styles.description}>
                      {' '}
                      {item.description}{' '}
                    </div>
                  </ListCell>
                  <ListCell />
                  <ListCell>
                    {gatewayStatus && this.networkOperatorInfo(gatewayStatus)}
                    {this.gatewayConnectionTypeInfo(gatewayStatus, gatewayInfo)}
                  </ListCell>
                  <ListCell />
                  <ListCell />
                  <ListCell />
                </ListRow>
              )}
            </ListContainer>
          );
        })}
      </BaseList>
    );
  }
}

export default GatewaysList;
