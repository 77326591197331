// @flow

import React from 'react';
import SignalIconFactory from '../../icons/IconSignalFactory';
import styles from './SignalChip.module.css';

type Props = {
  level: string,
  network?: boolean,
  size?: number,
  classes?: Object,
};

export default ({ level, network, size, classes = {} }: Props) => (
  <div className={styles.iconAndTextGrouping}>
    <SignalIconFactory
      classes={classes}
      size={size} // $FlowFixMe
      level={network ? `signal4G${level}` : `signal${level}`}
    />
  </div>
);
