// @flow

import * as React from 'react';
import type { Sentinel } from '../../../types/sentinel';
import BaseList from '../BaseList';
import ListHeader from '../ListHeader';
import styles from './SentinelsConfigurationList.module.css';
import classNames from '../../../utils/classNames';
import DeleteSentinelModal from '../../Modals/DeleteSentinelModal';
import { AlertChip, NotificationConfigurationChip, UiChip } from '../Cells';
import SentinelWizard from '../../sentinelWizard/SentinelWizard';
import ListRow from '../ListRow/ListRow';
import ListCell from '../ListCell';
import ListContainer from '../ListContainer';
import SentinelConfigurationDetailsChip from '../Cells/SentinelConfigurationDetailsChip';
import SystemMessage from '../../ui/SystemMessage/SystemMessage';
import MuteSentinelModal from '../../Modals/MuteSentinelModal';
import ResumeSentinelModal from '../../Modals/ResumeSentinelModal';

type Props = {
  data: Sentinel[],
  hasError?: boolean,
  onUpdateOrCreate: (?Sentinel) => Promise<void>,
  onExit: any => void,
  deleteSentinel: Sentinel => void,
  muteSentinel: ({ sentinelId: string, reason: string }) => void,
  resumeSentinel: ({ sentinelId: string }) => void,
  siteCountBySentinelIds?: {},
  dismissError: Function,
};

type State = {
  expanded: Set<string>,
  deleteModalOpen: boolean,
  sentinelPrimedForDelete?: Sentinel,
  muteModalOpen: boolean,
  sentinelToMuteOrUnmute?: Sentinel,
};

const extraStyles = {
  gridTemplateColumns: '3fr 2fr 1fr 4rem',
};

class SentinelsConfigurationList extends React.Component<Props, State> {
  state = {
    // $FlowFixMe
    expanded: new Set(),
    deleteModalOpen: false,
    sentinelPrimedForDelete: undefined,
    sentinelToMuteOrUnmute: undefined,
    muteModalOpen: false,
  };

  handleExpandRow = (sentinelId: string) => {
    const { expanded } = this.state;
    expanded.add(sentinelId);
    return this.setState({ expanded });
  };

  handleCloseRow = async (sentinelId: string) => {
    const { expanded } = this.state;
    expanded.delete(sentinelId);
    this.setState({ expanded });
  };

  handleDeleteSentinel = (sentinelPrimedForDelete: Sentinel) => {
    this.setState({
      deleteModalOpen: true,
      sentinelPrimedForDelete,
    });
  };

  handleMuteSentinel = ({
    sentinelId,
    reason,
  }: {
    sentinelId: string,
    reason: string,
  }) => {
    this.props.muteSentinel({ sentinelId, reason });
    this.setState({ muteModalOpen: false, sentinelToMuteOrUnmute: undefined });
  };

  handleResumeSentinel = (sentinelId: string) => {
    this.props.resumeSentinel({ sentinelId });
    this.setState({ muteModalOpen: false, sentinelToMuteOrUnmute: undefined });
  };

  handleStatusClick = (e: any, sentinel: Sentinel) => {
    // The row also has a click event so we must prevent that from firing as well.
    e.stopPropagation();
    this.setState({ muteModalOpen: true, sentinelToMuteOrUnmute: sentinel });
  };

  renderDetailRow = (item: Sentinel) => (
    <ListContainer key={`expandedSentinelList${item.id}`}>
      <ListRow
        autoHeight
        extraClassNames={[styles.rowExpanded]}
        extraStyles={{ gridTemplateColumns: '1fr' }}
      >
        <SentinelWizard
          sentinel={item}
          onExit={() => this.handleCloseRow(item.id)}
          onUpdateOrCreate={() =>
            this.props
              .onUpdateOrCreate()
              .then(() => this.handleCloseRow(item.id))
          }
          onDelete={() => this.handleDeleteSentinel(item)}
          extraClassNames={[styles.background]}
        />
      </ListRow>
    </ListContainer>
  );

  renderRow = (item: Sentinel) => {
    const rolledOutOnSitesCount = this.props.siteCountBySentinelIds
      ? this.props.siteCountBySentinelIds[item.id]
      : 0;

    return (
      <ListContainer key={`SentinelList${item.id}`}>
        <ListRow
          onClick={() => this.handleExpandRow(item.id)}
          size="large"
          extraStyles={{
            ...extraStyles,
            paddingTop: '0.9375rem',
            paddingBottom: '0.9375rem',
          }}
        >
          <ListCell extraClassNames={[classNames(styles.col4)]}>
            <div className={styles.contentInner}>
              <NotificationConfigurationChip
                title={item.title}
                description={item.description}
                sentinelType={item.sentinelType}
              />
            </div>
          </ListCell>
          <ListCell extraClassNames={[classNames(styles.col2)]}>
            <SentinelConfigurationDetailsChip sentinel={item} />
          </ListCell>
          <ListCell extraClassNames={[classNames(styles.col2)]}>
            <UiChip
              size="20"
              iconId="site"
              label={`${rolledOutOnSitesCount} ${
                rolledOutOnSitesCount !== 1 ? 'sites' : 'site'
              }`}
            />
          </ListCell>
          <ListCell
            onClick={e => this.handleStatusClick(e, item)}
            extraClassNames={[classNames(styles.col)]}
          >
            <AlertChip status={item.muted ? 'inactive' : 'active'} />
          </ListCell>
        </ListRow>
      </ListContainer>
    );
  };

  render() {
    const { hasError, data = [], dismissError } = this.props;
    const {
      expanded,
      deleteModalOpen,
      sentinelPrimedForDelete,
      sentinelToMuteOrUnmute,
      muteModalOpen,
    } = this.state;

    if (hasError && !data.length) {
      return (
        <SystemMessage
          title="Error"
          message="Error during API request while loading sentinels"
          type="error"
          onClick={dismissError}
        />
      );
    }

    return (
      <BaseList>
        {data.length ? (
          <ListHeader extraStyles={extraStyles}>
            <ListCell size="four">Configurations</ListCell>
            <ListCell>Details</ListCell>
            <ListCell>Rollout on sites</ListCell>
            <ListCell>Status</ListCell>
          </ListHeader>
        ) : null}

        {data.map(
          item =>
            expanded.has(item.id)
              ? this.renderDetailRow(item)
              : this.renderRow(item)
        )}

        {deleteModalOpen &&
          sentinelPrimedForDelete && (
            <DeleteSentinelModal
              sentinelPrimedForDelete={sentinelPrimedForDelete}
              onSubmit={() => {
                this.setState({ deleteModalOpen: false });
                this.props.deleteSentinel(sentinelPrimedForDelete);
              }}
              onClose={() =>
                this.setState({
                  deleteModalOpen: false,
                  sentinelPrimedForDelete: undefined,
                })
              }
            />
          )}

        {muteModalOpen &&
          sentinelToMuteOrUnmute &&
          (sentinelToMuteOrUnmute.muted ? (
            <ResumeSentinelModal
              onSubmit={this.handleResumeSentinel}
              sentinel={sentinelToMuteOrUnmute}
              onClose={() =>
                this.setState({
                  muteModalOpen: false,
                  sentinelToMuteOrUnmute: undefined,
                })
              }
            />
          ) : (
            <MuteSentinelModal
              onSubmit={this.handleMuteSentinel}
              sentinel={sentinelToMuteOrUnmute}
              onClose={() =>
                this.setState({
                  muteModalOpen: false,
                  sentinelToMuteOrUnmute: undefined,
                })
              }
            />
          ))}
      </BaseList>
    );
  }
}

export default SentinelsConfigurationList;
