// @flow

import * as React from 'react';
import classNames from '../../../utils/classNames';
import styles from './StepSchedule.module.css';

type Props = {
  onChange: ({
    from: string,
    to: string,
  }) => void,
};

const generateRandomIntOneThroughThree = () =>
  Math.floor(1 + Math.random() * (3 + 1 - 1));

class StepSchedule extends React.Component<Props> {
  componentDidMount() {
    this.props.onChange({
      from: '00:00:00',
      to: '00:00:00',
    });
  }

  render() {
    return (
      <div className={styles.schedulerContainer}>
        <div className={styles.scheduleHeaderText}>
          {' '}
          <h5>Please setup a time and schedule for your configuration</h5>{' '}
        </div>
        <div
          className={classNames([
            styles.scheduleText,
            styles[`background${generateRandomIntOneThroughThree()}`],
          ])}
        />
      </div>
    );
  }
}

export default StepSchedule;
