// @flow

import { EQUIPMENT_TYPES_SUCCESS } from '../actions/equipmentFilter';

type State = {
  equipmentGroups: number[],
  equipmentTypes: number[],
};

export default (
  state: State = {
    equipmentGroups: [],
    equipmentTypes: [],
  },
  action: { type: string, payload: any }
) => {
  switch (action.type) {
    case EQUIPMENT_TYPES_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
