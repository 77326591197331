// @flow

import * as React from 'react';
import styles from './LabelButton.module.css';
import classNames from '../../../utils/classNames';

type Props = {
  children: React.Node,
  fullWidth?: boolean,
  toUppercase?: boolean,
  extraClassNames?: Array<mixed>,
};

const LabelButton = ({
  children,
  fullWidth = false,
  toUppercase = false,
  extraClassNames = [],
}: Props) => (
  <div
    role="button"
    className={classNames(
      styles.root,
      extraClassNames.filter(f => f).length === 0
        ? styles.defaultColor
        : undefined,
      fullWidth ? styles.rootFullWidth : undefined,
      toUppercase ? styles.uppercase : undefined,
      ...extraClassNames
    )}
  >
    {children}
  </div>
);

export default LabelButton;
