// @flow

import React from 'react';

export default function getHighlightedText(
  text: string,
  highlight: string,
  className: string
) {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return parts.map(
    (part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        // eslint-disable-next-line react/no-array-index-key
        <span key={`${part}${index}`} className={className}>
          {part}
        </span>
      ) : (
        part
      )
  );
}
