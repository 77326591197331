// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Router } from '@reach/router';

import * as routes from '../routes';
import DefaultLayout from './layout/DefaultLayout';
import ViewLogin from '../components/views/ViewLogin';

function App() {
  return (
    <Router>
      <ViewLogin path={routes.LOGIN} />
      <DefaultLayout path="/*" />
    </Router>
  );
}

export default connect(
  null,
  null
)(App);
