// @flow

import * as React from 'react';
import styles from './FilterButton.module.css';
import classNames from '../../../utils/classNames';

type Props = {
  children: React.Node,
  onClick: Function,
  open?: boolean,
  active?: boolean,
};

const FilterButton = ({
  children,
  active = false,
  open = false,
  onClick,
}: Props) => (
  <button
    onClick={onClick}
    className={classNames(
      styles.root,
      active ? styles.active : undefined,
      open ? styles.open : undefined
    )}
  >
    {children}
  </button>
);
export default FilterButton;
