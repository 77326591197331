import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  didUpdate: state.app.serviceWorkerDidUpdate,
});

const ServiceWorkerDidUpdate = ({ didUpdate = false }) =>
  didUpdate ? (
    <button onClick={() => window.location.reload()}>
      We updated the application, please refresh the page.
    </button>
  ) : null;

ServiceWorkerDidUpdate.propTypes = {
  didUpdate: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ServiceWorkerDidUpdate);
