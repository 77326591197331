// @flow

import * as React from 'react';

type Props = {
  classes?: any,
  height?: number,
  width?: number,
};

export default ({ classes = {}, height = 40, width = 40 }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    className={classes.icon}
    width={height}
    height={width}
  >
    <g style={{ opacity: '0.1' }}>
      <path
        style={{ fill: 'currentColor' }}
        d="M21.31,6.7368l-9.73,14.27a1.8488,1.8488,0,0,0,1.049,2.8273l5.2875,1.4168a1,1,0,0,1,.7253,1.1437l-1.6747,9.27a1.8488,1.8488,0,0,0,1.3409,2.1145l.0241.0065a1.8488,1.8488,0,0,0,1.977-.7029L31.1544,22.0763a1.8488,1.8488,0,0,0-1.02-2.8687L23.9486,17.55a1,1,0,0,1-.7266-1.1362l1.4375-8.3208a1.8488,1.8488,0,0,0-1.3433-2.1005h0A1.8488,1.8488,0,0,0,21.31,6.7368Z"
      />
    </g>
    <g style={{ opacity: '0.1' }}>
      <path
        style={{ fill: 'currentColor' }}
        d="M31.6766,10.1139l-2.0049,2.94a.381.381,0,0,0,.2162.5826l1.09.2919a.2061.2061,0,0,1,.1494.2357l-.3451,1.91a.381.381,0,0,0,.2763.4357l.005.0013a.381.381,0,0,0,.4074-.1448l2.2347-3.0922a.381.381,0,0,0-.21-.5911L32.22,12.342a.2061.2061,0,0,1-.15-.2341l.2962-1.7145a.381.381,0,0,0-.2768-.4328h0A.381.381,0,0,0,31.6766,10.1139Z"
      />
    </g>
    <g style={{ opacity: '0.1' }}>
      <path
        style={{ fill: 'currentColor' }}
        d="M11.8472,28.0944l-2.0049,2.94a.381.381,0,0,0,.2162.5826l1.09.2919a.2061.2061,0,0,1,.1494.2357l-.3451,1.91a.381.381,0,0,0,.2763.4357l.005.0013a.381.381,0,0,0,.4074-.1448l2.2347-3.0922a.381.381,0,0,0-.21-.5911l-1.2746-.3415a.2061.2061,0,0,1-.15-.2341l.2962-1.7145a.381.381,0,0,0-.2768-.4328h0A.381.381,0,0,0,11.8472,28.0944Z"
      />
    </g>
    <g>
      <path
        style={{ fill: 'currentColor' }}
        d="M28.5386,17.3183l-6.1858-1.6575a1,1,0,0,1-.7266-1.1362l1.4375-8.3208a1.8488,1.8488,0,0,0-3.3494-1.3563l-9.73,14.27a1.8488,1.8488,0,0,0,1.0491,2.8274l5.2875,1.4167a1,1,0,0,1,.7252,1.1437l-1.6746,9.27A1.8487,1.8487,0,0,0,16.7122,35.89l.024.0065a1.848,1.848,0,0,0,1.9769-.7029L29.5586,20.1871A1.8488,1.8488,0,0,0,28.5386,17.3183ZM17.448,33.53l1.5662-8.6686A3.0177,3.0177,0,0,0,16.8384,21.43l-5.0818-1.3616L20.9742,6.55l-1.3187,7.634a2.9853,2.9853,0,0,0,2.18,3.4086l5.9739,1.6007Z"
      />
      <path
        style={{ fill: 'currentColor' }}
        d="M31.8209,10.92l-1.2745-.3416a.206.206,0,0,1-.15-.2341l.2963-1.7145a.381.381,0,0,0-.69-.2795l-2.005,2.94a.381.381,0,0,0,.2162.5826l1.0895.2919a.206.206,0,0,1,.15.2357l-.3452,1.91a.381.381,0,0,0,.2764.4357l.0049.0013a.381.381,0,0,0,.4075-.1448l2.2347-3.0922A.381.381,0,0,0,31.8209,10.92Z"
      />
      <path
        style={{ fill: 'currentColor' }}
        d="M11.9916,28.9l-1.2745-.3415a.206.206,0,0,1-.15-.2341l.2963-1.7145a.381.381,0,0,0-.69-.2795l-2.005,2.94a.381.381,0,0,0,.2162.5826l1.0895.2919a.2061.2061,0,0,1,.15.2357l-.3452,1.91a.381.381,0,0,0,.2764.4357l.0049.0013a.381.381,0,0,0,.4075-.1448l2.2347-3.0922A.381.381,0,0,0,11.9916,28.9Z"
      />
    </g>
  </svg>
);
