// @flow

import React, { Component } from 'react';

import styles from './ViewEquipmentSentinels.module.css';

import SentinelsConfigurationList from '../../Tables/SentinelsConfigurationList/SentinelsConfigurationList';
import Button from '../../ui/Button';
import type { Equipment } from '../../../types/equipment';
import type { Sentinel } from '../../../types/sentinel';
import {
  resumeSentinel,
  deleteSentinel,
  muteSentinel,
  fetchSentinels,
} from '../../../actions/sentinels';
import { fetchSiteByResourceId } from '../../../actions/sites';
import { fetchUnseenNotificationCountByResourceId } from '../../../actions/notifications';

type State = {
  sentinels: Sentinel[],
  hasError: boolean,
  showExitModal: boolean,
  siteCountBySentinelIds: Object,
  notificationCount: number | string,
};

type Props = {
  equipment: Equipment,
};

class ViewEquipmentNotifications extends Component<Props, State> {
  state = {
    sentinels: [],
    hasError: false,
    showExitModal: false,
    siteCountBySentinelIds: {},
    notificationCount: '...',
  };

  componentDidMount = () => {
    this.getCount();
    // this.intervalFunction = setInterval(this.getCount, 15000);
    this.handleFetchSentinels();
  };

  componentWillUnmount() {
    // window.clearInterval(this.intervalFunction);
  }

  onMuteSentinel = ({
    sentinelId,
    reason,
  }: {
    sentinelId: string,
    reason: string,
  }) => {
    muteSentinel(sentinelId, reason)
      .then(() => {
        const { sentinels } = this.state;
        const updatedSentinel =
          sentinels.find(item => item.id === sentinelId) || {};

        updatedSentinel.muted = true;
        updatedSentinel.reason = reason;

        this.setState({
          sentinels: [...sentinels],
        });
      })
      .catch(err => {
        // eslint-disable-next-line
        console.log(err);
      });
  };

  onResumeSentinel = ({ sentinelId }: { sentinelId: string }) => {
    resumeSentinel(sentinelId)
      .then(() => {
        const { sentinels } = this.state;
        const updatedSentinel =
          sentinels.find(item => item.id === sentinelId) || {};

        updatedSentinel.muted = false;
        updatedSentinel.reason = '';

        this.setState({
          sentinels: [...sentinels],
        });
      })
      .catch(err => {
        // eslint-disable-next-line
        console.log(err);
      });
  };

  onSentinelCreateOrUpdate = () => this.handleFetchSentinels();

  onExitSubmit = () => {
    this.setState({ showExitModal: !this.state.showExitModal });
    // navigate(Routes.NOTIFICATIONS_CONFIGURATION);
  };

  onDeleteSentinel = (sentinelPrimedForDelete: Sentinel) => {
    deleteSentinel(sentinelPrimedForDelete.id)
      .then(() => {
        const { sentinels } = this.state;
        const removeIndex = sentinels.findIndex(
          item => item.id === sentinelPrimedForDelete.id
        );

        this.setState({
          sentinels: [
            ...sentinels.slice(0, removeIndex),
            ...sentinels.slice(removeIndex + 1),
          ],
        });
      })
      .catch(() => {
        // TODO error logic here
      });
  };

  getCount = () =>
    fetchUnseenNotificationCountByResourceId(
      this.props.equipment.id,
      'equipment'
    )
      .then(res => {
        this.setState({ notificationCount: res.count });
      })
      .catch(() => {
        // console.log(err);
      });

  // intervalFunction: any;

  handleErrorClick = () => this.setState(() => ({ hasError: false }));

  handleFetchSentinels = () =>
    fetchSentinels({ equipmentIds: [this.props.equipment.id] })
      .then((sentinels = []) => {
        this.setState({ sentinels, hasError: false });
        return sentinels;
      })
      .then(this.handleGetSitesByResource)
      .catch(() => this.setState({ hasError: true }));

  handleGetSitesByResource = (sentinels: Object[] = []) => {
    const resourceIds = [].concat(
      ...sentinels.map(sentinel =>
        sentinel.targets.map(target => target.targetId)
      )
    );

    return Promise.all(resourceIds.map(id => fetchSiteByResourceId(id))).then(
      sites => {
        const resourcesToSite = resourceIds.reduce((acc, cur, index) => {
          acc[cur] = sites[index];
          return acc;
        }, {});

        const siteCountBySentinelIds = sentinels.reduce((acc, sentinel) => {
          let items = sentinel.targets
            .map(target => resourcesToSite[target.targetId])
            .filter(site => site !== undefined)
            .map(site => site.id);
          // Make it an array of unique items;
          items = [...new Set(items)];

          acc[sentinel.id] = items.length;

          return acc;
        }, {});

        this.setState({
          siteCountBySentinelIds,
        });
      }
    );
  };

  render() {
    const {
      sentinels = [],
      hasError,
      siteCountBySentinelIds,
      notificationCount,
    } = this.state;
    return sentinels.length > 0 ? (
      <div>
        <div className={styles.notificationCountButtonContainer}>
          <Button primary onClick={() => {}}>
            {notificationCount} Unseen Notifications
          </Button>
        </div>
        <div>
          <SentinelsConfigurationList
            dismissError={this.handleErrorClick}
            data={sentinels}
            hasError={hasError}
            onExit={() => {}}
            deleteSentinel={this.onDeleteSentinel}
            onUpdateOrCreate={this.onSentinelCreateOrUpdate}
            siteCountBySentinelIds={siteCountBySentinelIds}
            muteSentinel={this.onMuteSentinel}
            resumeSentinel={this.onResumeSentinel}
          />
        </div>
      </div>
    ) : (
      <div className={styles.messageContainer}>
        <h5>No configurations found for this equipment!</h5>
      </div>
    );
  }
}

export default ViewEquipmentNotifications;
