// @flow

import * as React from 'react';
import styles from './InputRange.module.css';
import { comparisonTypes } from '../../../types/sentinel';

type Props = {
  width: string,
  value: number,
  max: number,
  min: number,
  onChange: Function,
  name?: string,
  condition?: string,
  valueFormatter?: number => number,
};

function calculatePosition(
  min: number,
  max: number,
  value: number,
  comparisonType: string
): number {
  const difference = Math.abs(min - max);
  const numberPositionMin = Math.abs(min - value);
  const numberPositionMax = Math.abs(value - max);

  if (comparisonType === 'lt') {
    return (100 * (difference - numberPositionMax)) / difference;
  }

  return (100 * (difference - numberPositionMin)) / difference;
}

const InputRange = ({
  max = 100,
  min = -100,
  value = 0,
  onChange,
  name = '',
  width = '100%',
  condition = comparisonTypes.eq,
  valueFormatter,
}: Props) => {
  const renderSlideOverlay = () => {
    if (condition === comparisonTypes.lt) {
      return (
        <div
          className={styles.slideOverlay}
          style={{
            width: `calc(${calculatePosition(min, max, value, 'lt')}% + 12px)`,
          }}
        />
      );
    }

    return (
      <div
        className={styles.slideOverlay}
        style={{
          width: `calc(${calculatePosition(min, max, value, 'gt')}% + 6px)`,
          right: '-6px',
        }}
      />
    );
  };

  const targetName = name !== '' ? name : 'value';

  const formattedValue = valueFormatter ? valueFormatter(value) : value;

  return (
    <div
      className={styles.slideWrapper}
      style={{ width: `calc(${width} - 12px)` }}
    >
      <span className={styles.valueText}>{formattedValue}</span>
      <div className={styles.slideBar} />
      {condition !== comparisonTypes.eq ? renderSlideOverlay() : null}
      <input
        className={styles.rangeSlider}
        onChange={(ev: any) => onChange(targetName, ev.target.value)}
        type="range"
        value={value}
        min={min}
        max={max}
        style={{ width: `calc(${width} + 12px)` }}
      />
    </div>
  );
};

export default InputRange;
