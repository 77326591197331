// @flow

import React from 'react';
import BatteryIconFactory from '../../icons/IconBatteryFactory';
import styles from './BatteryChip.module.css';

type Props = {
  level: 0 | 25 | 50 | 75 | 100,
  size?: number,
  classes?: any,
};

export default ({ level, size, classes = {} }: Props) => (
  <div className={styles.iconAndTextGrouping}>
    <BatteryIconFactory classes={classes} size={size} level={level} />
  </div>
);
