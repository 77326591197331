// @flow

import fetchApi from '../middleware/api';

export const EQUIPMENT_TYPES_REQUEST = 'EQUIPMENT_TYPES_REQUEST';
export const EQUIPMENT_TYPES_SUCCESS = 'EQUIPMENT_TYPES_SUCCESS';
export const EQUIPMENT_TYPES_FAILURE = 'EQUIPMENT_TYPES_FAILURE';

export const fetchEquipmentTypes = async (dispatch: Function) => {
  dispatch({ type: EQUIPMENT_TYPES_REQUEST });
  try {
    const [equipmentTypes, equipmentGroups] = await Promise.all([
      fetchApi({ endpoint: '/api/v1/equipment-types/' }),
      fetchApi({ endpoint: '/api/v1/equipment-groups/' }),
    ]);

    dispatch({
      type: EQUIPMENT_TYPES_SUCCESS,
      payload: { equipmentGroups, equipmentTypes },
    });
  } catch (err) {
    dispatch({ type: EQUIPMENT_TYPES_FAILURE });
  }
};

export const SET_EQUIPMENT_STATUS_LIST_FILTER =
  'SET_EQUIPMENT_STATUS_LIST_FILTER';
export const setEquipmentStatusListFilter = (filterType: string) => ({
  type: SET_EQUIPMENT_STATUS_LIST_FILTER,
  payload: filterType,
});

export const SET_EQUIPMENT_TYPE_LIST_FILTER = 'SET_EQUIPMENT_TYPE_LIST_FILTER';
export const setEquipmentTypeListFilter = (equipmentTypeIds: number[]) => ({
  type: SET_EQUIPMENT_TYPE_LIST_FILTER,
  payload: equipmentTypeIds,
});

export const SET_EQUIPMENT_SPACE_LIST_FILTER =
  'SET_EQUIPMENT_SPACE_LIST_FILTER';
export const setEquipmentSpaceListFilter = (filterType: string) => ({
  type: SET_EQUIPMENT_SPACE_LIST_FILTER,
  payload: filterType,
});

// export const SET_EQUIPMENT_TITLE_FILTER =
//   'SET_EQUIPMENT_TITLE_FILTER';
// export const setEquipmentTitleFilter = (searchString: string) => ({
//   type: SET_EQUIPMENT_TITLE_FILTER,
//   payload: searchString,
// });
