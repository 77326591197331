// @flow
import * as React from 'react';
import { Battery0, Battery1, Battery2, Battery3, Battery4 } from './battery';

type Props = {
  level: 0 | 25 | 50 | 75 | 100,
  classes?: any,
  size?: number,
};

export default ({ classes, size = 20, level }: Props) => {
  switch (level) {
    case 0:
      return <Battery0 classes={classes} size={size} />;
    case 25:
      return <Battery1 classes={classes} size={size} />;
    case 50:
      return <Battery2 classes={classes} size={size} />;
    case 75:
      return <Battery3 classes={classes} size={size} />;
    case 100:
      return <Battery4 classes={classes} size={size} />;
    default:
      return null;
  }
};
