/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
// @flow

import * as React from 'react';
import styles from './ListContainer.module.css';
import classNames from '../../../utils/classNames';

type Props = {
  children: React.Node,
  disabled?: boolean,
};

const ListContainer = ({ children, disabled = false }: Props) => (
  <div
    className={classNames(
      styles.container,
      disabled ? styles.disabled : undefined
    )}
  >
    {children}
  </div>
);

export default ListContainer;
