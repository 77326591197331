// @flow

import * as React from 'react';

type Props = {
  size: number,
};

export default ({ size, ...rest }: Props) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    {...rest}
  >
    <g>
      <path
        d="M8 1c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.1-7 7-7m0-1C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z"
        fill="currentColor"
      />
      <path
        d="M8 4c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4 1.8-4 4-4"
        fill="currentColor"
      />
    </g>
  </svg>
);
