// @flow

import * as React from 'react';
import styles from './ListHeader.module.css';
import classNames from '../../../utils/classNames';

type Props = {
  children: React.Node,
  extraClassNames?: string[],
  extraStyles: any,
};

const ListHeader = ({
  children,
  extraClassNames = [],
  extraStyles = {},
}: Props) => (
  <div
    className={classNames(styles.listHeader, ...extraClassNames)}
    style={extraStyles}
  >
    {children}
  </div>
);

export default ListHeader;
