// @flow

import type { Gateway } from '../types/gateway';
import { siteStatus } from '../types/site';

export function determineSiteOnlineStatus(gateways: Gateway[] = []) {
  return gateways.reduce(
    (acc, cur) => {
      const gatewayOnlineStatus = cur.gatewayOnlineStatus || {};
      const { onlineState, timestamp } = gatewayOnlineStatus;
      // the reducer determines which date in offline hubs is the most recent
      // and counts the number of offline gateways.
      // it also flips the status to partiallyOffline if a gateway is found to be online.
      // not installed = '' | all sensors offline = siteStatus.off | all sensors online = siteStatus.on | Not all sensors online = siteStatus.partiallyOff
      if (!onlineState) {
        acc.mostRecentOnline =
          (!acc.mostRecentOnline && timestamp) ||
          (timestamp && new Date(acc.mostRecentOnline) < new Date(timestamp))
            ? timestamp
            : acc.mostRecentOnline;
        acc.offlineGatewaysCount += 1;
        acc.status =
          acc.status === '' || acc.status === siteStatus.off
            ? siteStatus.off
            : siteStatus.partiallyOff;
      } else {
        acc.status =
          acc.status === '' || acc.status === siteStatus.on
            ? siteStatus.on
            : siteStatus.partiallyOff;
      }

      return acc;
    },
    {
      status: '',
      mostRecentOnline: '',
      offlineGatewaysCount: 0,
    }
  );
}
