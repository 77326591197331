// @flow

import type { Equipment } from '../types/equipment';
import type { Sensor } from '../types/sensors';
import type { Gateway } from '../types/gateway';

/* eslint-disable */

export const equipmentLocationMapping = (e: Equipment) => {
  const returnObject = {
    path: [],
    site: {},
  };

  const recursiveCollector = (object: any) => {
    if (object.space) {
      returnObject.path.unshift(object.space);
      recursiveCollector(object.space);
    } else if (object.parent && object.parent._entity !== 'Site') {
      returnObject.path.unshift(object.parent);
      recursiveCollector(object.parent);
    } else if (object.parent && object.parent._entity === 'Site') {
      returnObject.site = object.parent;
    }
  };

  recursiveCollector(e);
  return returnObject;
};

export const gatewayLocationMapping = (e: Gateway) => {
  const returnObject = {
    path: [],
    site: {},
  };

  const recursiveCollector = (object: any) => {
    if (object.space) {
      returnObject.path.unshift(object.space);
      recursiveCollector(object.space);
    } else if (object.parent && object.parent._entity !== 'Site') {
      returnObject.path.unshift(object.parent);
      recursiveCollector(object.parent);
    } else if (object.parent && object.parent._entity === 'Site') {
      returnObject.site = object.parent;
    }
  };

  recursiveCollector(e);
  return returnObject;
};

export const sensorLocationMapping = (e: Sensor) => {
  const returnObject = {
    path: [],
    site: {},
  };

  const recursiveCollector = (object: any) => {
    if (object.resource) {
      object.resource._entity === 'Space'
        ? returnObject.path.unshift(object.resource)
        : undefined;
      recursiveCollector(object.resource);
    } else if (object.space) {
      returnObject.path.unshift(object.space);
      recursiveCollector(object.space);
    } else if (object.parent && object.parent._entity !== 'Site') {
      returnObject.path.unshift(object.parent);
      recursiveCollector(object.parent);
    } else if (object.parent && object.parent._entity === 'Site') {
      returnObject.site = object.parent;
    }
  };
  recursiveCollector(e);
  return returnObject;
};
