// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from '../../utils/classNames';

type Props = {
  classes: any,
  children: React.Node,
  flex: boolean,
};

const Card = ({ children, classes, flex }: Props) => (
  <div className={classNames([classes.root, flex ? classes.flex : undefined])}>
    {children}
  </div>
);

const styles = () => ({
  root: {
    boxShadow: '0 0 1.25rem 0 #F2F2F2',
    padding: '1.875rem',
    display: 'flex',
    flexDirection: 'column',
  },
});

export default withStyles(styles)(Card);
