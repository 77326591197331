// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';

type Props = {
  children: React.Node,
  classes: any,
};

const LineSeparator = ({ children, classes }: Props) => (
  <div className={classes.root}>{children}</div>
);

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '2rem',
    marginBottom: '2rem',
    fontWeight: 'bold',

    '&:before, &:after': {
      content: '""',
      flex: '1 1',
      borderBottom: `1px solid ${theme.palette.ambient.basePlus75}`,
      margin: 'auto',
    },
    '&:before': {
      marginRight: '1rem',
    },
    '&:after': {
      marginLeft: '1rem',
    },
  },
});

export default withStyles(styles)(LineSeparator);
