// @flow

import fetchApi from '../middleware/api';

export const fetchGateways = () =>
  fetchApi({
    endpoint: `/api/v1/gateways/`,
  });

export const fetchGatewaysByResource = (
  resourceId: number,
  recursive?: boolean = false
) =>
  fetchApi({
    endpoint: `/api/v1/gateways/resource/${resourceId.toString()}?recursive=${recursive.toString()}`,
  });
