import { connect } from 'react-redux';
import {
  setPageIcon,
  setPageTitle,
  setPageType,
  setPageSubtitle,
} from '../../actions/pageInfo';

const withPageUtils = WrappedComponent =>
  connect(
    null,
    { setPageTitle, setPageIcon, setPageType, setPageSubtitle }
  )(WrappedComponent);

export default withPageUtils;
