// @flow

import * as React from 'react';
import type { NotificationIcon } from '../../../types/icon';

const IconSize = () => (
  <g>
    <path
      d="M0 10h16v1H0zM0 5h1v2H0zM3 3h1v4H3zM6 5h1v2H6zM9 5h1v2H9zM12 3h1v4h-1zM15 5h1v2h-1z"
      fill="currentColor"
    />
    <path
      d="M2.5 13h2c.3 0 .5-.3.5-.6V8.6c0-.6-.7-.8-.9-.3l-2 3.8c-.2.4 0 .9.4.9zM13.5 13h-2c-.3 0-.5-.3-.5-.6V8.6c0-.6.7-.8.9-.3l2 3.8c.2.4 0 .9-.4.9z"
      fill="currentColor"
    />
  </g>
);

const getIconBySize = (size: number) => {
  switch (size) {
    default:
      return { viewBox: '0 0 16 16', icon: <IconSize /> };
  }
};
// TODO THIS IS A PLACE HOLDER FOR THE REAL ICON LOGIC

export default ({ classes = {}, size = 40 }: NotificationIcon) => {
  const { viewBox, icon } = getIconBySize(size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={classes.icon}
      width={size}
      height={size}
    >
      {icon}
    </svg>
  );
};
