// @flow

function rangeBetween(value: number, min: number, max: number): boolean {
  return value >= min && value < max;
}

export default function(signal: number) {
  if (typeof signal !== 'number' || isNaN(signal)) return undefined; // eslint-disable-line no-restricted-globals

  if (signal >= -32) {
    return 5;
  } else if (rangeBetween(signal, -44, -32)) {
    return 4;
  } else if (rangeBetween(signal, -56, -44)) {
    return 3;
  } else if (rangeBetween(signal, -68, -56)) {
    return 2;
  } else if (rangeBetween(signal, -80, -68)) {
    return 1;
  }

  return 0;
}
