// @flow

import * as React from 'react';

type Props = {
  size: number,
};

export default ({ size, ...rest }: Props) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    {...rest}
  >
    <path
      d="M14.8 3.8l-6.3 7.4c-.1.1-.2.2-.4.2-.1 0-.3-.1-.3-.1l-3.9-4c-.2-.2-.2-.5 0-.7.2-.2.5-.2.7 0l3.6 3.5L14.3 3C11.5-.4 6.5-1 3 1.7S-1 9.5 1.7 13s7.8 4 11.2 1.3c3.2-2.5 4-7 1.9-10.5z"
      fill="currentColor"
    />
  </svg>
);
