// @flow

import * as React from 'react';
import styles from './ChartLegend.module.css';

type Props = {
  items: any[],
  colorScale: string => string,
};

const Icon = ({ color }: { color: string }) => (
  <svg className={styles.icon} width="10" height="10">
    <circle cx="5" cy="5" r="5" fill={color} />
  </svg>
);

const ChartLegend = ({ items = [], colorScale }: Props) => (
  <div className={styles.root}>
    {items.map(item => (
      <div className={styles.item} key={item}>
        <Icon color={colorScale(item)} />
        {item}
      </div>
    ))}
  </div>
);

export default ChartLegend;
