export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export function setPageTitle(title) {
  return {
    type: SET_PAGE_TITLE,
    payload: title,
  };
}

export const SET_PAGE_SUBTITLE = 'SET_PAGE_SUBTITLE';
export function setPageSubtitle(pageSubtitle) {
  return {
    type: SET_PAGE_SUBTITLE,
    payload: pageSubtitle,
  };
}

export const SET_PAGE_ICON = 'SET_PAGE_ICON';
export function setPageIcon(icon) {
  return {
    type: SET_PAGE_ICON,
    payload: icon,
  };
}

export const SET_PAGE_TYPE = 'SET_PAGE_TYPE';
export function setPageType(pageType) {
  return {
    type: SET_PAGE_TYPE,
    payload: pageType,
  };
}
