// @flow

import * as React from 'react';
import { Router } from '@reach/router';
import ViewSiteIndex from './sites/ViewSiteIndex';
import ViewMonitoringSitesList from './sites/ViewSitesList';

const ViewSites = () => (
  <Router>
    <ViewMonitoringSitesList path="/" />
    <ViewSiteIndex path=":siteId/*" />
  </Router>
);

export default ViewSites;
