// @flow

import type { Equipment } from './equipment';

export const sensorState = {
  ACTIVE: 'Online',
  INACTIVE: 'Offline',
};

export type SensorState = $Keys<typeof sensorState>;

type SensorStatus = {
  _entity: 'SensorStatus',
  id: number,
  batteryLevel?: any,
  batteryVoltage?: number,
  interval: number,
  signalStrength: number,
  state: SensorState,
  temperature?: number,
  createdAt?: string,
  updatedAt: string,
};

type SensorInfo = {
  _entity: 'SensorInfo',
  id: number,
  _path?: any,
  firmwareVersion: string,
  hardwareVersion: string,
  location: string,
  signalStrength: number,
  createdAt: string,
  updatedAt: string,
};

export const sensorType = {
  TEMPERATURE: 'TEMPERATURE',
  HUMIDITY: 'HUMIDITY',
  BINARY: 'BINARY',
};

export type SensorType = $Keys<typeof sensorType>;

export const sensorTarget = {
  SPACE: 'SPACE',
  EQUIPMENT: 'EQUIPMENT',
};

export type SensorTarget = $Keys<typeof sensorTarget>;

export type Sensor = {
  id: number,
  title: string,
  description: string,

  modelId?: number,
  resourceId: number,
  uuid: string,
  vendor?: string,
  productId?: number,

  sensorStatus?: SensorStatus,
  sensorInfo?: SensorInfo,

  firmwareVersion: string,
  hardwareVersion: string,

  resource?: Equipment,

  location: string,

  createdAt: string,
  updatedAt?: string,

  types: SensorType[],
  status?: any,
  gateway?: any,
};

export type OperationalSample = {
  temperature: number,
  state: [boolean],
  humidity: number,
};

export const getOperationalValue = (
  type: string,
  sample: OperationalSample
) => {
  switch (type) {
    case sensorType.HUMIDITY:
      return sample[sensorType.HUMIDITY.toLowerCase()];
    case sensorType.TEMPERATURE:
      return sample[sensorType.TEMPERATURE.toLowerCase()];
    case sensorType.BINARY:
      return sample.state && sample.state[0] ? 'Open' : 'Closed';
    default:
      throw new Error('unknown type');
  }
};

/*
"sensorInfo": {
  "_entity": "string",
    "_path": {},
    "createdAt": "2018-08-01T10:17:38.588Z",
    "description": "string",
    "firmwareVersion": "string",
    "hardwareVersion": "string",
    "id": 0,
    "location": "string",
    "signalStrength": 0,
    "title": "string",
    "updatedAt": "2018-08-01T10:17:38.588Z"
},
"sensorStatus": {
  "_entity": "string",
    "batteryLevel": 0,
    "batteryVoltage": 0,
    "createdAt": "2018-08-01T10:17:38.588Z",
    "id": 0,
    "interval": 0,
    "signalStrength": 0,
    "state": "INACTIVE",
    "temperature": 0,
    "updatedAt": "2018-08-01T10:17:38.588Z"
},
*/

/*
const s = [
  {
    _entity: 'Sensor',
    id: 2418,
    createdAt: '2018-07-26T08:48:16.862+0000',
    updatedAt: null,
    _path: null,
    title: 'a temperature sensor',
    description: 'test sensor',
    modelId: 2417,
    resourceId: 3803,
    uuid: '01:00:00:00:00:0b',
    vendor: 'energybox',
    productId: null,
    signalStrength: null,
    firmwareVersion: '1',
    hardwareVersion: '1',
    location: 'string',
    model: {
      _entity: 'SensorModel',
      id: 2417,
      createdAt: '2018-07-26T08:43:37.975+0000',
      updatedAt: null,
      alias: 'test model',
      title: 'model t',
      description: 'test sensor model',
    },
    resource: {
      _entity: 'Equipment',
      id: 3803,
      createdAt: '2018-07-26T07:54:21.674+0000',
      updatedAt: null,
      _path: null,
      title: 'HVAC',
      description: 'HVAC',
      typeId: 3102,
      spaceId: 3800,
      type: null,
      space: null,
    },
    types: ['TEMPERATURE'],
    status: null,
    gateway: null,
  },
];
*/
