// @flow

import React from 'react';
import styles from './SourceChip.module.css';
import IconEquipmentFactory from '../../icons/IconEquipmentFactory';
import PathBreadCrumbs from '../../breadcrumbs/PathBreadCrumbs';

type Props = {
  equipmentTypeId: string,
  equipmentName: string,
  spaces: Object[],
  siteName: string,
  extraContent: any,
};

export default ({
  equipmentTypeId,
  equipmentName,
  spaces,
  extraContent,
}: Props) => (
  <div className={styles.iconAndTextGrouping}>
    <IconEquipmentFactory size={25} id={equipmentTypeId} />
    <div className={styles.textGrouping}>
      <span className={styles.titleText}> {equipmentName} </span>
      <PathBreadCrumbs collection={spaces} />
    </div>
    {extraContent}
  </div>
);
