// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '../../ui/Checkbox';
import Icon from '../../Icon';
import classNames from '../../../utils/classNames';
import IconEquipmentFactory from '../../icons/IconEquipmentFactory';

type Props = {
  classes: any,
  activeItems: number,
  maxItems: number,
  onClick: Function,
  title: string,
  children: React.Node,
  alias: string,
};

type State = {
  isActive: boolean,
};

class EquipmentMenuItem extends React.Component<Props, State> {
  static defaultProps = {
    checked: false,
    onClick: () => {},
  };

  state = {
    isActive: false,
  };

  onDropDownClick = () => {
    this.setState(state => ({
      isActive: !state.isActive,
    }));
  };

  render() {
    const { isActive } = this.state;
    const {
      classes,
      activeItems,
      maxItems,
      onClick,
      title,
      alias,
    } = this.props;

    return (
      <li className={classes.listStyleNone}>
        <div
          className={classNames([
            classes.listItem,
            isActive ? classes.listItemActive : undefined,
          ])}
        >
          <Checkbox
            checked={activeItems > 0}
            variant={maxItems > activeItems ? 'outlined' : 'contained'}
            className={classes.checkbox}
            size={16}
            onChange={onClick}
          >
            <div className={classes.listIcon}>
              <IconEquipmentFactory id={alias} size={40} />
            </div>
            <div className={classes.listTitle}>{title}</div>
          </Checkbox>
          <div className={classes.dropDownIcon}>
            <Icon onClick={this.onDropDownClick} size={20} icon="arrow" />
          </div>
        </div>
        {isActive ? (
          <ul className={classes.childList}>{this.props.children}</ul>
        ) : null}
      </li>
    );
  }
}

const styles = theme => ({
  checkbox: {
    alignItems: 'center',
    display: 'flex',
    height: '60px',
    flexGrow: 1,
    '& > svg': {
      borderBottom: '1px solid #e1e2e4',
    },
    '& > *': {
      height: '100%',
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    listStyle: 'none',
  },
  listItemActive: {
    '& $checkbox > svg, $listIcon': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    '& $dropDownIcon > svg': {
      transform: 'rotate(180deg)',
    },
  },
  listIcon: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #e1e2e4',
    paddingLeft: '18px',
  },
  listTitle: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #e1e2e4',
    flexGrow: 1,
    paddingLeft: '18px',
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.typography.body.color,
    fontWeight: 'bold',
  },
  dropDownIcon: {
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #e1e2e4',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  childList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 0,
    padding: '20px 0 10px 0',
    borderBottom: '1px solid #e1e2e4',
    listStyle: 'none',
  },
  childListItem: {
    flexBasis: '50%',
    paddingBottom: '10px',
  },
  listStyleNone: {
    listStyle: 'none',
  },
});

export const ListItem = withStyles(styles)(
  ({ classes, key, label, checked, onClick }) => (
    <li className={classes.childListItem} key={key}>
      <Checkbox
        size={16}
        checked={checked}
        label={label}
        onChange={onClick}
        variant="outlined"
      />
    </li>
  )
);

export default withStyles(styles)(EquipmentMenuItem);
