// @flow
/* eslint react/prop-types: 0 */

import * as React from 'react';

import { connect } from 'react-redux';
import { Router } from '@reach/router';
import * as routes from '../../routes';
import {
  ViewNotifications,
  ViewProfile,
  ViewDashboard,
} from '../../components/Views';
import ConnectedHeader from './ConnectedHeader';
import Navigation from '../../components/Navigation';
import ViewEquipment from '../../components/views/ViewEquipment';
import ViewSites from '../../components/views/ViewSites';
import styles from './DefaultLayout.module.css';
import ViewLogin from '../../components/views/ViewLogin';
import ViewUsers from '../../components/views/ViewUsers';
import type { User } from '../../types/user';
import Loader from '../../components/Loader';

type Props = {
  app: { isAuthenticated: boolean },
  user: User,
};

function DefaultLayout({ app, user }: Props) {
  if (!app.isAuthenticated) {
    return <ViewLogin />;
  }

  if (!user.id) {
    return <Loader />;
  }

  return (
    <div className={styles.root}>
      <ConnectedHeader classes={styles} isAuthenticated />
      <Navigation classes={styles} isAuthenticated />
      <main className={styles.content}>
        <Router primary={false}>
          <ViewDashboard path="/" />
          <ViewNotifications path="notifications/*" />
          <ViewEquipment path="monitoring/equipment/*" />
          <ViewSites path="monitoring/sites/*" />
          <ViewUsers path="users/*" />
          <ViewProfile path={routes.PROFILE} />
        </Router>
      </main>
    </div>
  );
}

export default connect(state => ({ app: state.app, user: state.user }))(
  DefaultLayout
);
