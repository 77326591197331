import { connect } from 'react-redux';
import AuthForm from '../../components/auth/AuthForm/AuthForm';
import { loginUser } from '../../actions/user';

const mapStateToProps = state => ({ app: state.app });
const mapDispatchToProps = {
  loginUser,
};

const Login = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthForm);

export default Login;
