// @flow

import React from 'react';
import Icon from '../../Icon';
import styles from './NotificationIndicator.module.css';
import { fetchUnseenNotificationCountByResourceId } from '../../../actions/notifications';

type Props = {
  resourceId: number,
};

type State = {
  count: number,
  isLoading: boolean,
};

export default class NotificationIndicator extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      count: 0,
    };
  }

  componentDidMount() {
    this.getCount();
    // this.intervalFunction = setInterval(this.getCount, 15000);
  }

  componentWillUnmount() {
    // window.clearInterval(this.intervalFunction);
  }

  getCount = () =>
    fetchUnseenNotificationCountByResourceId(this.props.resourceId, 'equipment')
      .then(res => {
        this.setState({ count: res.count, isLoading: false });
      })
      .catch(() => {
        // console.log(err);
      });

  // intervalFunction: any;

  render() {
    const { count, isLoading } = this.state;

    return (
      <div>
        <div className={styles.notification}>
          <Icon icon="notification" />
          {!isLoading && <span> {count} </span>}
        </div>
      </div>
    );
  }
}
