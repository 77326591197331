// @flow

import * as React from 'react';
import { EquipmentGrouping } from '../../reducers/equipmentGrouping';
import DropDown from '../DropDown';
import styles from './EquipmentGroupingSelect.module.css';

type Props = {
  onChange: string => void,
};

const EquipmentGroupingSelect = ({ onChange }: Props) => {
  const sortItems = [
    { text: 'Equipment Name', value: EquipmentGrouping.ALPHABETICAL },
    { text: 'Equipment Type', value: EquipmentGrouping.EQUIPMENT_TYPE },
    { text: 'Equipment Group', value: EquipmentGrouping.EQUIPMENT_GROUP },
    { text: 'Space', value: EquipmentGrouping.SPACE },
  ];

  return (
    <div className={styles.root}>
      <DropDown labelText="Sort by" onChange={onChange} sortItems={sortItems} />
    </div>
  );
};

export default EquipmentGroupingSelect;
