// @flow

import * as React from 'react';
import Loadable from 'react-loadable';
import CircularProgress from '@material-ui/core/CircularProgress';
import type { IconFactory } from '../../types/icon';

const Loading = ({ size = 40 }: { size: number }) => (
  <CircularProgress size={size} />
);

const GasStation = Loadable({
  loader: () => import('./sites/GasStation'),
  loading: Loading,
});

export default ({ classes, size, id }: IconFactory) => {
  switch (id) {
    case 'gasstation':
      return <GasStation classes={classes} size={size} id={id} />;
    default:
      return '';
  }
};
