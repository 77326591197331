// @flow

import * as React from 'react';
import classNames from '../../../utils/classNames';
import { equipmentStatus } from '../../../types/equipment';
import type { Equipment } from '../../../types/equipment';
import type { favoriteTypes } from '../../../types/general';
import IconEquipmentFactory from '../../icons/IconEquipmentFactory';
import styles from './EquipmentCard.module.css';
import SpaceBreadCrumbs from '../../breadcrumbs/SpaceBreadCrumbs';
import Tooltip from '../../Tooltip';
import { equipmentLocationMapping } from '../../../utils/locationMapping';
import NotificationIndicator from './NotificationIndicator';

type Props = {
  styles: any,
  notificationCount?: number,
  onCardClick?: Function,
  onPinClick: Function,
  pinnedType: favoriteTypes,
  status: string,
  onFocus?: Function,
  equipmentObject: Equipment,
};
// function getStatus(s: string) {
//   switch (s) {
//     case equipmentStatus.on: {
//       return 'on';
//     }
//     case equipmentStatus.off: {
//       return 'off';
//     }
//     default: {
//       return 'stdby';
//     }
//   }
// }
/* eslint-disable */

const generateTooltipContent = (equipmentObject: Equipment) => {
  const pathAndSiteObject = equipmentLocationMapping(equipmentObject);
  return (
    <div>
      <div className={styles.tooltipTitle}>{pathAndSiteObject.site.title}</div>
      <SpaceBreadCrumbs collection={pathAndSiteObject.path} />
    </div>
  );
};
/* eslint-enable */

const EquipmentCard = ({
  equipmentObject,
  // notificationCount = 0,
  // onPinClick,
  onCardClick,
  onFocus,
  status = equipmentStatus.on,
}: Props) => (
  <div
    onFocus={onFocus}
    onMouseEnter={onFocus}
    role="button"
    tabIndex={0}
    className={classNames([
      styles.root,
      status === equipmentStatus.on ? styles.rootStatusOn : undefined,
      status === equipmentStatus.standby ? styles.rootStatusStandby : undefined,
      status === equipmentStatus.off ? styles.rootStatusOff : undefined,
      equipmentObject.pinned && status === equipmentStatus.on
        ? styles.rootStatusOnPinned
        : undefined,
      equipmentObject.pinned && status === equipmentStatus.standby
        ? styles.rootStatusStandbyPinned
        : undefined,
      equipmentObject.pinned && status === equipmentStatus.off
        ? styles.rootStatusOffPinned
        : undefined,
      onCardClick ? styles.pointer : undefined,
    ])}
    onKeyPress={onCardClick ? () => onCardClick() : undefined}
    onClick={onCardClick ? () => onCardClick() : undefined}
  >
    <div className={styles.areaIcon}>
      <IconEquipmentFactory
        styles={styles}
        id={equipmentObject.type.alias}
        size={80}
      />
    </div>

    <div className={styles.areaTitle}>
      <h5 className={styles.title}>{equipmentObject.title}</h5>
    </div>
    <div className={styles.areaLocation}>
      <Tooltip
        content={generateTooltipContent(equipmentObject)}
        arrowDirection="bottom"
      >
        {equipmentObject.space && equipmentObject.space.title}
      </Tooltip>
    </div>
    <div />
    <div className={styles.notification}>
      <NotificationIndicator resourceId={equipmentObject.id} />
    </div>
  </div>
);

export default EquipmentCard;
