// @flow

import * as React from 'react';
import styles from './PieSlice.module.css';

type Slice = {
  data: { name: string, value: number },
  index: number,
  value: number,
  startAngle: number,
  endAngle: number,
  padAngle: number,
};

type Props = {
  slice: Slice,
  fill: string,
  arc: Function,
  arcHover: Function,
  percentage: Function,
};

type State = {
  path: string,
};

class PieSlice extends React.Component<Props, State> {
  state = {
    path: this.props.arc(this.props.slice),
  };

  onMouseEnter = (slice: Slice) =>
    this.setState({
      path: this.props.arcHover(slice),
    });

  onMouseLeave = (slice: Slice) =>
    this.setState({
      path: this.props.arc(slice),
    });

  render() {
    const { slice, fill, arc, percentage } = this.props;

    return (
      <g
        key={slice.data.name}
        className={styles.sliceContainer}
        onMouseEnter={() => this.onMouseEnter(slice)}
        onMouseLeave={() => this.onMouseLeave(slice)}
        style={{ color: fill }}
      >
        <path className={styles.slice} d={this.state.path} />
        <text
          className={styles.sliceText}
          dy=".35em"
          textAnchor="middle"
          transform={`translate(${arc.centroid(slice)})`}
        >
          {percentage(slice.value)}
        </text>
      </g>
    );
  }
}

export default PieSlice;
