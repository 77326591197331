// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';

type Props = {
  classes: any,
  children: React.Node,
};

type State = {};

const styles = theme => ({
  root: {
    height: '3.75rem',
    backgroundColor: theme.palette.ambient.basePlus90,
    paddingLeft: '3.5rem',
    paddingRight: '3.5rem',
    position: 'relative',
  },
  tabs: {
    position: 'absolute',
    bottom: '-5px',
  },
});

class MenuTabBar extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.tabs}>{this.props.children}</div>
      </div>
    );
  }
}

export default withStyles(styles)(MenuTabBar);
