// @flow

import * as React from 'react';
import classNames from '../utils/classNames';
import styles from './Switch.module.css';

type Props = {
  checked: boolean,
  onChange: any => any,
  id: string,
};

const Switch = ({ checked, onChange, id }: Props) => (
  <label
    className={classNames(
      styles.switchWrapper,
      checked ? styles.switchOn : styles.switchOff,
      checked && styles.active
    )}
    htmlFor={id}
  >
    <input
      className={classNames(styles.switchCheckbox)}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      id={id}
    />
  </label>
);

export default Switch;
