// @flow

import { store } from '../index';
import { getConfig, services } from '../config';

const config = getConfig();

type HttpRequestHeaders = {
  'Content-Type': string,
  Authorization?: string,
};

function getServiceUrl(service): string {
  switch (service) {
    case services.cas:
      return config.serviceUrls.SERVICE_CAS;
    case services.sentinel:
      return config.serviceUrls.SERVICE_SENTINELS;
    case services.tsd:
      return config.serviceUrls.SERVICE_TSD_ROOT;
    default:
      return config.serviceUrls.SERVICE_IAM;
  }
}

export const defaultHeaders = (token: string): HttpRequestHeaders => {
  const authorization = token ? `Bearer ${token}` : null;
  const headers: HttpRequestHeaders = {
    'Content-Type': 'application/json',
  };

  if (authorization) {
    headers.Authorization = authorization;
  }

  return headers;
};

export async function fetchApi({
  endpoint = '',
  method = 'GET',
  payload = {},
  service,
}: {
  endpoint: string,
  method?: string,
  payload?: any,
  service?: string,
}): Promise<any> {
  const { accessToken } = store.getState().app;

  const fullUrl = `${getServiceUrl(service)}${endpoint}`;
  const headers = defaultHeaders(accessToken);

  const body = Object.keys(payload).length
    ? JSON.stringify(payload)
    : undefined;

  try {
    const response = await fetch(fullUrl, { method, headers, body });

    // Todo: Refresh token if expired
    // Todo: Check 200, 201, 204 or any error
    if (response.status === 401) {
      // todo: parse JSON, check if token has expired, retry request with updated token
      return Promise.reject(Error('Unauthorized'));
    }

    if (response.status === 403) {
      return Promise.reject(new Error('Forbidden'));
    }

    if (!response.ok) {
      return Promise.reject(new Error(`API request failed ${response.status}`));
    }

    if (response.status === 204) {
      return Promise.resolve();
    }

    const responseContentType = response.headers.get('content-type');

    if (responseContentType) {
      if (
        responseContentType.startsWith('application/json') ||
        responseContentType.startsWith('application/vnd.api+json')
      ) {
        return Promise.resolve(response.json());
      }
    }

    return Promise.resolve(response.text());
  } catch (err) {
    return Promise.reject(err);
  }
}

export default fetchApi;
