// @flow

import * as React from 'react';
import classNames from '../../../utils/classNames';
import styles from './InputField.module.css';

type Props = {
  isMainTitle?: boolean,
  isTitle?: boolean,
  type?: string,
  placeholder?: string,
  disabled?: boolean,
  value: string,
  fullWidth?: boolean,
  handleChange?: Function,
  error?: boolean,
  onChange?: string => void,
  onFocus?: any => void,
  extraClassName?: string,
};

const InputField = ({
  isMainTitle,
  isTitle,
  type = 'text',
  value,
  handleChange,
  placeholder = '',
  disabled = false,
  error = false,
  onChange,
  onFocus,
  extraClassName = '',
}: Props) => (
  <input
    type={type}
    onChange={handleChange || onChange}
    onFocus={onFocus}
    placeholder={placeholder}
    value={value}
    disabled={disabled}
    className={classNames(
      styles.input,
      error ? styles.errorInput : null,
      isTitle ? styles.isTitle : null,
      isMainTitle ? styles.isMainTitle : null,
      extraClassName !== '' ? extraClassName : null
    )}
  />
);

export default InputField;
