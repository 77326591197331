// @flow

import React from 'react';
import IconSensorTypeFactory from '../../icons/IconSensorTypeFactory';

import styles from './SensorTypeChip.module.css';
import type { SensorType } from '../../../types/sensors';
import type { SentinelType } from '../../../types/sentinel';

type Props = {
  primaryText: string,
  secondaryText?: string,
  sensorType?: SensorType,
  sentinelType?: SentinelType,
  type: SensorType | SentinelType,
};

export default ({
  primaryText,
  type,
  sensorType,
  sentinelType,
  secondaryText,
}: Props) => (
  <div className={styles.grouping}>
    {(type || sensorType || sentinelType) && (
      <div className={styles.icon}>
        <IconSensorTypeFactory
          size={20}
          id={type || sensorType || sentinelType}
        />
      </div>
    )}
    <span className={styles.text}>
      <div>{primaryText}</div>
      {secondaryText && (
        <div className={styles.secondaryText}>{secondaryText}</div>
      )}
    </span>
  </div>
);
