// @flow

import * as React from 'react';
import type { NotificationIcon } from '../../../types/icon';

const IconSize = () => (
  <g>
    <path
      d="M11.5 2c2 0 3.6 1.5 3.6 3.3v2.3c0 1.8-1.1 2.9-3.1 4.3-.6.4-1.3.8-1.9 1.2V11H4.6C2.6 11 1 9.5 1 7.7V5.3C1 3.5 2.6 2 4.6 2h6.9m0-1H4.6C2.1 1 0 2.9 0 5.3v2.3c0 2.4 2 4.3 4.6 4.3H9v3s2.1-1.3 3.4-2.2c2-1.4 3.6-2.7 3.6-5.1V5.3C16 2.9 14 1 11.5 1z"
      fill="currentColor"
    />
    <path d="M12 5H4v1h8V5zM8 7H4v1h4V7z" fill="currentColor" />
  </g>
);

const getIconBySize = (size: number) => {
  switch (size) {
    default:
      return { viewBox: '0 0 16 16', icon: <IconSize /> };
  }
};
// TODO THIS IS A PLACE HOLDER FOR THE REAL ICON LOGIC

export default ({ classes = {}, size = 40 }: NotificationIcon) => {
  const { viewBox, icon } = getIconBySize(size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={classes.icon}
      width={size}
      height={size}
    >
      {icon}
    </svg>
  );
};
