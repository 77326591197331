// @flow

import * as React from 'react';
import styles from './EquipmentSelector.module.css';
import { fetchSites } from '../../../actions/sites';
import type { Site } from '../../../types/site';
import { fetchEquipment } from '../../../actions/equipment';
import classNames from '../../../utils/classNames';
import type { Equipment } from '../../../types/equipment';
import FilterMenuItem from '../../Filters/FilterItems/FilterMenuItem';
import type { SentinelType } from '../../../types/sentinel';
import { sentinelTypeToSensorType } from '../../../types/sentinel';
import SearchBox from '../../search/SearchBox';
import { filterSites } from '../../../utils/textFilters';

type Props = {
  sentinelType: SentinelType,
  handleSelectSite: Site => void,
  selectedSites: Set<number>,
};

type State = {
  sites: Site[],
  query: string,
  isLoading: boolean,
  hasError: boolean,
};

class EquipmentSelector extends React.Component<Props, State> {
  state = {
    sites: [],
    query: '',
    isLoading: false,
    hasError: false,
  };

  componentDidMount() {
    this.loadSites();
  }

  async loadEquipment(siteId: number): Promise<Equipment[]> {
    this.setState({ isLoading: true });
    try {
      const { sentinelType } = this.props;
      const sensorType = sentinelTypeToSensorType(sentinelType);
      const equipment = await fetchEquipment({
        siteIds: [siteId],
        sensorTypes: [sensorType],
      });
      this.setState({ isLoading: false, hasError: false });
      return equipment;
    } catch (err) {
      this.setState({ isLoading: false, hasError: true });
      return [];
    }
  }

  async loadSites() {
    try {
      const { sentinelType } = this.props;

      const sensorType = sentinelTypeToSensorType(sentinelType);
      const sites = await fetchSites({
        sensorTypes: [sensorType],
        sensorTarget: ['EQUIPMENT'],
      });
      this.setState({ sites, hasError: false });
    } catch (err) {
      this.setState({ hasError: true });
    }
  }

  handleSearch = (value: string) =>
    this.setState({
      query: value,
    });

  handleSelectSite = (site: Site) => {
    this.loadEquipment(site.id).then(
      equipment =>
        equipment.length > 0
          ? this.props.handleSelectSite({
              ...site,
              equipment,
            })
          : undefined
    );
  };

  render() {
    const { sites, query, isLoading, hasError } = this.state;
    const { selectedSites } = this.props;
    const lowerCaseFilterValue = query.toLowerCase();
    const renderItems = sites
      .filter(filterSites.bind(this, lowerCaseFilterValue))
      .map(site => (
        <FilterMenuItem
          className={classNames(
            styles.listElement,
            isLoading ? styles.isLoading : undefined
          )}
          key={site.id}
          id={site.id}
          title={site.title}
          description={site.address}
          onClick={() => this.handleSelectSite(site)}
          checked={selectedSites.has(site.id)}
          highlight={query}
        >
          {site.title}
        </FilterMenuItem>
      ));

    if (hasError) {
      return <div>An error occurred</div>;
    }

    return (
      <div className={styles.root}>
        <SearchBox
          placeholder="site name, city, zip…"
          query={query}
          onChange={this.handleSearch}
          items={renderItems}
          width="18.75rem"
          widthActive="18.75rem"
          withDropdown
          error={renderItems.length === 0}
        />
      </div>
    );
  }
}

export default EquipmentSelector;
