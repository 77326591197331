// @flow
import * as React from 'react';

// import SpaceMenuFilter from './FilterMenus/SpaceMenuFilter';
// <SpaceMenuFilter label="Spaces" />
import EquipmentFilter from '../../containers/filter/EquipmentFilter';
// import StatusMenuFilter from '../../containers/filter/StatusFilter';
// <StatusMenuFilter label="Status" />

import BaseFilterBar from './BaseFilterBar';
import SearchBox from '../search/SearchBox';

type Props = {
  query?: string,
  onChange?: (query: string) => void,
  error?: boolean,
};

const EquipmentFilterBar = ({ query, error, onChange }: Props) => (
  <BaseFilterBar>
    <EquipmentFilter label="Equipment Types" />
    <SearchBox
      placeholder="Keyword, equipment name, etc…"
      width="15.625rem"
      widthActive="15.625rem"
      query={query}
      error={error}
      onChange={onChange}
    />
  </BaseFilterBar>
);

export default EquipmentFilterBar;
