// @flow

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import { Link } from '@reach/router';

import IconUIFactory from '../../icons/IconUiFactory';
import classNames from '../../../utils/classNames';
import styles from './MenuSidebarItem.module.css';
import * as routes from '../../../routes';

const isActive = (to, classes) => ({ isCurrent, isPartiallyCurrent }) => {
  if (to === routes.HOME) {
    if (isCurrent) {
      return { className: classNames([classes.navItemLink, classes.active]) };
    }

    return null;
  }

  return isPartiallyCurrent
    ? { className: classNames(classes.navItemLink, classes.active) }
    : null;
};

type Props = {
  classes?: string[],
  to?: string,
  icon?: string,
  onClick?: any => any,
  custom?: any,
};

export const MenuSidebarItem = ({
  classes = [],
  to = '',
  icon,
  onClick,
  custom,
}: Props) => (
  <li className={classNames(styles.navItem, ...classes)}>
    {to ? (
      <Link
        to={to}
        className={styles.navItemLink}
        getProps={isActive(to, styles)}
      >
        {custom || <IconUIFactory size={25} id={icon} />}
      </Link>
    ) : (
      <div onClick={onClick} className={styles.navItemLink}>
        {custom || <IconUIFactory size={25} id={icon} />}
      </div>
    )}
  </li>
);

export default MenuSidebarItem;
