// @flow

import { equipmentStatus } from '../types/equipment';
import {
  EQUIPMENT_TYPES_SUCCESS,
  SET_EQUIPMENT_TYPE_LIST_FILTER,
  SET_EQUIPMENT_SPACE_LIST_FILTER,
  SET_EQUIPMENT_STATUS_LIST_FILTER,
} from '../actions/equipmentFilter';
import type { EquipmentType } from '../types/tags';

type EquipmentFilter = { [key: string]: string[] };

export const EquipmentFilters = {
  STATUS: 'STATUS',
  TYPE: 'TYPE',
  SPACE: 'SPACE',
};

const initialState: EquipmentFilter = {
  [EquipmentFilters.STATUS]: [equipmentStatus.on],
  [EquipmentFilters.TYPE]: [],
  [EquipmentFilters.SPACE]: [],
};

function updateByValue(s: string[], needle: string): string[] {
  return s.includes(needle) ? s.filter(f => f !== needle) : [...s, needle];
}

export default (
  state: EquipmentFilter = initialState,
  action: { type: string, payload: any }
) => {
  switch (action.type) {
    case SET_EQUIPMENT_STATUS_LIST_FILTER: {
      return {
        ...state,
        [EquipmentFilters.STATUS]: updateByValue(
          state[EquipmentFilters.STATUS],
          action.payload
        ),
      };
    }

    case SET_EQUIPMENT_TYPE_LIST_FILTER: {
      return {
        ...state,
        [EquipmentFilters.TYPE]: action.payload,
      };
    }

    case EQUIPMENT_TYPES_SUCCESS: {
      return {
        ...state,
        [EquipmentFilters.TYPE]: action.payload.equipmentTypes.map(
          (e: EquipmentType) => e.id
        ),
      };
    }

    case SET_EQUIPMENT_SPACE_LIST_FILTER: {
      return {
        ...state,
        [EquipmentFilters.SPACE]: updateByValue(
          state[EquipmentFilters.SPACE],
          action.payload
        ),
      };
    }

    // case SET_EQUIPMENT_TEXT_FILTER: {
    //   return {
    //     ...state,
    //     [EquipmentFilters.TEXT]: updateByValue(
    //       state[EquipmentFilters.TEXT],
    //       action.payload
    //     ),
    //   };
    // }

    default: {
      return state;
    }
  }
};
