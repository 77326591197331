// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import styles from './ViewMonitoringEquipmentList.module.css';
import {
  getEquipment,
  starEquipment,
  clearEquipment,
  getEquipmentById,
} from '../../../../actions/equipment';
import type { Equipment } from '../../../../types/equipment';
import type { Space } from '../../../../types/space';
import { setPageIcon, setPageTitle } from '../../../../actions/pageInfo';
import EquipmentCard from '../../../../components/cards/EquipmentCard';
import EquipmentFilterBar from '../../../../components/Filters/EquipmentFilterBar';
import BaseContentContainer from '../../../../components/layout/BaseContentContainer';
import EquipmentCardList from '../../../../components/lists/EquipmentCardList';
import { EquipmentFilters } from '../../../../reducers/equipmentFilter';
import LineSeparator from '../../../../components/LineSeparator';
import { EquipmentGrouping } from '../../../../reducers/equipmentGrouping';
import EquipmentGroupingSelect from '../../../../components/Filters/EquipmentGroupingSelect';
import { setEquipmentGrouping } from '../../../../actions/equipmentGrouping';
import {
  equipmentStatus,
  equipmentStatusToName,
} from '../../../../types/equipment';
import type { EquipmentGroup, EquipmentType } from '../../../../types/tags';
import { filterEquipment } from '../../../../utils/textFilters';

type Props = {
  getEquipment: Object => any,
  setPageTitle: string => void,
  setPageIcon: string => void,
  clearEquipment: () => void,
  setEquipmentGrouping: string => void,
  equipmentStatusFilter: string[],
  equipmentTypeFilter: number[],
  equipmentSpaceFilter: number[],
  spaces: { [key: string]: Space },
  equipment: Equipment[],
  equipmentTags: {
    equipmentTypes: EquipmentType[],
    equipmentGroups: EquipmentGroup[],
  },
  getEquipmentById: number => void,
  equipmentGrouping: string,
  isLoading: boolean,
};

type State = {
  items: Equipment[],
  textFilter: string,
  isFetching: boolean,
  filteredSpaces: Object,
};

class ViewMonitoringEquipmentList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.props.setPageTitle('Equipment');
    this.props.setPageIcon('Equipment');
    this.props.clearEquipment();
    this.state = {
      items: [],
      textFilter: '',
      isFetching: false,
      filteredSpaces: {},
    };
  }

  componentDidMount() {
    if (this.props.equipmentTypeFilter.length) {
      this.loadEquipment(this.props.equipmentTypeFilter);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const equipmentFilterDidChange =
      this.props.equipmentTypeFilter.length !==
      prevProps.equipmentTypeFilter.length;

    if (equipmentFilterDidChange) {
      this.loadEquipment(this.props.equipmentTypeFilter);
    }
  }

  getEquipmentWithFullPath = (id: number) => {
    const equipment = this.props.equipment.find((e: Equipment) => e.id === id);

    if (equipment && !equipment.hasFullPath) {
      this.props.getEquipmentById(id);
    }
  };

  loadEquipment = equipmentTypeFilter => {
    this.setState({ isFetching: true });

    this.props
      .getEquipment({ equipmentTypeIds: equipmentTypeFilter })
      .then(items => {
        const filteredSpaces = items.reduce((acc, cur) => {
          if (cur.space) {
            acc[cur.space.id] = { title: cur.space.title };
          }
          return acc;
        }, {});

        this.setState({ items, isFetching: false, filteredSpaces });
      })
      .catch(() => this.setState({ isFetching: false }));
  };

  fnEquipmentFilter = (item: Equipment): boolean =>
    this.props.equipmentTypeFilter.includes(item.typeId);

  groupBy = (groupType: string, list: Equipment[]) => {
    const filteredList = list.filter(this.fnEquipmentFilter);
    switch (groupType) {
      case EquipmentGrouping.STATUS:
        return filteredList.reduce(
          (group, next: Equipment) =>
            group.set(next.status, [...(group.get(next.status) || []), next]),
          new Map([
            [equipmentStatus.on, []],
            [equipmentStatus.off, []],
            [equipmentStatus.standby, []],
          ])
        );

      case EquipmentGrouping.ALPHABETICAL:
        return filteredList.reduce(
          (group, next: Equipment) =>
            group.set(next.title[0], [
              ...(group.get(next.title[0]) || []),
              next,
            ]),
          new Map([
            ...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
              .split('')
              .map(letter => [letter, []]),
          ])
        );

      case EquipmentGrouping.EQUIPMENT_GROUP:
        return filteredList.reduce(
          (group, next: Equipment) =>
            group.set((next.type.groupId || '').toString(), [
              ...(group.get((next.type.groupId || '').toString()) || []),
              next,
            ]),
          new Map()
        );

      case EquipmentGrouping.EQUIPMENT_TYPE:
        return filteredList.reduce(
          (group, next: Equipment) =>
            group.set((next.typeId || '').toString(), [
              ...(group.get((next.typeId || '').toString()) || []),
              next,
            ]),
          new Map()
        );

      case EquipmentGrouping.SPACE:
        return filteredList.reduce(
          (group, next: Equipment) =>
            group.set((next.spaceId || '').toString(), [
              ...(group.get((next.spaceId || '').toString()) || []),
              next,
            ]),
          new Map()
        );
      default:
        return new Map([['none', filteredList]]);
    }
  };

  groupKeyToName = (groupType, groupKey: number | string): string => {
    switch (groupType) {
      case EquipmentGrouping.STATUS:
        return (
          equipmentStatusToName[groupKey] || 'No Status Found'
        ).toUpperCase();
      case EquipmentGrouping.ALPHABETICAL:
        return groupKey.toString().toUpperCase();

      case EquipmentGrouping.EQUIPMENT_GROUP:
        return (
          this.props.equipmentTags.equipmentGroups.find(
            g => g.id.toString() === groupKey.toString()
          ) || {}
        ).title;

      case EquipmentGrouping.EQUIPMENT_TYPE: {
        return (
          this.props.equipmentTags.equipmentTypes.find(
            e => e.id.toString() === groupKey.toString()
          ) || {}
        ).title;
      }

      // TODO re-think how to get spaces given that this could be a big list of large items.
      // We really need flat collections of like [ { id: title: } ] for a lot of these.

      case EquipmentGrouping.SPACE:
        return (
          (this.state.filteredSpaces[groupKey.toString()] || {}).title ||
          'NO SPACE FOUND'
        );
      default:
        return '';
    }
  };

  handlePinClick = () => window.alert('pinned'); // eslint-disable-line no-alert

  handleCardClick = (id: number) =>
    navigate(`/monitoring/equipment/${id}/monitor`);

  renderList = equipment => {
    const { equipmentGrouping } = this.props;
    const grouped = this.groupBy(equipmentGrouping, equipment);
    return [...grouped] // map to array
      .filter(arr => arr[1].length > 0)
      .map(([groupKey, equipmentList]: [string, Equipment[]]) => (
        <div key={`ViewMonitoringEquipmentList${groupKey}`}>
          <LineSeparator>
            {this.groupKeyToName(equipmentGrouping, groupKey)}
          </LineSeparator>
          <EquipmentCardList>
            {equipmentList.map(e => this.renderEquipmentCard(e))}
          </EquipmentCardList>
        </div>
      ));
  };

  renderEquipmentCard = (e: Equipment) => (
    <EquipmentCard
      key={e.id}
      equipmentObject={e}
      onPinClick={this.handlePinClick}
      onCardClick={() => this.handleCardClick(e.id)}
      onFocus={() => this.getEquipmentWithFullPath(e.id)}
    />
  );

  renderEquipmentCardListOrMessage = (items: any) =>
    items.length ? (
      this.renderList(items)
    ) : (
      <div className={styles.messageContainer}>
        <div>
          <h5>Oops! We can’t seem to find the result you’re looking for.</h5>
        </div>
        <div>
          <span>No equipment found</span>
        </div>
      </div>
    );

  render() {
    const { equipmentGrouping, isLoading } = this.props;
    const { items, isFetching, textFilter = '' } = this.state;
    const filteredEquipment = items.filter(
      filterEquipment.bind(this, textFilter)
    );

    return (
      <React.Fragment>
        <EquipmentFilterBar
          query={this.state.textFilter}
          error={!filteredEquipment.length}
          onChange={value => this.setState({ textFilter: value.toLowerCase() })}
        />
        <BaseContentContainer hasFilterBar isLoading={isLoading}>
          {isFetching && <div>...loading</div>}

          {!isFetching && (
            <div className={styles.flexRow}>
              <EquipmentGroupingSelect
                value={equipmentGrouping}
                onChange={this.props.setEquipmentGrouping}
              />
            </div>
          )}

          {!isFetching &&
            this.renderEquipmentCardListOrMessage(filteredEquipment)}
        </BaseContentContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  equipmentStatusFilter: state.equipmentFilter[EquipmentFilters.STATUS],
  equipmentTypeFilter: state.equipmentFilter[EquipmentFilters.TYPE],
  equipmentSpaceFilter: state.equipmentFilter[EquipmentFilters.SPACE],
  spaces: state.spaces,
  equipment: state.equipment,
  equipmentTags: state.equipmentTags,
  equipmentGrouping: state.equipmentGrouping,
  isLoading: state.loading.equipment,
});

const mapDispatchToProps = {
  getEquipment,
  setPageTitle,
  setPageIcon,
  starEquipment,
  setEquipmentGrouping,
  clearEquipment,
  getEquipmentById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewMonitoringEquipmentList);
