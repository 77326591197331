// @flow

import fetchApi from '../middleware/api';
import { services } from '../config';
import type {
  Notification,
  NotificationCountTypes,
} from '../types/notification';

export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_FAILURE = 'NOTIFICATIONS_FAILURE';

export const fetchNotificationsList = (queryParams: string) =>
  fetchApi({
    endpoint: `/api/v1/notifications?${queryParams}`,
    service: services.sentinel,
  });

export const getNotifications = (queryParams: string) => async (
  dispatch: any => any
): Promise<any> => {
  dispatch({ type: NOTIFICATIONS_REQUEST });
  try {
    const notifications = await fetchNotificationsList(queryParams);
    dispatch({ type: NOTIFICATIONS_SUCCESS, payload: notifications });
    return notifications;
  } catch (err) {
    dispatch({ type: NOTIFICATIONS_FAILURE });
    return Promise.reject(new Error(err));
  }
};

export const NOTIFICATIONS_CLEAR = 'NOTIFICATIONS_CLEAR';
export const clearNotifications = () => ({
  type: NOTIFICATIONS_CLEAR,
});

export const DISMISS_NOTIFICATION_REQUEST = 'DISMISS_NOTIFICATION_REQUEST';
export const DISMISS_NOTIFICATION_SUCCESS = 'DISMISS_NOTIFICATION_SUCCESS';
export const DISMISS_NOTIFICATION_FAILURE = 'DISMISS_NOTIFICATION_FAILURE';

const dismissNotification = (id: string, comment: string) =>
  fetchApi({
    endpoint: `/api/v1/notifications/${id}/dismiss`,
    payload: {
      dismissedComment: comment,
    },
    service: services.sentinel,
    method: 'PUT',
  });

export const dismissNotificationById = (id: string, comment: string) => async (
  dispatch: any => any
): Promise<any> => {
  dispatch({ type: DISMISS_NOTIFICATION_REQUEST });
  try {
    const notification = await dismissNotification(id, comment);
    dispatch({ type: DISMISS_NOTIFICATION_SUCCESS, payload: notification });
    return notification;
  } catch (err) {
    dispatch({ type: DISMISS_NOTIFICATION_FAILURE });
    return Promise.reject(new Error(err));
  }
};
// Temporary
export const updateNotificationOrder = (notifications: Notification[]) => (
  dispatch: any
) => {
  dispatch({ type: NOTIFICATIONS_SUCCESS, payload: notifications });
};

export const fetchUnseenNotificationCountByResourceId = (
  resourceId: number,
  type: NotificationCountTypes
) =>
  fetchApi({
    endpoint: `/api/v1/count/notifications?state=unseen&${type}Ids=${resourceId}`,
    service: services.sentinel,
    method: 'GET',
  });

export const fetchAllUnseenNotificationCount = () =>
  fetchApi({
    endpoint: `/api/v1/count/notifications`,
    service: services.sentinel,
    method: 'GET',
  });
