// @flow

import React from 'react';
import styles from './LogoutModal.module.css';
import Button from '../ui/Button';
import BaseModal from './BaseModal';
import withLogout from '../../containers/auth/withLogout';

type Props = {
  onClose: Function,
};

const LogoutButton = withLogout(({ userLogout }) => (
  <Button primary onClick={() => userLogout()}>
    Logout
  </Button>
));

export default ({ onClose }: Props) => (
  <BaseModal onClose={onClose} title="Logout">
    <div className={styles.infoText}>Are you sure you want to logout?</div>
    <div className={styles.buttonGroup}>
      <LogoutButton />
      <Button onClick={onClose} secondary>
        Cancel
      </Button>
    </div>
  </BaseModal>
);
