// @flow

import * as React from 'react';
import TextField from '../ui/TextField';
import styles from './SentinelMetaInputs.module.css';

type Props = {
  handleNameChange: Function,
  title: string,
  description: string,
};

const SentinelMetaInput = ({ handleNameChange, title, description }: Props) => (
  <div className={styles.metaContainer}>
    <div>
      <TextField
        isMainTitle
        value={title}
        placeholder="Title"
        handleChange={handleNameChange('title')}
      />
      <TextField
        value={description}
        placeholder="Your description"
        handleChange={handleNameChange('description')}
        extraClassName={styles.descriptionField}
      />
    </div>
  </div>
);

export default SentinelMetaInput;
