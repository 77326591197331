// @flow

import * as React from 'react';

import ListHeader from '../ListHeader';
import ListCell from '../ListCell';
import ListRow from '../ListRow';
import ListContainer from '../ListContainer';
import {
  ActivityChip,
  BatteryChip,
  DateChip,
  TitleChip,
  SignalChip,
  ValueChip,
  StatusChip,
} from '../Cells';
import IconEquipmentFactory from '../../icons/IconEquipmentFactory';
import IconSensorTypeFactory from '../../icons/IconSensorTypeFactory';
import Tooltip from '../../Tooltip';
import BaseList from '../BaseList';
import type { Sensor } from '../../../types/sensors';
import type { Equipment } from '../../../types/equipment';
import SpaceBreadCrumbs from '../../breadcrumbs/SpaceBreadCrumbs';
import Button from '../../ui/Button';
import { sensorType } from '../../../types/sensors';
import InfoChip from '../Cells/InfoChip';
import { nextCheckIn } from '../../../utils/dateMath';
import signalToValue from '../../../utils/signalToValue';
import voltToValue from '../../../utils/voltToValue';
import ChevronDown from '../../icons/ui/ChevronDown';
import ChevronRight from '../../icons/ui/ChevronRight';
import styles from './SensorsList.module.css';
import { sensorLocationMapping } from '../../../utils/locationMapping';
import graphDemoTemperature from '../../../demoAssets/graph_temp_02.jpg';
import graphDemoDoor from '../../../demoAssets/graph_door_01.jpg';

type Props = {
  sensors: Sensor[],
  equipment?: Equipment,
  site?: Equipment,
};

type State = {
  expanded: Set<number>,
};

const extraStyles = {
  gridTemplateColumns:
    '1.25rem 3rem .14fr .12fr .11fr .18fr .16fr .15fr .05fr .05fr .05fr',
};

class SensorsList extends React.Component<Props, State> {
  state = {
    // $FlowFixMe
    expanded: new Set(),
  };

  handleExpandClick = (id: number) => {
    const expanded = new Set(this.state.expanded);

    if (expanded.has(id)) {
      expanded.delete(id);
    } else {
      expanded.add(id);
    }

    this.setState({ expanded });
  };

  renderLastCheckInChip = (
    sensorStatus: Sensor.sensorStatus,
    isOffline: boolean
  ) => (
    <Tooltip
      hoverOverride={false}
      arrowDirection="top"
      content={<DateChip value={sensorStatus.receivedAt} isTimeFrom={false} />}
    >
      <DateChip
        extraClassNames={[
          styles.timeText,
          isOffline ? styles['icon-offline'] : '',
        ]}
        value={sensorStatus.receivedAt}
        isTimeFrom
      />
    </Tooltip>
  );

  renderExpandedRow = (item: Sensor, itemExpanded: boolean) => {
    const t = item.types;
    const onlineStatus = item.sensorStatus ? 'online' : 'offline';
    const interval = item.sensorStatus ? item.sensorStatus.interval : '';
    const receivedAt = item.sensorStatus ? item.sensorStatus.receivedAt : '';
    const nextCheckInDate = interval ? nextCheckIn(interval, receivedAt) : '';

    return (
      <ListRow
        size={itemExpanded ? 'auto' : undefined}
        className=""
        extraStyles={extraStyles}
        extraClassNames={[styles[`row-${onlineStatus}`]]}
      >
        <ListCell isControlCell />
        <ListCell />
        <ListCell>
          <React.Fragment>
            {t.map(t => (
              <InfoChip key={t} title="Type" description={t.toLowerCase()} />
            ))}
            <InfoChip
              title="Model"
              description={item.modelId ? item.modelId.toString() : 'n/a'}
            />
            <InfoChip title="UUID" description={item.uuid} />
            <InfoChip
              title="Firmware Version"
              description={
                item.sensorInfo
                  ? item.sensorInfo.firmwareVersion || 'n/a'
                  : 'n/a'
              }
            />
          </React.Fragment>
        </ListCell>
        <ListCell />
        <ListCell />
        <ListCell />
        <ListCell>
          <div className={styles.description}>{item.description}</div>
        </ListCell>
        <ListCell>
          <React.Fragment>
            <InfoChip
              title="Expected Next Check-in"
              description={
                nextCheckInDate ? <DateChip value={nextCheckInDate} /> : 'n/a'
              }
            />
            <InfoChip
              title="Update Interval"
              description={
                interval
                  ? `${interval / 1000 / 60} ${
                      interval / 1000 / 60 === 1 ? 'minute' : 'minutes'
                    }`
                  : 'n/a'
              }
            />
          </React.Fragment>
        </ListCell>
        <ListCell />
        <ListCell />
        <ListCell />
      </ListRow>
    );
  };

  render() {
    const { expanded } = this.state;
    // eslint-disable-next-line no-unused-vars
    const { sensors, equipment, site } = this.props;

    if (sensors.length === 0) {
      return <div>No connected sensors found.</div>;
    }

    return (
      <BaseList>
        <ListHeader extraStyles={extraStyles}>
          <ListCell isControlCell />
          <ListCell />
          <ListCell>Name / Gateway</ListCell>
          <ListCell>Value</ListCell>
          <ListCell>Status</ListCell>
          <ListCell>Equipment</ListCell>
          <ListCell>Located In</ListCell>
          <ListCell>Last check-in</ListCell>
          <ListCell>Signal</ListCell>
          <ListCell>Power</ListCell>
          <ListCell>Activity</ListCell>
        </ListHeader>

        {sensors.map((item: Sensor) => {
          const itemExpanded = expanded.has(item.id);
          // TODO ADD UNPAIRED STATUS TO THIS TERNARY. CSS IS ALREADY SETUP for 'unpaired'
          const onlineStatus = item.sensorStatus ? 'online' : 'offline';
          const locationMapping = sensorLocationMapping(item);
          /* eslint-disable */
          return (
            <ListContainer key={`SensorsListRow${item.id}`}>
              <ListRow
                size={itemExpanded ? 'auto' : undefined}
                extraClassNames={[styles[`row-${onlineStatus}`]]}
                extraStyles={extraStyles}
              >
                <ListCell isControlCell>
                  <Button icon onClick={() => this.handleExpandClick(item.id)}>
                    {itemExpanded ? (
                      <div className={styles[`icon-${onlineStatus}`]}>
                        <ChevronDown size={12} />
                      </div>
                    ) : (
                      <div className={styles[`icon-${onlineStatus}`]}>
                        <ChevronRight size={12} />
                      </div>
                    )}
                  </Button>
                </ListCell>
                <ListCell>
                  <div className={styles.iconFlexCenter}>
                    <IconSensorTypeFactory
                      size={20}
                      id={sensorType[item.types[0]]}
                    />
                  </div>
                </ListCell>
                <ListCell>
                  <TitleChip primaryText={item.title} secondaryText="n/a" />
                </ListCell>
                <ListCell extraClassNames={[styles.showOverflow]}>
                  <ValueChip
                    sensorId={item.id}
                    updatedAt={
                      item.sensorStatus ? item.sensorStatus.receivedAt : ''
                    }
                    value={0}
                    type={item.types[0]}
                  />
                </ListCell>
                <ListCell>
                  <StatusChip
                    text={
                      onlineStatus.charAt(0).toUpperCase() +
                      onlineStatus.slice(1)
                    }
                    status={onlineStatus}
                  />
                </ListCell>
                <ListCell>
                  <div style={{ display: 'flex' }}>
                    {item.resource &&
                      item.resource.type && (
                        <span className={styles.iconPaddingRight}>
                          <IconEquipmentFactory
                            id={item.resource.type.alias}
                            size={15}
                          />
                        </span>
                      )}
                    <div className={styles.equipmentText}>
                      {' '}
                      {item.resource && item.resource._entity !== 'Space' ? (
                        item.resource.title
                      ) : (
                        <div className={styles.iconPaddingMimic}>----</div>
                      )}
                    </div>
                  </div>
                </ListCell>
                <ListCell>
                  <SpaceBreadCrumbs collection={locationMapping.path} />
                </ListCell>
                <ListCell extraClassNames={[styles.showOverflow]}>
                  {item.sensorStatus
                    ? this.renderLastCheckInChip(item.sensorStatus)
                    : 'Not checked in yet'}
                </ListCell>
                <ListCell>
                  <div className={styles.blackIcon}>
                    <SignalChip
                      level={
                        item.sensorStatus && item.sensorStatus.signalStrength
                          ? signalToValue(item.sensorStatus.signalStrength)
                          : 0
                      }
                    />
                  </div>
                </ListCell>
                <ListCell>
                  <div className={styles.blackIcon}>
                    <BatteryChip
                      level={
                        item.sensorStatus && item.sensorStatus.batteryVoltage
                          ? voltToValue(item.sensorStatus.batteryVoltage)
                          : 0
                      }
                    />
                  </div>
                </ListCell>
                <ListCell extraClassNames={[styles.borderLeft]}>
                  <ActivityChip type="sensor" resourceId={item.id} />
                </ListCell>
              </ListRow>
              {itemExpanded ? this.renderExpandedRow(item, itemExpanded) : null}
              {itemExpanded && item.types.includes(sensorType.TEMPERATURE) ? (
                <ListRow>
                  <ListCell>
                    <img
                      src={graphDemoTemperature}
                      alt=""
                      style={{ maxWidth: '100%', padding: '1rem' }}
                    />
                  </ListCell>
                </ListRow>
              ) : null}

              {itemExpanded && item.types.includes(sensorType.BINARY) ? (
                <ListRow>
                  <ListCell>
                    <img
                      src={graphDemoDoor}
                      alt=""
                      style={{ maxWidth: '100%', margin: '1rem' }}
                    />
                  </ListCell>
                </ListRow>
              ) : null}
            </ListContainer>
          );
        })}
      </BaseList>
    );
  }
}

export default SensorsList;
