// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  progress: {
    color: theme.palette.accent.main,
  },
});

type Props = {
  classes: any,
  isLoading?: boolean,
};

const Loader = ({ classes, isLoading = false }: Props) =>
  isLoading ? (
    <div className={classes.root}>
      <LinearProgress className={classes.progress} />
    </div>
  ) : null;

export default withStyles(styles)(Loader);
