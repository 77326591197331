// @flow

import * as React from 'react';

type Props = {
  classes: any,
  height: number,
  width: number,
};

export default ({ classes = {}, height = 40, width = 40 }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    className={classes.icon}
    width={height}
    height={width}
  >
    <g>
      <path
        style={{ opacity: '0.1' }}
        d="M37.707,18.4141a1,1,0,0,0-1.4141,0l-.4774.4774A14.99,14.99,0,0,0,6,21.1211a15.2643,15.2643,0,0,0,1.2381,6.0547,6.9852,6.9852,0,0,0,10.8792,8.6719A15.6116,15.6116,0,0,0,21,36.1211c2.8,0,9.0225-1.3447,12.5273-6.4326A1,1,0,0,0,31.88,28.5537C28.8467,32.958,23.437,34.1211,21,34.1211a13.6151,13.6151,0,0,1-1.6121-.1052c.0205-.0428.0491-.0806.0688-.1238A6.9908,6.9908,0,0,0,8.8525,25.4356c-.0353.0269-.0644.0609-.0992.0884A13.29,13.29,0,0,1,8,21.1211a12.9986,12.9986,0,0,1,25.7139-2.7l-.0068-.0068a1,1,0,0,0-1.4141,1.4141l1.9993,1.9993.0015.0015L35,22.5352l.7062-.7062.0015-.0015,1.9993-1.9993A1,1,0,0,0,37.707,18.4141Z"
      />
      <path
        style={{ opacity: '0.1' }}
        d="M18.19,7.9351a.5.5,0,0,0-.5.5v7.55a2.5,2.5,0,1,0,2.95,2.95h5.5654a.485.485,0,0,0,.485-.485v-.03a.485.485,0,0,0-.485-.485H20.64a2.5023,2.5023,0,0,0-1.95-1.95v-7.55A.5.5,0,0,0,18.19,7.9351Zm1.5,10.5a1.5,1.5,0,1,1-1.5-1.5A1.5017,1.5017,0,0,1,19.69,18.4351Z"
      />
    </g>
    <g>
      <path
        style={{ fill: 'currentColor' }}
        d="M26.2056,17.8135H20.64a2.5022,2.5022,0,0,0-1.95-1.95v-7.55a.5.5,0,1,0-1,0v7.55a2.5,2.5,0,1,0,2.95,2.95h5.5653a.4851.4851,0,0,0,.4851-.485v-.03A.485.485,0,0,0,26.2056,17.8135Zm-8.0149,2a1.5,1.5,0,1,1,1.5-1.5A1.5017,1.5017,0,0,1,18.1907,19.8135Z"
      />
      <g>
        <path
          style={{ fill: 'currentColor' }}
          d="M28.88,25.43C25.85,29.84,20.44,31,18,31q-.6149,0-1.23-.06a6.7959,6.7959,0,0,0,.6-1.05A7.1107,7.1107,0,0,0,17.93,28H14.47L13.4,30.19l-.5,1.03a.5.5,0,0,1-.45.28h-.03a.4959.4959,0,0,1-.44-.33l-.73-2.01L9.43,24.14,8.02,26.41l-.84,1.35a.4907.4907,0,0,1-.42.24H4.08A6.9788,6.9788,0,0,0,11,34a6.8825,6.8825,0,0,0,4.02-1.29A15.3644,15.3644,0,0,0,18,33c2.8,0,9.02-1.34,12.53-6.43a1.0028,1.0028,0,0,0-1.65-1.14Z"
        />
        <path
          style={{ fill: 'currentColor' }}
          d="M34.71,15.29a1.0078,1.0078,0,0,0-1.42,0l-.47.48A14.9929,14.9929,0,0,0,3,18a15.2872,15.2872,0,0,0,1.45,6.54A6.9292,6.9292,0,0,0,4,27H6.48l.89-1.43,1.75-2.83a.5246.5246,0,0,1,.48-.24.5131.5131,0,0,1,.42.33l2.5,6.89,1.19-2.44a.4859.4859,0,0,1,.44-.28H18a6.9949,6.9949,0,0,0-7-7,6.9233,6.9233,0,0,0-4.23,1.44,6.6344,6.6344,0,0,0-1.03.95A13.1761,13.1761,0,0,1,5,18a12.9976,12.9976,0,0,1,25.71-2.71,1.0041,1.0041,0,0,0-1.42,1.42l2,2,.71.7.71-.7,2-2A1.0079,1.0079,0,0,0,34.71,15.29Z"
        />
      </g>
    </g>
  </svg>
);
