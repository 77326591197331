// @flow

import * as React from 'react';
import { scaleOrdinal } from 'd3-scale';
import { hierarchy, partition } from 'd3-hierarchy';
import { arc } from 'd3-shape';
import { select } from 'd3-selection';
import classNames from '../../utils/classNames';
import styles from './Chart.module.css';
import ChartLegend from './ChartLegend';

const MARGINS = { top: 0, right: 20, bottom: 0, left: 20 };

const colorScale = scaleOrdinal().range([
  '#2BDDCF',
  '#3FBCF4',
  '#626DFA',
  '#B873E4',
  '#FF9248',
  '#77EB85',
]);

const alpha = [
  'FF', // 100% 255
  // 'F2', // 95% 242
  'E6', // 90% 230
  // 'D9', // 85% 217
  'CC', // 80% 204
  // 'BF', // 75% 191
  'B3', // 70% 179
  // 'A6', // 65% 166
  '99', // 60% 153
  // '8C', // 55% 140
  '80', // 50% 128
  // '73', // 45% 115
  '66', // 40% 102
  // '59', // 35% 89
  '4D', // 30% 77
  // '40', // 25% 64
  '33', // 20% 51
  // '26', // 15% 38
  '1A', // 10% 26
  // '0D', //  5% 13
  '00', //  0% 0
];

type Props = {
  data: any,
};

type State = {
  width: number,
  height: number,
  radius: number,
  name: string,
  totalSize: number,
  selectedSize: number,
};

class SunBurst extends React.Component<Props, State> {
  state = {
    width: 300,
    height: 300,
    radius: Math.min(300, 300) / 2 - 20,
    name: '',
    selectedSize: 0,
    totalSize: 0,
  };

  componentDidMount() {
    // window.addEventListener('resize', this.setOffsetWidth);
    // this.setOffsetWidth();

    const { data } = this.props;
    this.createVisualization(data.nodes);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setOffsetWidth);
  }

  setOffsetWidth = () => {
    if (!this.chartWrapperDiv.current) {
      return null;
    }

    const { offsetWidth } = this.chartWrapperDiv.current;

    return this.setState({
      width: offsetWidth,
      radius: Math.min(offsetWidth, offsetWidth) / 2,
    });
  };

  handleOnHover = (d: { value: number, data: any, ancestors: any }) => {
    this.setState({
      name: d.data.name,
      selectedSize: d.value,
    });

    const sequenceArray = d.ancestors().reverse();
    sequenceArray.shift(); // remove root node from the array

    select(this.chartContainer.current)
      .selectAll('path')
      .style('opacity', 0.3);

    // Then highlight only those that are an ancestor of the current segment.
    select(this.chartContainer.current)
      .selectAll('path')
      .filter(node => sequenceArray.indexOf(node) >= 0)
      .style('opacity', 1);
  };

  handleOnContainerHover = () => {
    select(this.chartContainer.current)
      .selectAll('path')
      .style('opacity', 1);

    this.setState({
      name: '',
      selectedSize: 0,
    });
  };

  createVisualization = (data: any) => {
    // Turn the data into a d3 hierarchy and calculate the sums.
    const root = hierarchy(data)
      .sum(d => d.size)
      .sort((a, b) => b.value - a.value);

    // For efficiency, filter nodes to keep only those large enough to see.
    const nodes = this.partitionFn(root)
      .descendants()
      .filter(d => d.x1 - d.x0 > 0.005); // 0.005 radians = 0.29 degrees

    const path = select(this.chartContainer.current)
      .data([data])
      .selectAll('path')
      .data(nodes)
      .enter()
      .append('svg:path')
      .attr('display', d => (d.depth ? null : 'none'))
      .attr('d', this.arcFn)
      .attr('fill-rule', 'evenodd')
      .attr('class', styles.slice)
      .style(
        'fill',
        d =>
          d.parent
            ? `${colorScale(d.ancestors().reverse()[1].data.name)}${
                alpha[d.depth]
              }`
            : 'white'
      )
      .on('mouseover', this.handleOnHover);

    // Get total size of the tree = value of root node from partition.
    this.setState({
      totalSize: path.datum().value,
    });
  };

  partitionFn = partition().size([
    2 * Math.PI,
    this.state.radius * this.state.radius,
  ]);

  arcFn = arc()
    .startAngle(d => d.x0)
    .endAngle(d => d.x1)
    .innerRadius(d => Math.sqrt(d.y0))
    .outerRadius(d => Math.sqrt(d.y1));

  chartContainer = React.createRef();
  chartWrapperDiv = React.createRef();

  render() {
    const { radius, width, height, name, selectedSize, totalSize } = this.state;
    const svgDimensions = { width, height };
    const { data } = this.props;

    const dataSetKeys =
      typeof data.nodes.children === 'object'
        ? data.nodes.children.map(child => child.name)
        : [];

    return (
      <div className={styles.container} ref={this.chartWrapperDiv}>
        <svg width={svgDimensions.width} height={svgDimensions.height}>
          <g
            onMouseLeave={this.handleOnContainerHover}
            transform={`translate(${width / 2},${height / 2})`}
            ref={this.chartContainer}
          >
            <circle r={radius} style={{ opacity: 0 }} />
          </g>
          <g
            transform={`translate(${svgDimensions.width /
              2}, ${(svgDimensions.height - MARGINS.top) / 2})`}
          >
            <text
              className={classNames([styles.circleText, styles.circleTextTop])}
            >
              {name || 'Total'}
            </text>
            <text className={styles.totalSumText}>
              {selectedSize
                ? `${selectedSize.toFixed(0)}`
                : `${totalSize.toFixed(0)}`}
            </text>
            <text
              className={classNames([
                styles.circleText,
                styles.circleTextBottom,
              ])}
            >
              kWh
            </text>
          </g>
        </svg>
        <ChartLegend items={dataSetKeys} colorScale={colorScale} />
      </div>
    );
  }
}

export default SunBurst;

/*

// Restore everything to full opacity when moving off the visualization.
function mouseleave(d) {
  // Hide the breadcrumb trail
  select('#trail').style('visibility', 'hidden');

  // Deactivate all segments during transition.
  selectAll('path').on('mouseover', null);

  // Transition each segment to full opacity and then reactivate it.
  selectAll('path')
    .transition()
    .duration(1000)
    .style('opacity', 1)
    .on('end', function() {
      select(this).on('mouseover', mouseover);
    });

  select('#explanation').style('visibility', 'hidden');
}

function initializeBreadcrumbTrail() {
  // Add the svg area.
  const trail = select('#sequence')
    .append('svg:svg')
    .attr('width', width)
    .attr('height', 50)
    .attr('id', 'trail');
  // Add the label at the end, for the percentage.
  trail
    .append('svg:text')
    .attr('id', 'endlabel')
    .style('fill', '#000');
}

// Generate a string that describes the points of a breadcrumb polygon.
function breadcrumbPoints(d, i) {
  const points = [];
  points.push('0,0');
  points.push(`${b.w},0`);
  points.push(`${b.w + b.t},${b.h / 2}`);
  points.push(`${b.w},${b.h}`);
  points.push(`0,${b.h}`);

  if (i > 0) {
    // Leftmost breadcrumb; don't include 6th vertex.
    points.push(`${b.t},${b.h / 2}`);
  }
  return points.join(' ');
}

// Update the breadcrumb trail to show the current sequence and percentage.
function updateBreadcrumbs(nodeArray, percentageString) {
  // Data join; key function combines name and depth (= position in sequence).
  const trail = select('#trail')
    .selectAll('g')
    .data(nodeArray, d => d.data.name + d.depth);

  // Remove exiting nodes.
  trail.exit().remove();

  // Add breadcrumb and label for entering nodes.
  const entering = trail.enter().append('svg:g');

  entering
    .append('svg:polygon')
    .attr('points', breadcrumbPoints)
    .style('fill', d => getColor(d.data));

  entering
    .append('svg:text')
    .attr('x', (b.w + b.t) / 2)
    .attr('y', b.h / 2)
    .attr('dy', '0.35em')
    .attr('text-anchor', 'middle')
    .text(d => d.data);

  // Merge enter and update selections; set position for all nodes.
  entering
    .merge(trail)
    .attr('transform', (d, i) => 'translate(' + i * (b.w + b.s) + ', 0)');

  // Now move and update the percentage at the end.
  select('#trail')
    .select('#endlabel')
    .attr('x', (nodeArray.length + 0.5) * (b.w + b.s))
    .attr('y', b.h / 2)
    .attr('dy', '0.35em')
    .attr('text-anchor', 'middle')
    .text(percentageString);

  // Make the breadcrumb trail visible, if it's hidden.
  select('#trail').style('visibility', '');
}

function drawLegend() {
  // Dimensions of legend item: width, height, spacing, radius of rounded rect.
  const li = {
    w: 75,
    h: 30,
    s: 3,
    r: 3,
  };

  const legend = select('#legend')
    .append('svg:svg')
    .attr('width', li.w)
    .attr('height', keys(colors).length * (li.h + li.s));

  const g = legend
    .selectAll('g')
    .data(entries(colors))
    .enter()
    .append('svg:g')
    .attr('transform', (d, i) => `translate(0,${i * (li.h + li.s)})`);

  g.append('svg:rect')
    .attr('rx', li.r)
    .attr('ry', li.r)
    .attr('width', li.w)
    .attr('height', li.h)
    .style('fill', d => d.value);

  g.append('svg:text')
    .attr('x', li.w / 2)
    .attr('y', li.h / 2)
    .attr('dy', '0.35em')
    .attr('text-anchor', 'middle')
    .text(d => d.key);
}

function toggleLegend() {
  const legend = select('#legend');
  if (legend.style('visibility') === 'hidden') {
    legend.style('visibility', '');
  } else {
    legend.style('visibility', 'hidden');
  }
}

*/
