// @flow
import * as React from 'react';
import IconUiFactory from '../icons/IconUiFactory';

import styles from './SpaceBreadCrumbs.module.css';
import Tooltip from '../Tooltip';
import type { Space } from '../../types/space';

type Props = {
  collection: Space[],
  fullPath?: boolean,
};

const deriveTitleFromEntityType = (e: Space) => e.title;

const SpaceBreadCrumbs = ({ collection = [], fullPath }: Props) => {
  if (collection.length > 2) {
    return fullPath ? (
      <Tooltip
        arrowDirection="top"
        content={<SpaceBreadCrumbs fullPath collection={collection} />}
      >
        <div className={styles.wrapper}>
          {collection.slice(0, collection.length - 1).map(item => (
            <span key={item.id}>
              <span>{deriveTitleFromEntityType(item)} </span>
              <IconUiFactory size={8} id="chevronRightTiny" /> : null
            </span>
          ))}
          <span>
            {' '}
            {deriveTitleFromEntityType(collection[collection.length - 1])}
          </span>
        </div>
      </Tooltip>
    ) : (
      <div className={styles.wrapper}>
        <span>{deriveTitleFromEntityType(collection[0])} </span>

        <span className={styles.condensedText}>
          <IconUiFactory size={8} id="chevronRightTiny" />
          <span className={styles.dots}>...</span>
          <IconUiFactory size={8} id="chevronRightTiny" />
        </span>
        <span>
          {' '}
          {deriveTitleFromEntityType(collection[collection.length - 1])}
        </span>
      </div>
    );
  } else if (collection.length === 2) {
    return (
      <div className={styles.wrapper}>
        <span> {deriveTitleFromEntityType(collection[0])} </span>
        <span className={styles.condensedText}>
          <IconUiFactory size={8} id="chevronRightTiny" />
        </span>
        <span> {deriveTitleFromEntityType(collection[1])}</span>
      </div>
    );
  }

  return collection.map(parent => (
    <span className={styles.wrapper} key={`SpaceBreadCrumbs${parent.id}`}>
      {deriveTitleFromEntityType(parent)}
    </span>
  ));
};

export default SpaceBreadCrumbs;
