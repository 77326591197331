// @flow

import fetchApi from '../middleware/api';
import { services } from '../config';
import type { Sentinel, SentinelsApiFilter } from '../types/sentinel';

const service = services.sentinel;

/*
todo: dispatch actions
export const SENTINEL_REQUEST = 'SENTINEL_REQUEST';
export const SENTINEL_SUCCESS = 'SENTINEL_SUCCESS';
export const SENTINEL_FAILURE = 'SENTINEL_FAILURE';
*/

export const fetchSentinels = (filter?: SentinelsApiFilter) => {
  const queryParams = new URLSearchParams();

  if (filter && filter.equipmentIds) {
    queryParams.set('equipmentIds', filter.equipmentIds.toString());
  }

  if (filter && filter.siteIds) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.spaceIds) {
    queryParams.set('spaceIds', filter.spaceIds.join(','));
  }

  return fetchApi({
    endpoint: `/api/v1/sentinels/?${queryParams.toString()}`,
    method: 'GET',
    service,
  });
};

/*
Todo: dispatch actions
export const SENTINEL_CREATE_REQUEST = 'SENTINEL_CREATE_REQUEST';
export const SENTINEL_CREATE_SUCCESS = 'SENTINEL_CREATE_SUCCESS';
export const SENTINEL_CREATE_FAILURE = 'SENTINEL_CREATE_FAILURE';
*/

export const fetchSentinelsCreate = (payload: Sentinel): Promise<any> =>
  fetchApi({
    endpoint: payload.id
      ? `/api/v1/sentinels/${payload.id}`
      : `/api/v1/sentinels`,
    method: payload.id ? 'PUT' : 'POST',
    service,
    payload,
  });

export const deleteSentinel = (sentinelId: string): Promise<any> =>
  fetchApi({
    endpoint: `/api/v1/sentinels/${sentinelId}`,
    method: 'DELETE',
    service,
  });

export const muteSentinel = (
  sentinelId: string,
  reason: string
): Promise<any> =>
  fetchApi({
    endpoint: `/api/v1/sentinels/${sentinelId}/mute`,
    method: 'POST',
    service,
    payload: {
      reason,
    },
  });

export const resumeSentinel = (sentinelId: string): Promise<any> =>
  fetchApi({
    endpoint: `/api/v1/sentinels/${sentinelId}/mute`,
    method: 'DELETE',
    service,
  });

export const getSentinelById = (sentinelId: string): Promise<any> =>
  fetchApi({
    endpoint: `/api/v1/sentinels/${sentinelId}`,
    method: 'GET',
    service,
  });
