// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import { deriveDateValueRaw } from '../../../utils/dates';

import styles from './ValueChip.module.css';
import temperature from '../../../utils/temperature';
import { sentinelTypes } from '../../../types/sentinel';
import { getOperationalValue, sensorType } from '../../../types/sensors';
import {
  fetchOperationalSamples,
  getLastSample,
} from '../../../actions/samples';
import type { MeasurementSystem } from '../../../types/shared';

type Props = {
  sensorId: number,
  updatedAt: string,
  value: number | string | boolean,
  secondaryText?: string,
  measurementSystem: MeasurementSystem,
  type: string,
};

type State = {
  value?: any,
  isLoading: boolean,
};

const createTextFromValueAndType = (
  value: any,
  type: string,
  measurementSystem: MeasurementSystem
) => {
  const valueArray = [value];

  try {
    switch (type) {
      case 'sentinel_type_electricity': {
        valueArray.push('kWh');
        break;
      }

      case sentinelTypes.DOOR:
        break; // TODO: What should we display here?

      case sensorType.TEMPERATURE:
      case sentinelTypes.TEMPERATURE: {
        const temperatureValue = parseFloat(valueArray[0]);
        if (measurementSystem === 'IMPERIAL') {
          valueArray[0] = temperature(temperatureValue, 'c', 0);
          valueArray.push('˚F');
        } else {
          valueArray[0] = temperatureValue.toFixed(0);
          valueArray.push('˚C');
        }
        break;
      }

      case sensorType.HUMIDITY: {
        valueArray.push('%');
        break;
      }
      default: {
        break;
      }
    }
  } catch (e) {
    // log error
  }
  return <div> {valueArray.join(' ')} </div>;
};

class ValueChip extends React.Component<Props, State> {
  state = {
    value: undefined,
    isLoading: false,
  };

  componentDidMount() {
    const { sensorId, updatedAt, type, value } = this.props;

    if (value) {
      return;
    }

    if (updatedAt === '') {
      return;
    }

    if (!sensorId) {
      return;
    }

    this.setState({ isLoading: true });

    const from = deriveDateValueRaw(updatedAt);

    const queryParams = `filter[sensor]=${sensorId.toString()}&filter[from]=${from.unix() -
      3600}&filter[to]=${from.unix() + 3600}`;

    fetchOperationalSamples(queryParams)
      .then(r => {
        const sample = getLastSample(r);

        this.setState({
          value: getOperationalValue(type, sample),
          isLoading: false,
        });
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  render() {
    const { type, secondaryText, measurementSystem } = this.props;
    const { isLoading } = this.state;

    const value = this.props.value || this.state.value;

    if (isLoading) {
      return '..load';
    }

    if (!value) {
      return <span>n/a</span>;
    }

    return (
      <span className={styles.sensorIconAndTextGrouping}>
        <span className={styles.sensorTypeText}>
          {createTextFromValueAndType(value, type, measurementSystem)}
          {secondaryText && (
            <div className={styles.secondaryText}> {secondaryText}</div>
          )}
        </span>
      </span>
    );
  }
}

const mapStateToProps = state => ({
  measurementSystem: state.app.measurementSystem,
});

export default connect(
  mapStateToProps,
  null
)(ValueChip);
