// @flow

import * as React from 'react';
import InputRange from '../../../ui/InputRange';
import DropDown from '../../../DropDown';
import type { ParametersTemperature } from '../../../../types/sentinel';
import {
  comparisonTypes,
  defaultTemperatureParams,
} from '../../../../types/sentinel';
import styles from './ConditionTemperature.module.css';
import { UiChip } from '../../../Tables/Cells';
import { toFahrenheit } from '../../../../utils/temperature';
import Radio from '../../../ui/Radio';

type Props = {
  onChange: (string, string) => void,
  parameters?: ParametersTemperature,
};

type Unit = 'f' | 'c';

type State = {
  unit: Unit,
};

const sortItems = [{ text: '° F', value: 'f' }, { text: '° C', value: 'c' }];

const generateInfoBox = comparisonType => {
  if (comparisonType === comparisonTypes.gt) {
    return 'The notifications will be triggered when the temperature falls below the defined limit.';
  } else if (comparisonType === comparisonTypes.eq) {
    return 'The notifications will be triggered when the temperature varies from the defined value.';
  }
  return 'The notifications will be triggered when the temperature raises above the defined limit.';
};

class ConditionTemperature extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      unit: 'f',
    };
  }

  onUnitChange = (unit: Unit) => this.setState({ unit });

  render() {
    const { unit } = this.state;
    const { onChange, parameters = defaultTemperatureParams } = this.props;

    return (
      <React.Fragment>
        <div className={styles.stepLeft}>
          <form>
            <InputRange
              onChange={onChange}
              valueFormatter={value =>
                unit === 'f' ? +toFahrenheit(value).toFixed(0) : value
              }
              name="threshold"
              width="100%"
              min={-100}
              max={100}
              value={+parameters.threshold}
              condition={parameters.comparisonType}
            />
            <div className={styles.checkboxWrapper}>
              <Radio
                id="condition_less_then"
                type="radio"
                checked={parameters.comparisonType === comparisonTypes.lt}
                onChange={() => onChange('comparisonType', comparisonTypes.lt)}
                label="Less then"
              />
              <Radio
                id="condition_equals"
                type="radio"
                checked={parameters.comparisonType === comparisonTypes.eq}
                onChange={() => onChange('comparisonType', comparisonTypes.eq)}
                label="Exact"
              />
              <Radio
                id="condition_greater"
                type="radio"
                checked={parameters.comparisonType === comparisonTypes.gt}
                onChange={() => onChange('comparisonType', comparisonTypes.gt)}
                label="Greater then"
              />
            </div>
            <div className={styles.bottomConditionsWrapper}>
              <div className={styles.conditionScale}>
                <div className={styles.conditionLabel}>Scale:</div>
                <DropDown onChange={this.onUnitChange} sortItems={sortItems} />
              </div>
              <div className={styles.conditionReaction}>
                <span className={styles.conditionLabel}>Reaction time: </span>
                <span>XXX minutes</span>
              </div>
            </div>
          </form>
        </div>
        <div className={styles.stepRight}>
          <div className={styles.helpText}>
            <UiChip
              customIconColor={styles.infoIcon}
              classes={styles.infoIcon}
              label={generateInfoBox(parameters.comparisonType)}
              iconId="info"
            />
          </div>
          <div className={styles.helpText}>
            <UiChip
              customIconColor={styles.infoIcon}
              classes={styles.infoIcon}
              label="Specify the time after which the first notification will be generated."
              iconId="info"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ConditionTemperature;
