// @flow
import React from 'react';
import styles from './StatusChip.module.css';
import className from '../../../utils/classNames';

type Props = {
  status: 'online' | 'offline' | 'buffer',
  timeSince?: string,
  text: string,
};

export default ({ status = 'online', text, timeSince }: Props) => (
  <div className={className([styles[status], styles.flex])}>
    <div>{text}</div>
    {timeSince && <div>{timeSince} </div>}
  </div>
);
