// @flow

import * as React from 'react';
import styles from './StepTypes.module.css';
import classNames from '../../../utils/classNames';
import IconSensorTypeFactory from '../../icons/IconSensorTypeFactory';
import { sentinelTypesList } from '../../../types/sentinel';
import type { SentinelType } from '../../../types/sentinel';
import { getSentinelDescription } from '../../../constants/sentinels';

type StepSensorTypeCardProps = {
  onChange: SentinelType => void,
  sentinelType: SentinelType,
  sentinel: any,
};

const StepSensorTypeCard = ({
  sentinel,
  sentinelType,
  onChange,
}: StepSensorTypeCardProps) => (
  <div
    key={sentinel.type}
    onClick={() => onChange(sentinel.type)}
    onKeyPress={() => onChange(sentinel.type)}
    className={classNames(
      styles.card,
      sentinelType === sentinel.type ? styles.cardSelected : undefined
    )}
    role="button"
    tabIndex="0"
  >
    <div className={styles.iconWrapper}>
      {IconSensorTypeFactory({ id: sentinel.type, size: 80 })}
    </div>
    <h3 className={styles.cardTitle}>{sentinel.title}</h3>
    <p className={styles.cardDescription}>
      {getSentinelDescription(sentinel.type)}
    </p>
  </div>
);

type StepTypeProps = {
  onChange: string => void,
  sentinelType: SentinelType,
};

const StepType = ({ onChange, sentinelType }: StepTypeProps) => (
  <div className={styles.root}>
    {sentinelTypesList.map(s => (
      <StepSensorTypeCard
        key={`StepType${s.type}`}
        sentinelType={sentinelType}
        sentinel={s}
        onChange={onChange}
      />
    ))}
  </div>
);

export default StepType;
