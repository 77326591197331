// @flow

import * as React from 'react';
import styles from './FilterMenuFooter.module.css';

type Props = {
  children: React.Node,
};

const FilterMenuFooter = ({ children }: Props) => (
  <div className={styles.root}>{children}</div>
);

export default FilterMenuFooter;
