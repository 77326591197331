// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from '../../utils/classNames';

const tabStyles = theme => ({
  root: {
    ...theme.typography.body,
    fontSize: theme.typography.fontSize,
    outline: 'none',
    userSelect: 'none',
    height: '3.4375rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '1.375rem',
    paddingRight: '1.375rem',
    color: theme.palette.ambient.main,
    border: 'transparent',
    borderBottom: `5px solid transparent`,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '-webkit-appearance': 'none',
    '&:hover': {
      color: theme.palette.accent.main,
    },
  },
  disabled: {
    color: theme.palette.ambient.basePlus75,
    '&:hover': {
      color: theme.palette.ambient.basePlus75,
      cursor: 'auto',
    },
  },
  withIcon: {
    marginLeft: '0.9375rem',
  },
  isActive: {
    color: theme.palette.accent.contrastText,
    backgroundColor: theme.palette.accent.main,
    borderBottom: `5px solid ${theme.palette.accent.baseMinus25}`,
    '&:hover': {
      color: theme.palette.accent.contrastText,
    },
  },
});

type Props = {
  classes: any,
  icon?: React.Node,
  label: string,
  isActive?: boolean,
  disabled?: boolean,
  onClick: Function,
};

const BasicTab = ({
  classes,
  onClick,
  label,
  isActive,
  disabled = false,
  icon,
}: Props) => (
  <button
    className={classNames([
      classes.root,
      isActive ? classes.isActive : undefined,
      disabled ? classes.disabled : undefined,
    ])}
    role="tab"
    onClick={() => onClick()}
    disabled={disabled}
  >
    {icon} <span className={icon ? classes.withIcon : undefined}>{label}</span>
  </button>
);

const TabWithStyles = withStyles(tabStyles)(BasicTab);

const tabsStyles = () => ({
  root: {
    display: 'flex',
  },
});

const Tabs = ({
  children,
  classes,
}: {
  children: React.Node,
  classes: any,
}) => (
  <div role="tablist" className={classes.root}>
    {children}
  </div>
);

export { TabWithStyles as Tab, BasicTab };

export default withStyles(tabsStyles)(Tabs);
