// @flow

import { store } from '../../index';
import convertTemp from '../../utils/temperature';
import { measurementSystem } from '../../types/shared';

export default function formatTemperature(
  value: number = 0,
  decimals: number = 0
) {
  const ms = store.getState().app.measurementSystem;

  return ms === measurementSystem.imperial
    ? `${convertTemp(value, 'c', decimals)} °F`
    : `${value.toFixed(decimals)} °C`;
}
