// @flow

import dayjs from 'dayjs';
import addYears from 'date-fns/addYears';
import addMonths from 'date-fns/addMonths';
import addDays from 'date-fns/addDays';
import addHours from 'date-fns/addHours';
import addMinutes from 'date-fns/addMinutes';
import getTime from 'date-fns/getTime';

type SensorValuePair = { sensor_id: string, value: number };

type Sample = {
  timestamp: number,
  gatewayId: string,
  aggregation: {
    energy: number,
  },
  energy: [SensorValuePair],
};

type Samples = Sample[];

export function reduceSensorValue(s: [SensorValuePair]): number {
  return s.reduce((sum, sensor) => sum + sensor.value, 0);
}

const getIntervalFunction = (aggregationLevel: string): Function | string => {
  switch (aggregationLevel) {
    case 'years_1':
      return addYears;
    case 'months_1':
      return addMonths;
    case 'days_1':
      return addDays;
    case 'hours_1':
      return addHours;
    case 'minutes_15':
      return i => addMinutes(i, 15);
    case 'minutes_1':
      return addMinutes;
    case 'none':
      return i => i + 2;
    default:
      return `Could not resolve aggregation level: ${aggregationLevel}`;
  }
};

export function generateEmptyInterval(
  timeFrom: number,
  timeTo: number,
  aggregationLevel: string
): number[] {
  const intervalFn = getIntervalFunction(aggregationLevel);

  if (typeof intervalFn !== 'function') {
    // todo: throw error?
    return [];
  }

  const d = [];
  const getNextMonth = (i: number): Date => intervalFn(i, 1);
  const dateAsUnix = (d: Date): number => Math.round(getTime(d));

  for (let i = timeFrom * 1000; i < timeTo * 1000; ) {
    d.push(i);
    i = dateAsUnix(getNextMonth(i));
  }

  return d;
}

export function transformSamplesToMonths(
  energyTypeKey: string,
  samples: Samples = [],
  queryParameters: {
    timeFrom: number,
    timeTo: number,
    aggregation: string,
  }
) {
  const interval = generateEmptyInterval(
    queryParameters.timeFrom,
    queryParameters.timeTo,
    queryParameters.aggregation
  );

  const data = interval.reduce((obj, next) => {
    const date = dayjs(next);
    const month = date.format('MMM');
    const year = date.format('YYYY');

    if (!obj[month]) {
      obj[month] = {}; // eslint-disable-line no-param-reassign
    }

    if (!obj[month][year]) {
      obj[month][year] = null; // eslint-disable-line no-param-reassign
    }

    return obj;
  }, {});

  samples.forEach(next => {
    const date = dayjs(next.timestamp * 1000);
    const month = date.format('MMM');
    const year = date.format('YYYY');
    data[month][year] = next.aggregation[energyTypeKey];
  });

  return data;
}

export function transformSamplesToDays(
  energyTypeKey: string,
  samples: Samples = [],
  queryParameters: {
    timeFrom: number,
    timeTo: number,
    aggregation: string,
  }
) {
  const interval = generateEmptyInterval(
    queryParameters.timeFrom,
    queryParameters.timeTo,
    queryParameters.aggregation
  );

  const data = interval.reduce((obj, next) => {
    const date = dayjs(next);
    const day = date.format('D');
    const month = date.format('MMM');

    if (!obj[day]) {
      obj[day] = {}; // eslint-disable-line no-param-reassign
    }

    if (!obj[day][month]) {
      obj[day][month] = null; // eslint-disable-line no-param-reassign
    }

    return obj;
  }, {});

  samples.forEach(next => {
    const date = dayjs(next.timestamp * 1000);
    const day = date.format('D');
    const month = date.format('MMM');
    data[day][month] = next.aggregation[energyTypeKey];
  });

  return data;
}

export function sumByEnergyType(
  energyTypeKey: string,
  samples: Samples = [],
  queryParameters?: { useFactor: boolean, factor: number }
): number {
  const s = samples
    .map((s: Sample) => s.aggregation[energyTypeKey])
    .reduce((total, value) => total + value, 0);

  if (queryParameters) {
    if (
      queryParameters.useFactor &&
      typeof queryParameters.factor === 'number'
    ) {
      return s * queryParameters.factor;
    }
  }

  return s;
}

export function averageByEnergyType(
  energyTypeKey: string,
  samples: Samples = []
): number {
  const interval = samples.length;
  const sum = sumByEnergyType(energyTypeKey, samples);

  if (!interval) {
    return 0;
  }

  if (!sum) {
    return 0;
  }

  return sum / interval;
}
