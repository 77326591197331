// @flow

import * as React from 'react';
import styles from './BaseList.module.css';
import classNames from '../../utils/classNames';

type Props = {
  children: React.Node,
  extraClassNames?: string[],
};

const BaseList = ({ children, extraClassNames = [] }: Props) => (
  <div className={classNames(styles.list, ...extraClassNames)}>{children}</div>
);

export default BaseList;
