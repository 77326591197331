// @flow

import * as React from 'react';
import { Router } from '@reach/router';
import ViewUsersList from './users/ViewUsersList';
import BaseContentContainer from '../layout/BaseContentContainer';

const ViewUsers = () => (
  <BaseContentContainer>
    <Router>
      <ViewUsersList path="/" />
    </Router>
  </BaseContentContainer>
);

export default ViewUsers;
