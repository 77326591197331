// @flow

import * as React from 'react';
import Loadable from 'react-loadable';
import CircularProgress from '@material-ui/core/CircularProgress';
import type { IconFactory } from '../../types/icon';

const Loading = ({ size = 40 }: { size: number }) => (
  <CircularProgress size={size} />
);

const IconClose = Loadable({
  loader: () => import('./ui/Close'),
  loading: Loading,
});

const IconChevronBreadCrumb = Loadable({
  loader: () => import('./ui/ChevronBreadCrumb'),
  loading: Loading,
});

const IconChevronRight = Loadable({
  loader: () => import('./ui/ChevronRight'),
  loading: Loading,
});

const IconChevronDown = Loadable({
  loader: () => import('./ui/ChevronDown'),
  loading: Loading,
});

const IconChevronUpTiny = Loadable({
  loader: () => import('./ui/ChevronUpTiny'),
  loading: Loading,
});

const IconChevronRightTiny = Loadable({
  loader: () => import('./ui/ChevronRightTiny'),
  loading: Loading,
});

const IconChevronDownTiny = Loadable({
  loader: () => import('./ui/ChevronDownTiny'),
  loading: Loading,
});

const IconChevronLeftTiny = Loadable({
  loader: () => import('./ui/ChevronLeftTiny'),
  loading: Loading,
});

const Check = Loadable({
  loader: () => import('./ui/Check'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('./ui/Dashboard'),
  loading: Loading,
});

const Notification = Loadable({
  loader: () => import('./ui/Notification'),
  loading: Loading,
});

const Site = Loadable({
  loader: () => import('./ui/Site'),
  loading: Loading,
});

const Equipment = Loadable({
  loader: () => import('./ui/Equipment'),
  loading: Loading,
});

const User = Loadable({
  loader: () => import('./ui/User'),
  loading: Loading,
});

const Trash = Loadable({
  loader: () => import('./ui/Trash'),
  loading: Loading,
});

const Info = Loadable({
  loader: () => import('./ui/Info'),
  loading: Loading,
});

const BellConfig = Loadable({
  loader: () => import('./ui/BellConfig'),
  loading: Loading,
});

const Globe = Loadable({
  loader: () => import('./ui/Globe'),
  loading: Loading,
});

const Location = Loadable({
  loader: () => import('./ui/Location'),
  loading: Loading,
});

const Mail = Loadable({
  loader: () => import('./ui/Mail'),
  loading: Loading,
});

const Mobile = Loadable({
  loader: () => import('./ui/Mobile'),
  loading: Loading,
});

const Logout = Loadable({
  loader: () => import('./ui/Logout'),
  loading: Loading,
});

const Search = Loadable({
  loader: () => import('./ui/Search'),
  loading: Loading,
});

const Offline = Loadable({
  loader: () => import('./ui/Offline'),
  loading: Loading,
});

const Logo = Loadable({
  loader: () => import('./ui/Logo'),
  loading: Loading,
});

const Ethernet = Loadable({
  loader: () => import('./ui/Ethernet'),
  loading: Loading,
});

export default ({ classes, size, id }: IconFactory) => {
  switch (id) {
    case 'ethernet':
      return <Ethernet classes={classes} size={size} />;
    case 'logo':
      return <Logo classes={classes} size={size} />;
    case 'offline':
      return <Offline classes={classes} size={size} />;
    case 'close':
      return <IconClose classes={classes} size={size} />;
    case 'dashboard':
      return <Dashboard classes={classes} size={size} />;
    case 'notification':
      return <Notification classes={classes} size={size} />;
    case 'check':
      return <Check classes={classes} size={size} />;
    case 'site':
      return <Site classes={classes} size={size} />;
    case 'equipment':
      return <Equipment classes={classes} size={size} />;
    case 'user':
      return <User classes={classes} size={size} />;
    case 'chevronBreadCrumb':
      return <IconChevronBreadCrumb classes={classes} size={size} />;
    case 'chevronDown':
      return <IconChevronDown classes={classes} size={size} />;
    case 'chevronRight':
      return <IconChevronRight classes={classes} size={size} />;
    case 'chevronLeft':
      return <IconChevronRight classes={classes} size={size} />;
    case 'chevronTopTiny':
      return <IconChevronUpTiny classes={classes} size={size} />;
    case 'chevronRightTiny':
      return <IconChevronRightTiny classes={classes} size={size} />;
    case 'chevronBottomTiny':
      return <IconChevronDownTiny classes={classes} size={size} />;
    case 'chevronLeftTiny':
      return <IconChevronLeftTiny classes={classes} size={size} />;
    case 'trash':
      return <Trash classes={classes} size={size} />;
    case 'info':
      return <Info classes={classes} size={size} />;
    case 'bell-config':
      return <BellConfig classes={classes} size={size} />;
    case 'globe':
      return <Globe classes={classes} size={size} />;
    case 'mobile':
      return <Mobile classes={classes} size={size} />;
    case 'mail':
      return <Mail classes={classes} size={size} />;
    case 'location':
      return <Location classes={classes} size={size} />;
    case 'logout':
      return <Logout classes={classes} size={size} />;
    case 'search':
      return <Search classes={classes} size={size} />;
    default:
      return null;
  }
};
