// @flow

import * as React from 'react';
import { Router } from '@reach/router';
import MenuTabBar from '../../MenuTabBar';
import Tabs from '../../Tabs/Tabs';
import ViewEquipmentMonitor from './ViewEquipmentMonitor';
import ViewEquipmentSensors from './ViewEquipmentSensors';
import ViewEquipmentSentinels from './ViewEquipmentSentinels';
// import ViewEquipmentNotifications from './ViewEquipmentNotifications';
import TabLink from '../../Tabs/TabLink';
import BaseContentContainer from '../../layout/BaseContentContainer';
import { fetchEquipmentById } from '../../../actions/equipment';
import type { Equipment } from '../../../types/equipment';
import withPageUtils from '../../../containers/views/withPageUtils';

type State = {
  equipment: ?Equipment,
  hasError: boolean,
};

type Props = {
  equipmentId?: number,
  setPageTitle: string => void,
  setPageType: string => void,
};

class ViewEquipmentIndex extends React.Component<Props, State> {
  state = {
    equipment: null,
    hasError: false,
  };

  async componentDidMount() {
    const { equipmentId } = this.props;

    if (!equipmentId) {
      return;
    }

    this.loadEquipment(equipmentId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.equipmentId !== prevProps.equipmentId) {
      if (!this.props.equipmentId) {
        return;
      }

      this.loadEquipment(this.props.equipmentId);
    }
  }

  componentWillUnmount() {
    this.props.setPageType('');
  }

  async loadEquipment(equipmentId: number) {
    try {
      const equipment = await fetchEquipmentById(equipmentId);
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ equipment });
      this.props.setPageTitle(equipment.title);
      this.props.setPageType('equipment');
    } catch (err) {
      this.setState({ hasError: true });
    }
  }

  render() {
    const { equipment, hasError } = this.state;

    if (hasError) {
      return <div>Error</div>;
    }

    return (
      <React.Fragment>
        <MenuTabBar>
          <Tabs>
            <TabLink to="monitor">Monitor</TabLink>
            <TabLink to="sensors">Sensors</TabLink>
            <TabLink to="notifications">Notifications</TabLink>
            {/* <TabLink to="notifications">Notifications</TabLink> */}
          </Tabs>
        </MenuTabBar>

        <BaseContentContainer>
          {equipment && equipment.id ? (
            <Router primary={false}>
              <ViewEquipmentSensors path="sensors" equipment={equipment} />
              <ViewEquipmentMonitor view="monitoring" path="monitor" equipment={equipment} />
              <ViewEquipmentSentinels
                path="notifications"
                equipment={equipment}
              />
              {/* <ViewEquipmentNotifications
                path="notifications"
                equipment={equipment}
              /> */}
            </Router>
          ) : null}
        </BaseContentContainer>
      </React.Fragment>
    );
  }
}

export default withPageUtils(ViewEquipmentIndex);
