// @flow

import * as React from 'react';

type Props = {
  classes: any,
  height: number,
  width: number,
};

export default ({ classes = {}, height = 40, width = 40 }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    className={classes.icon}
    width={height}
    height={width}
  >
    <g>
      <path
        style={{ fill: 'currentColor' }}
        d="M26.2056,17.8135H20.64a2.5022,2.5022,0,0,0-1.95-1.95v-7.55a.5.5,0,1,0-1,0v7.55a2.5,2.5,0,1,0,2.95,2.95h5.5653a.4851.4851,0,0,0,.4851-.485v-.03A.485.485,0,0,0,26.2056,17.8135Zm-8.0149,2a1.5,1.5,0,1,1,1.5-1.5A1.5017,1.5017,0,0,1,18.1907,19.8135Z"
      />
      <path
        style={{ fill: 'currentColor' }}
        d="M34.71,15.29a1.0078,1.0078,0,0,0-1.42,0l-.47.48A14.9929,14.9929,0,0,0,3,18a15.2647,15.2647,0,0,0,1.23,6.04,6.9944,6.9944,0,0,0,10.91,8.7A15.7654,15.7654,0,0,0,18,33c2.8,0,9.02-1.34,12.53-6.43a1.0028,1.0028,0,0,0-1.65-1.14C25.85,29.84,20.44,31,18,31a12.5155,12.5155,0,0,1-1.63-.1v-.01a6.985,6.985,0,0,0-10.6-8.45l-.01.01A13.2841,13.2841,0,0,1,5,18a12.9976,12.9976,0,0,1,25.71-2.71,1.0041,1.0041,0,0,0-1.42,1.42l2,2,.71.7.71-.7,2-2A1.0079,1.0079,0,0,0,34.71,15.29ZM8,31H6V27H8v4Zm4-1.42V25.75h2V31H12ZM9,24h2v7H9V24Z"
      />
    </g>
    <g style={{ opacity: '0.1' }}>
      <path
        style={{ fill: 'currentColor' }}
        d="M37.71,18.29a1.0078,1.0078,0,0,0-1.42,0l-.47.48A14.9929,14.9929,0,0,0,6,21a15.2647,15.2647,0,0,0,1.23,6.04,6.9944,6.9944,0,0,0,10.91,8.7A15.7654,15.7654,0,0,0,21,36c2.8,0,9.02-1.34,12.53-6.43a1.0028,1.0028,0,0,0-1.65-1.14C28.85,32.84,23.44,34,21,34a12.5155,12.5155,0,0,1-1.63-.1v-.01a6.985,6.985,0,0,0-10.6-8.45l-.01.01A13.2841,13.2841,0,0,1,8,21a12.9976,12.9976,0,0,1,25.71-2.71,1.0041,1.0041,0,0,0-1.42,1.42l2,2,.71.7.71-.7,2-2A1.0079,1.0079,0,0,0,37.71,18.29Z"
      />
      <path
        style={{ fill: 'currentColor' }}
        d="M21.1907,10.8135a.5.5,0,0,0-.5.5v7.55a2.5,2.5,0,1,0,2.95,2.95h5.5653a.4851.4851,0,0,0,.4851-.485v-.03a.485.485,0,0,0-.4851-.485H23.64a2.5022,2.5022,0,0,0-1.95-1.95v-7.55A.5.5,0,0,0,21.1907,10.8135Zm1.5,10.5a1.5,1.5,0,1,1-1.5-1.5A1.5017,1.5017,0,0,1,22.6907,21.3135Z"
      />
    </g>
  </svg>
);
