// @flow

import * as React from 'react';
import type { NotificationIcon } from '../../../types/icon';

const IconSize = () => (
  <g>
    <path
      d="M3.5 5c-.3 0-.5.2-.5.5v3c0 .3.2.5.5.5s.5-.2.5-.5v-3c0-.3-.2-.5-.5-.5zM.5 15c-.1 0-.3-.1-.4-.2-.2-.2-.2-.5 0-.7l14-13c.2-.2.5-.2.7 0s.2.5 0 .7l-14 13c-.1.2-.2.2-.3.2z"
      fill="currentColor"
    />
    <path
      d="M4 5.4c-.4.4-1 .6-1.6.6H1v4h1.4c.6 0 1.2.2 1.6.6l.6.4L9 6.9V1.3L4 5.4z"
      fill="none"
    />
    <path
      d="M4 10.6c-.4-.4-1-.6-1.6-.6H1V6h1.4C3 6 3.6 5.8 4 5.4l5-4.1v5.6l1-.9V.7c0-.4-.4-.7-.7-.7-.2 0-.3 0-.5.2L3.4 4.6c-.3.3-.7.4-1 .4H1c-.6 0-1 .4-1 1v4c0 .6.4 1 1 1h1.4c.4 0 .7.1 1 .4l.4.4.8-.8-.6-.4zM9 14.7L5.7 12l-.7.7 3.8 3.2c.2.1.3.1.5.1.4 0 .7-.3.7-.7V8l-1 .9v5.8z"
      fill="currentColor"
    />
  </g>
);

const getIconBySize = (size: number) => {
  switch (size) {
    default:
      return { viewBox: '0 0 16 16', icon: <IconSize /> };
  }
};
// TODO THIS IS A PLACE HOLDER FOR THE REAL ICON LOGIC

export default ({ classes = {}, size = 20 }: NotificationIcon) => {
  const { viewBox, icon } = getIconBySize(size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={classes.icon}
      width={size}
      height={size}
    >
      {icon}
    </svg>
  );
};
