// @flow

import * as React from 'react';
import { scaleLinear, scaleBand, scaleOrdinal } from 'd3-scale';
import { max } from 'd3-array';

import './BarChart.css';
import Axes from './Axes/Axes';
import Bars from './Bars/Bars';
import ToolTip from './ToolTip/ToolTip';
import ChartLegend from './ChartLegend';

const MARGINS = { top: 40, right: 0, bottom: 40, left: 20 };

const DEFAULT_Y_AXIS_VALUE = 1;

type Props = {
  data: Object,
  className: string,
  isLoading: boolean,
};

type State = {
  width: number,
  hoveredBar: any,
};

export default class Chart extends React.Component<Props, State> {
  state = {
    width: 400,
    hoveredBar: null,
  };

  componentDidMount() {
    window.addEventListener('resize', this.setOffsetWidth);
    this.setOffsetWidth();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setOffsetWidth);
  }

  setOffsetWidth = () => {
    if (!this.chartWrapperDiv.current) {
      return null;
    }

    return this.setState({
      width: this.chartWrapperDiv.current.offsetWidth,
    });
  };

  x0Scale = scaleBand();
  x1Scale = scaleBand();
  yScale = scaleLinear();

  chartWrapperDiv = React.createRef();

  render() {
    const { data } = this.props;
    const svgDimensions = { width: this.state.width, height: 200 };
    const colorScale = scaleOrdinal().range(['#3FBCF4', '#77EB85']);
    const dataKeys = Object.keys(data);
    const dataSetKeys = Object.keys(data[Object.keys(data)[0]]);
    const dataCopy = Object.entries(data).map(([k, d]: [string, any]) => ({
      Month: k,
      years: dataSetKeys.map(name => ({ name, value: d[name] || 0 })),
    }));

    const x0Scale = this.x0Scale
      .rangeRound([0, svgDimensions.width - MARGINS.left - MARGINS.right])
      .paddingInner(0.4)
      .paddingOuter(0.7)
      .domain(dataKeys); // array

    const x1Scale = this.x1Scale
      .domain(dataSetKeys)
      .rangeRound([0, x0Scale.bandwidth()]);

    const yScale = this.yScale
      .range([svgDimensions.height - MARGINS.top - MARGINS.bottom, 0])
      .domain([
        0,
        max(Object.values(data), d =>
          max(Object.values(d), d => d || DEFAULT_Y_AXIS_VALUE)
        ),
      ]);

    if (!dataSetKeys.length) {
      return <div>NO DATA</div>;
    }

    return (
      <div ref={this.chartWrapperDiv} style={{ position: 'relative' }}>
        <svg
          className={this.props.className}
          width={svgDimensions.width}
          height={svgDimensions.height}
        >
          <Axes
            scales={{ x0Scale, yScale }}
            margins={MARGINS}
            svgDimensions={svgDimensions}
          />
          <Bars
            data={data}
            oldData={dataCopy}
            scales={{ x0Scale, x1Scale, yScale }}
            margins={MARGINS}
            svgDimensions={svgDimensions}
            onMouseOverCallback={bar => this.setState({ hoveredBar: bar })}
            onMouseLeaveCallback={() => this.setState({ hoveredBar: null })}
          />
        </svg>
        <ToolTip
          scales={{ x0Scale, yScale }}
          hoveredBar={this.state.hoveredBar}
        />
        <ChartLegend items={dataSetKeys} colorScale={colorScale} />
      </div>
    );
  }
}
