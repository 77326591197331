// @flow

import { connect } from 'react-redux';
import Header from '../../components/Header';

const mapStateToProps = state => ({
  pageInfo: state.pageInfo,
  pageType: state.pageType,
  sites: state.sites,
  equipment: state.equipment,
});

export default connect(
  mapStateToProps,
  null
)(Header);
