// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';

type Props = {
  classes: any,
  children: React.Node,
};

const styles = () => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 12.5rem)', // minmax(11.25rem, 1fr) is nice as well
    gridGap: '2.5rem',
    marginBottom: '2.5rem',
  },
});

const FeatureMonitorCircleList = ({ classes, children }: Props) => (
  <div className={classes.root}>{children}</div>
);

export default withStyles(styles)(FeatureMonitorCircleList);
