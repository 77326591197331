// @flow

import * as React from 'react';
import styles from './Tooltip.module.css';
import className from '../utils/classNames';

type Props = {
  content: any,
  children: any,
  arrowDirection?: 'top' | 'left' | 'bottom' | 'right',
  extraClassNames?: string[],
  hoverOverride?: boolean,
};

// Wraps around a component and appends a tooltip to said component with custom content
const Tooltip = ({
  content,
  extraClassNames = [],
  children,
  arrowDirection = 'left',
  hoverOverride = false,
}: Props) => (
  <div
    className={className([
      styles.tooltip,
      styles[arrowDirection],
      hoverOverride ? styles.hoverOverride : '',
      ...extraClassNames,
    ])}
  >
    <div className={styles.tooltipComment}>{children}</div>
    {content && (
      <span className={className(styles.tooltipText, styles[arrowDirection])}>
        {content}
      </span>
    )}
  </div>
);

export default Tooltip;
