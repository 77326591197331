// @flow

import fetchApi from '../middleware/api';
import type { Site, SitesApiFilter } from '../types/site';
import { store } from '../index';

import type { SensorType } from '../types/sensors';

export const SITES_REQUEST = 'SITES_REQUEST ';
export const SITES_SUCCESS = 'SITES_SUCCESS ';
export const SITES_FAILURE = 'SITES_FAILURE ';

export const fetchSites = (filter?: SitesApiFilter): Promise<Site[]> => {
  const queryParams = new URLSearchParams();

  if (filter && filter.equipmentIds) {
    queryParams.set('equipmentIds', filter.equipmentIds.join(','));
  }

  if (filter && filter.sensorTypes) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  if (filter && filter.sensorTarget) {
    queryParams.set('sensorTarget', filter.sensorTarget.join(','));
  }

  return fetchApi({ endpoint: `/api/v1/sites?${queryParams.toString()}` });
};

export const fetchSitesByUserOrganizationAndSensorType = (
  sensorType: SensorType
): Promise<Site[]> => {
  const { organizationId } = store.getState().app.user;

  return fetchApi({
    endpoint: `/api/v1/sites/organization/${organizationId.toString()}/sensors?sensorTypes=${sensorType}`,
  });
};

export const getSites = () => async (dispatch: any => any): Promise<any> => {
  dispatch({ type: SITES_REQUEST });
  try {
    const sites = await fetchSites();

    await dispatch({ type: SITES_SUCCESS, payload: sites });
    return sites;
  } catch (err) {
    dispatch({ type: SITES_FAILURE });
    return Promise.reject(new Error(err));
  }
};

export const SITE_REQUEST = 'SITE_REQUEST ';
export const SITE_SUCCESS = 'SITE_SUCCESS ';
export const SITE_FAILURE = 'SITE_FAILURE ';

export const fetchSiteById = (siteId: string | number): Promise<Site> =>
  fetchApi({ endpoint: `/api/v1/sites/${siteId}` }).catch(err => err);

export const getSiteById = (siteId: string) => async (
  dispatch: Function
): Promise<{ [key: string]: Site }> => {
  dispatch({ type: SITE_REQUEST });

  try {
    const site = await fetchApi({ endpoint: `/api/v1/sites/${siteId}` });
    dispatch({ type: SITE_SUCCESS, payload: site });
    return site;
  } catch (err) {
    dispatch({ type: SITE_FAILURE });
    return Promise.reject(new Error(err));
  }
};

export const fetchSiteByResourceId = (resourceId: number): Promise<Site> =>
  fetchApi({
    endpoint: `/api/v1/resources/${resourceId.toString()}/site`,
  });
