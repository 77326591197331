// @flow

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { store } from '../index';

dayjs.extend(relativeTime);

export const deriveDateValueRaw = (value: any) => {
  if (value === '' || value === null || value === undefined) {
    return dayjs(NaN);
  }

  let valueCopy = value;

  // Correcting date format for safari, but only do this if it's a string, there are cases
  // where miliseconds expressed as a number are passed in.
  if (typeof value === 'string') {
    const offsetStart = valueCopy.indexOf('+');

    if (offsetStart !== -1) {
      let newEndValue = value.substr(offsetStart);
      newEndValue = `${newEndValue.slice(0, 3)}:${newEndValue.slice(3)}`;
      valueCopy = value.substr(0, offsetStart) + newEndValue;
    }
  }

  return dayjs(valueCopy);
};

export const deriveDateValue = (value: any, isTimeFrom: boolean) => {
  if (value === '' || value === null || value === undefined) {
    return '';
  }
  const dayjsObject = deriveDateValueRaw(value);

  if (isTimeFrom) {
    return dayjsObject.fromNow();
  }

  // I don't like this to have access to the store, it makes testing more difficult.
  const dateFormat = store.getState().app.dateFormatWithTime;

  return dayjsObject.format(dateFormat);
};
