// @flow

import * as React from 'react';
import Icon from './Icon';
import classNames from '../utils/classNames';
import DropDownItem from './DropDownItem';

import styles from './DropDown.module.css';

type Props = {
  sortItems: any[],
  labelText?: string,
  onChange: Function,
  initiallySelectedText?: string,
  customSelectedText?: string,
  position?: 'left' | 'right',
};

type State = {
  open: boolean,
  selected: string,
};

class SortDropDown extends React.Component<Props, State> {
  state = {
    open: false,
    selected: this.props.initiallySelectedText || this.props.sortItems[0].text,
  };

  componentDidMount = () => {
    document.addEventListener('click', this.handleClickOutside);
  };

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClickOutside);
  };

  onItemClick = (item: Object) => {
    this.setState({
      selected: item.text,
      open: false,
    });
    this.props.onChange(item.value);
  };

  container = React.createRef();

  handleClickOutside = (event: MouseEvent) => {
    if (!this.container.current) {
      return;
    }

    if (!this.container.current.contains(event.target)) {
      this.setState({
        open: false,
      });
    }
  };

  toggleClickDropDown = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  togglePressDropDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.setState({
        open: !this.state.open,
      });
    }
  };

  render() {
    const { open, selected } = this.state;
    const {
      sortItems,
      labelText,
      customSelectedText,
      position = 'right',
    } = this.props;

    return (
      <div className={styles.flexWrapper}>
        <div className={styles.wrapper} ref={this.container}>
          <div
            className={styles.textWrapper}
            onClick={this.toggleClickDropDown}
            onKeyPress={this.togglePressDropDown}
            role="button"
            tabIndex={0}
          >
            <Icon size={20} icon="arrowUpDown" />
            <p className={styles.sortText}>
              {labelText}{' '}
              <span className={styles.selected}>
                {customSelectedText || selected}
              </span>
            </p>
          </div>
          {open ? (
            <ul
              className={classNames(
                styles.itemsWrapper,
                position === 'left' ? styles.left : styles.right
              )}
            >
              {sortItems.map(item => (
                <DropDownItem
                  onClick={() => this.onItemClick(item)}
                  key={item.value}
                >
                  {item.text}
                </DropDownItem>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
    );
  }
}

export default SortDropDown;
