// @flow

import fetchApi from '../middleware/api';
import type { Sensor } from '../types/sensors';

/*
// eslint-disable-next-line import/prefer-default-export
const fetchSensorsBySite = (siteId: string) =>
  fetchApi({
    endpoint: `/api/v1/samples/sites/${siteId}?mdp=true`,
  })
    .then(r => r)
    .catch(() => ({ data: [] }));

export const getSensorsBySite = (id: string) => async () => {
  try {
    const res = await fetchSensorsBySite(id);
    return normalize(res.data.panels, [gatewaySchema]).entities.gateways;
  } catch (err) {
    return [];
  }
};

const fetchSensorsByEquipment = (id: string) =>
  fetchApi({
    endpoint: `/api/v1/samples/equipment/${id}`,
  })
    .then(r => r)
    .catch(() => ({ data: [] }));

export const getSensorsByEquipment = (id: string) => async () => {
  try {
    const res = await fetchSensorsByEquipment(id);
    return normalize(res.data.panels, [gatewaySchema]).entities.gateways;
  } catch (err) {
    return [];
  }
}; */

export const fetchSensors = () =>
  fetchApi({
    endpoint: `/api/v1/sensors/`,
  });

export const fetchSensorsByResource = (
  resourceId: number,
  recursive?: boolean = false
) =>
  fetchApi({
    endpoint: `/api/v1/sensors/resource/${resourceId.toString()}?recursive=${recursive.toString()}`,
  });

export const SENSOR_BY_ID_REQUEST = 'SENSOR_BY_ID_REQUEST';
export const SENSOR_BY_ID_SUCCESS = 'SENSOR_BY_ID_SUCCESS';
export const SENSOR_BY_ID_FAILURE = 'SENSOR_BY_ID_FAILURE';

export const fetchSensorById = (sensorId: number): Promise<Sensor> =>
  fetchApi({
    endpoint: `/api/v1/sensors/${sensorId}`,
  });

export const getSensorById = (sensorId: number) => async (dispatch: any) => {
  dispatch({ type: SENSOR_BY_ID_REQUEST });

  try {
    const sensor = await fetchSensorById(sensorId);

    // sensor.hasFullPath = true;

    dispatch({ type: SENSOR_BY_ID_SUCCESS, payload: sensor });
    return Promise.resolve();
  } catch (err) {
    dispatch({ type: SENSOR_BY_ID_FAILURE });
    return Promise.resolve();
  }
};
