// @flow

import * as React from 'react';
import styles from './ViewSiteSchedules.module.css';
import classNames from '../../../utils/classNames';
import FilterButton from '../../ui/FilterButton';
import IconEquipmentFactory from '../../icons/IconEquipmentFactory';
import Button from '../../ui/Button';
// import IconCheckboxUnchecked from '../../icons/ui/IconUnchecked';
import IconCheckboxUnchecked from '../../icons/ui/IconCheckboxContained';

class EquipmentRow extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { enabled: true };
  }

  toggleEnabled() {
    this.setState({ enabled: !this.state.enabled });
  }

  render() {
    const { enabled } = this.state;
    const { colorClass, offset, width, name, iconId, rangeText } = this.props;

    return (
      <div
        className={classNames([
          styles.equipmentRow,
          colorClass,
          enabled ? styles.enabled : null,
        ])}
      >
        <div className={styles.equipmentName}>
          <IconEquipmentFactory id={iconId} size={80} />
          <span>{name}</span>
        </div>
        <div
          className={styles.timeRange}
          style={{ marginLeft: `${offset}%`, width: `${width}%` }}
        >
          <span className={styles.statusLabel}>ON</span>
          {rangeText}
        </div>

        <div
          className={styles.switchButton}
          onClick={this.toggleEnabled.bind(this)}
        >
          <div className={styles.toggle} />
          <span>{enabled ? 'ON' : 'OFF'}</span>
        </div>
      </div>
    );
  }
}

export default class ViewSiteSchedules extends React.Component<Props, State> {
  resize() {
    const scheduleColumns = document.getElementById('schedule-columns');
    const scheduleContent = document.getElementById('schedule-content');
    const height =
      scheduleContent.offsetHeight + scheduleContent.offsetTop + 20;

    scheduleColumns.style.height = `${height}px`;
  }

  componentDidMount() {
    this.resize();
    setTimeout(this.resize.bind(this), 500);
  }

  render() {
    return (
      <div className={styles.root}>
        <div>
          <FilterButton>Equipment Types</FilterButton>
          <div className={styles.legend}>
            <div>
              <IconCheckboxUnchecked size={16} className={styles.turquoise} />
              <span>Openning & operating hours</span>
            </div>
            <div>
              <IconCheckboxUnchecked size={16} className={styles.lemon} />
              <span>Sunshine hours</span>
            </div>
            <div>
              <IconCheckboxUnchecked size={16} className={styles.blue} />
              <span>Local regulations</span>
            </div>
          </div>
        </div>

        <div className={styles.scheduleContainer}>
          <div
            className={styles.scheduleContainerColumns}
            id="schedule-columns"
          >
            <div />
            <div>
              <span>00:00am</span>
            </div>
            <div />
            <div>
              <span>02:00am</span>
            </div>
            <div />
            <div>
              <span>04:00am</span>
            </div>
            <div />
            <div>
              <span>06:00am</span>
            </div>
            <div />
            <div>
              <span>08:00am</span>
            </div>
            <div />
            <div>
              <span>10:00am</span>
            </div>
            <div />
            <div>
              <span>12:00pm</span>
            </div>
            <div />
            <div>
              <span>02:00pm</span>
            </div>
            <div />
            <div>
              <span>04:00pm</span>
            </div>
            <div />
            <div>
              <span>06:00pm</span>
            </div>
            <div />
            <div>
              <span>08:00pm</span>
            </div>
            <div />
            <div>
              <span>10:00pm</span>
            </div>
            <div />
            <div>
              <span className="status">Status</span>
            </div>
          </div>

          <div className={styles.projectionsWrapper}>
            <div className={styles.daylight} />
            <div className={styles.localRegulations} />
            <div className={styles.openningHours} />
          </div>

          <div className={styles.scheduleItem} id="schedule-content">
            <div className={styles.title}>
              <div>Monday - Friday</div>
              <div>6am - 10pm</div>
            </div>

            <EquipmentRow
              colorClass={styles.color1}
              offset={37.5}
              width={51}
              name="Canopy Lightning"
              iconId="equipmentLightning"
              rangeText="5:30am - 10:30pm"
            />
            <EquipmentRow
              colorClass={styles.color1}
              offset={39}
              width={48}
              name="Signage Lights"
              iconId="equipmentLightning"
              rangeText="6:00am - 10:00pm"
            />
            <EquipmentRow
              colorClass={styles.color1}
              offset={39}
              width={48}
              name="Store Exterior Lightning"
              iconId="equipmentLightning"
              rangeText="6:00am - 10:00pm"
            />
            <EquipmentRow
              colorClass={styles.color2}
              offset={33.3}
              width={50}
              name="RTU 1 Heating"
              iconId="equipmentLightning"
              rangeText="4:00am - 11:00pm"
            />
            <EquipmentRow
              colorClass={styles.color2}
              offset={31.2}
              width={50}
              name="RTU 1 Cooling"
              iconId="equipmentLightning"
              rangeText="4:00am - 11:00pm"
            />

            <div>
              <Button className={styles.addControlButton}>Add control</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
