// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import styles from './ViewSiteEquipment.module.css';
import {
  getFetchEquipmentBySiteId,
  starEquipment,
  clearEquipment,
  getEquipmentById,
} from '../../../actions/equipment';
import type { Equipment } from '../../../types/equipment';
import type { Site } from '../../../types/site';
import type { Space } from '../../../types/space';

import EquipmentCard from '../../../components/cards/EquipmentCard';
import BaseContentContainer from '../../../components/layout/BaseContentContainer';
import EquipmentCardList from '../../../components/lists/EquipmentCardList';
import { EquipmentFilters } from '../../../reducers/equipmentFilter';
import LineSeparator from '../../../components/LineSeparator';
import { EquipmentGrouping } from '../../../reducers/equipmentGrouping';
import EquipmentGroupingSelect from '../../../components/Filters/EquipmentGroupingSelect';
import { setEquipmentGrouping } from '../../../actions/equipmentGrouping';
import {
  equipmentStatus,
  equipmentStatusToName,
} from '../../../types/equipment';
import SearchBox from '../../search/SearchBox';
import { filterEquipment } from '../../../utils/textFilters';
import type { EquipmentGroup, EquipmentType } from '../../../types/tags';

type Props = {
  getEquipmentbySiteId: number => void,
  setEquipmentGrouping: string => void,
  getEquipmentById: number => void,
  clearEquipment: () => void,
  equipmentStatusFilter: string[],
  equipmentTypeFilter: number[],
  equipmentSpaceFilter: number[],
  equipment: Equipment[],
  equipmentTags: {
    equipmentTypes: EquipmentType[],
    equipmentGroups: EquipmentGroup[],
  },
  spaces: Space[],
  site: Site,
  equipmentGrouping: string,
  isLoading: boolean,
};

type State = {
  filteredSpaces: {},
  textFilter: '',
};

class ViewSiteEquipment extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      filteredSpaces: {},
      textFilter: '',
    };

    this.props.clearEquipment();
    try {
      this.getEquipment();
    } catch (err) {
      // todo: handle error
    }
  }

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.site.id !== prevProps.site.id) {
      this.props.clearEquipment();
      this.props.getEquipmentbySiteId(this.props.site.id);
    }
  };

  getEquipment = () => {
    this.props.getEquipmentbySiteId(this.props.site.id).then(items => {
      const filteredSpaces = items.reduce((acc, cur) => {
        if (cur.space) {
          acc[cur.space.id] = { title: cur.space.title };
        }
        return acc;
      }, {});

      this.setState({ filteredSpaces });
    });
  };

  getEquipmentWithFullPath = (id: number) => {
    const equipment = this.props.equipment.find((e: Equipment) => e.id === id);

    if (equipment && !equipment.hasFullPath) {
      this.props.getEquipmentById(id);
    }
  };

  fnStatusFilter = (item: Equipment): boolean =>
    this.props.equipmentStatusFilter.includes(item.status);

  fnEquipmentFilter = (item: Equipment): boolean =>
    this.props.equipmentTypeFilter.includes(item.typeId);

  groupBy = (groupType: string, list: Equipment[]) => {
    const filteredList = list
      // .filter(this.fnStatusFilter)
      .filter(this.fnEquipmentFilter);

    switch (groupType) {
      case EquipmentGrouping.STATUS:
        return filteredList.reduce(
          (group, next: Equipment) =>
            group.set(next.status, [...(group.get(next.status) || []), next]),
          new Map([
            [equipmentStatus.on, []],
            [equipmentStatus.off, []],
            [equipmentStatus.standby, []],
          ])
        );

      case EquipmentGrouping.ALPHABETICAL:
        return filteredList.reduce(
          (group, next: Equipment) =>
            group.set(next.title[0], [
              ...(group.get(next.title[0]) || []),
              next,
            ]),
          new Map([
            ...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
              .split('')
              .map(letter => [letter, []]),
          ])
        );

      case EquipmentGrouping.EQUIPMENT_GROUP:
        return filteredList.reduce(
          (group, next: Equipment) =>
            group.set((next.groupId || '').toString(), [
              ...(group.get((next.groupId || '').toString()) || []),
              next,
            ]),
          new Map()
        );

      case EquipmentGrouping.EQUIPMENT_TYPE:
        return filteredList.reduce(
          (group, next: Equipment) =>
            group.set((next.typeId || '').toString(), [
              ...(group.get((next.typeId || '').toString()) || []),
              next,
            ]),
          new Map()
        );

      case EquipmentGrouping.SPACE:
        return filteredList.reduce(
          (group, next: Equipment) =>
            group.set((next.spaceId || '').toString(), [
              ...(group.get((next.spaceId || '').toString()) || []),
              next,
            ]),
          new Map()
        );
      default:
        return new Map([['none', filteredList]]);
    }
  };

  groupKeyToName = (groupType, groupKey: number | string): string => {
    switch (groupType) {
      case EquipmentGrouping.STATUS:
        return (equipmentStatusToName[groupKey] || '').toUpperCase();
      case EquipmentGrouping.ALPHABETICAL:
        return groupKey.toString().toUpperCase();
      case EquipmentGrouping.EQUIPMENT_GROUP:
        return (
          this.props.equipmentTags.equipmentGroups.find(
            g => g.id.toString() === groupKey.toString()
          ) || {}
        ).title;
      case EquipmentGrouping.EQUIPMENT_TYPE:
        return (
          this.props.equipmentTags.equipmentTypes.find(
            e => e.id.toString() === groupKey.toString()
          ) || {}
        ).title;
      case EquipmentGrouping.SPACE:
        return (
          (this.state.filteredSpaces[groupKey.toString()] || {}).title ||
          'NO SPACE FOUND'
        );
      default:
        return '';
    }
  };

  handlePinClick = () => window.alert('pinned'); // eslint-disable-line no-alert

  handleCardClick = (id: number) =>
    navigate(`/monitoring/equipment/${id}/sensors`);

  renderEquipmentCard = (e: Equipment) => (
    <EquipmentCard
      key={e.id}
      equipmentObject={e}
      onPinClick={this.handlePinClick}
      onCardClick={() => this.handleCardClick(e.id)}
      onFocus={() => this.getEquipmentWithFullPath(e.id)}
    />
  );

  renderEquipmentCardListOrMessage = (list: any) =>
    list.length ? (
      list
    ) : (
      <div className={styles.messageContainer}>
        <div>
          <h5>Oops! We can’t seem to find the result you’re looking for.</h5>
        </div>
        <div>
          <span>No equipment found</span>
        </div>
      </div>
    );

  render() {
    const { equipment = [], equipmentGrouping, isLoading } = this.props;

    const { textFilter = '' } = this.state;

    const filteredEquipment = equipment.filter(
      filterEquipment.bind(this, textFilter)
    );

    const grouped = this.groupBy(equipmentGrouping, filteredEquipment);
    const list = [...grouped] // map to array
      .filter(arr => arr[1].length > 0)
      .map(([groupKey, equipmentList]: [string, Equipment[]]) => (
        <div key={`ViewSiteEquipment${groupKey}`}>
          <LineSeparator>
            {this.groupKeyToName(equipmentGrouping, groupKey)}
          </LineSeparator>
          <EquipmentCardList>
            {equipmentList.map(e => this.renderEquipmentCard(e))}
          </EquipmentCardList>
        </div>
      ));

    return (
      <React.Fragment>
        <BaseContentContainer hasFilterBar isLoading={isLoading}>
          {!isLoading && (
            <div className={styles.flexRow}>
              <EquipmentGroupingSelect
                value={equipmentGrouping}
                onChange={this.props.setEquipmentGrouping}
              />
              <SearchBox
                placeholder="Keyword, equipment name, etc…"
                width="15.625rem"
                query={this.state.textFilter}
                error={!filteredEquipment.length}
                widthActive="15.625rem"
                onChange={value =>
                  this.setState({ textFilter: value.toLowerCase() })
                }
              />
            </div>
          )}
          {!isLoading && this.renderEquipmentCardListOrMessage(list)}
        </BaseContentContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  equipmentStatusFilter: state.equipmentFilter[EquipmentFilters.STATUS],
  equipmentTypeFilter: state.equipmentFilter[EquipmentFilters.TYPE],
  equipmentSpaceFilter: state.equipmentFilter[EquipmentFilters.SPACE],
  spaces: state.spaces,
  equipment: Object.values(state.equipment),
  equipmentTags: state.equipmentTags,
  equipmentGrouping: state.equipmentGrouping,
  isLoading: state.loading.equipment,
});

const mapDispatchToProps = {
  getEquipmentbySiteId: getFetchEquipmentBySiteId,
  getEquipmentById,
  starEquipment,
  setEquipmentGrouping,
  clearEquipment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewSiteEquipment);
