// @flow

import * as React from 'react';
import PieSlice from '../PieSlice/PieSlice';

type Props = {
  data: Object,
  arc: Function,
  arcHover: Function,
  svgDimensions: Object,
  margins: Object,
  percentage: Function,
  colorScale: Function,
};

const PieSlices = ({
  data,
  arc,
  arcHover,
  svgDimensions,
  margins,
  percentage,
  colorScale,
}: Props) => {
  const slices = data.map(slice => (
    <PieSlice
      key={slice.data.name}
      slice={slice}
      fill={colorScale(slice.data.name)}
      arc={arc}
      arcHover={arcHover}
      percentage={percentage}
    />
  ));

  return (
    <g
      transform={`translate(${svgDimensions.width /
        2}, ${(svgDimensions.height - margins.top) / 2})`}
    >
      {slices}
    </g>
  );
};

export default PieSlices;
