// @flow

import * as React from 'react';
import styles from './EquipmentCardList.module.css';

type Props = {
  children: React.Node,
};

const EquipmentCardList = ({ children }: Props) => (
  <div className={styles.root}>{children}</div>
);

export default EquipmentCardList;
