// @flow

import * as React from 'react';

type Props = {
  classes?: any,
  height?: number,
  width?: number,
};

export default ({ classes = {}, height = 40, width = 40 }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    className={classes.icon}
    width={height}
    height={width}
  >
    <g style={{ opacity: '0.1' }}>
      <path
        style={{ fill: 'currentColor' }}
        d="M38,23.5a8.4962,8.4962,0,0,0-7.0183-8.3626A8.4946,8.4946,0,0,0,14,15.5c0,.1971.0163.39.0295.584A6.9948,6.9948,0,0,0,11,29.7063V30H24.0283a8.485,8.485,0,0,0,10.9435,0H35v-.0257A8.4765,8.4765,0,0,0,38,23.5Z"
      />
    </g>
    <g>
      <path
        style={{ fill: 'currentColor' }}
        d="M27.9817,12.1374A8.4946,8.4946,0,0,0,11,12.5c0,.1971.0163.39.0295.584A6.986,6.986,0,0,0,5.238,25.1162a8.3251,8.3251,0,0,1,.819-2.0651,4.9667,4.9667,0,0,1,4.68-7.9886l2.4564.3632-.1685-2.4774L13.018,12.85C13.01,12.7339,13,12.618,13,12.5a6.4946,6.4946,0,0,1,12.9835-.2781l.0679,1.6063,1.5834.2789a6.4828,6.4828,0,0,1,3.174,11.2447A7.78,7.78,0,0,1,31,27c0,.2469-.0252.4889-.048.7313A8.5713,8.5713,0,0,0,31.9717,27H32v-.0257a8.4895,8.4895,0,0,0-4.0183-14.8369Z"
      />
      <g>
        <path
          style={{ fill: 'currentColor' }}
          d="M11.6831,23.252a2.21,2.21,0,0,0-1.896.92,4.282,4.282,0,0,0-.6714,2.5645q0,3.42,2.5674,3.4209a8.1352,8.1352,0,0,0,2.61-.5381v1.8184a7.25,7.25,0,0,1-2.8125.5254,4.29,4.29,0,0,1-3.4141-1.3545,5.7283,5.7283,0,0,1-1.1826-3.8857,6.3517,6.3517,0,0,1,.5806-2.7959,4.1963,4.1963,0,0,1,1.6689-1.84,4.9349,4.9349,0,0,1,2.55-.64,6.8828,6.8828,0,0,1,2.9946.7207l-.7,1.7627a11.5016,11.5016,0,0,0-1.1543-.4756A3.4318,3.4318,0,0,0,11.6831,23.252Z"
        />
        <path
          style={{ fill: 'currentColor' }}
          d="M25.7529,26.6943a5.5506,5.5506,0,0,1-1.2593,3.9043,4.6694,4.6694,0,0,1-3.61,1.3643,4.67,4.67,0,0,1-3.61-1.3643,5.5747,5.5747,0,0,1-1.2593-3.918,5.4989,5.4989,0,0,1,1.2627-3.9014,4.7245,4.7245,0,0,1,3.6206-1.3467A4.6594,4.6594,0,0,1,24.5044,22.79,5.5656,5.5656,0,0,1,25.7529,26.6943Zm-7.4648,0a4.2719,4.2719,0,0,0,.65,2.5811,2.2757,2.2757,0,0,0,1.9453.8682q2.5957,0,2.5957-3.4492,0-3.457-2.582-3.4561a2.2907,2.2907,0,0,0-1.9517.8711A4.2529,4.2529,0,0,0,18.2881,26.6943Z"
        />
      </g>
      <path
        style={{ fill: 'currentColor' }}
        d="M30.4678,33.4521H26.7563V32.543l1.249-1.2627q.5347-.56.7-.7646a1.7108,1.7108,0,0,0,.229-.3516.7556.7556,0,0,0,.0645-.3076.4017.4017,0,0,0-.1294-.3154.532.532,0,0,0-.3638-.1152.9857.9857,0,0,0-.4878.1387,3.4616,3.4616,0,0,0-.5581.4072L26.7,29.083a4.0472,4.0472,0,0,1,.665-.5049,2.362,2.362,0,0,1,.5859-.2334,2.8908,2.8908,0,0,1,.7119-.08,2.1528,2.1528,0,0,1,.8867.1748,1.387,1.387,0,0,1,.605.499,1.2773,1.2773,0,0,1,.2153.7256,1.8642,1.8642,0,0,1-.3081,1.0576,3.4223,3.4223,0,0,1-.418.52q-.2607.2739-1.1108,1.0361v.0342h1.9346Z"
      />
    </g>
  </svg>
);
