// @flow

import * as React from 'react';
import styles from './SubTabs.module.css';

type Props = {
  children: React.Node,
};

const SubTabs = ({ children }: Props) => (
  <div role="tablist" className={styles.root}>
    {children}
  </div>
);

export default SubTabs;
