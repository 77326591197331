// @flow

import type { Equipment } from './equipment';
import type { SensorTarget, SensorType } from './sensors';
import type { MeasurementSystem } from './shared';
import type { Organization } from './organization';

const SITE_STATUS_ON = 'SITE_STATUS_ON';
const SITE_STATUS_OFF = 'SITE_STATUS_OFF';
const SITE_STATUS_PARTIALLY_OFF = 'SITE_STATUS_PARTIALLY_OFF';

export const siteStatus = {
  on: SITE_STATUS_ON,
  off: SITE_STATUS_OFF,
  partiallyOff: SITE_STATUS_PARTIALLY_OFF,
};

export type SiteStatus = $Values<typeof siteStatus>; // eslint-disable-line no-undef

export const equipmentStatusToName = {
  [SITE_STATUS_ON]: 'on',
  [SITE_STATUS_OFF]: 'off',
  [SITE_STATUS_PARTIALLY_OFF]: 'partiallyOff',
};

export type Site = {
  _entity: 'Site',
  id: number,
  title: string,
  description: string,
  parentId: number,
  parent?: any,
  alias: string,
  timeZone?: string,
  address: string,
  createdAt: string,
  updatedAt?: null,
  equipment?: Equipment[],
  externalId?: string,
  type?: Object,
  typeId?: string,
  organization?: Organization,
  organizationId?: string,
  latitude?: string,
  longitude?: string,
  currency?: string,
  measurementSystem?: MeasurementSystem,
};

export type SitesApiFilter = {
  equipmentIds?: number[],
  ids?: number[],
  limit?: number,
  skip?: number,
  sensorTypes?: SensorType[],
  sensorTarget?: SensorTarget[],
};
