// @flow

import * as React from 'react';

type Props = {
  classes: any,
  height: number,
  width: number,
};

export default ({ classes = {}, height = 40, width = 40 }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    className={classes.icon}
    width={height}
    height={width}
  >
    <g style={{ opacity: '0.1' }}>
      <path
        style={{ fill: 'currentColor' }}
        d="M10,34.9058l-3.3525-9.1s-3.4821-10.4278,5.7036-13.55A10.57,10.57,0,0,1,16,11.9058h7a7.3751,7.3751,0,0,1,4.9155-3.9029C29.4342,7.5965,31,8.3336,31,9.9058v4a13.7765,13.7765,0,0,0,1.2345,2.5256c.4342.7983.8568,1.4744,1.7655,1.4744h2c.4272,0,1,.1668,1,.594v4.8819a1.4314,1.4314,0,0,1-.146.67,3.2872,3.2872,0,0,1-2.4869,1.6352,3.4815,3.4815,0,0,0-2.3275,1.648l-4.8332,8.0864a1.0918,1.0918,0,0,1-.908.4851H21.9032c-.6027,0-.9032-.3973-.9032-1v-3H17v3a1,1,0,0,1-1,1H11C10.5544,35.9058,10.1657,35.3194,10,34.9058Z"
      />
    </g>
    <g>
      <path
        style={{ fill: 'currentColor' }}
        d="M33,15.0027H31c-.91,0-1.33-.68-1.77-1.48a14.0419,14.0419,0,0,1-1.23-2.52v-4a2.0635,2.0635,0,0,0-2.26-2.02,3.4438,3.4438,0,0,0-.83.11A7.367,7.367,0,0,0,20,9.0027H17.73a9.4452,9.4452,0,0,1,1.31,2h2.31l.5-1.26a5.3993,5.3993,0,0,1,3.58-2.71,1.1159,1.1159,0,0,1,.31-.05,1.0864,1.0864,0,0,1,.26.04v3.98c0,.73.34,1.39,1.32,3.19l.15.29a4.0068,4.0068,0,0,0,3.53,2.52h1v3.34a1.6044,1.6044,0,0,1-1.01.47,5.48,5.48,0,0,0-3.67,2.59l-4.54,7.6H20v-4H12v4H8.76L6,23.5027a9.3718,9.3718,0,0,1-2.75-2.22,13.003,13.003,0,0,0,.39,1.62l3.36,9.1c.16.41.55,1,1,1h5a1.003,1.003,0,0,0,1-1v-3h4v3c0,.6.3,1,.9,1h4.4a1.0689,1.0689,0,0,0,.9-.49l4.84-8.08a3.4453,3.4453,0,0,1,2.32-1.65,3.2936,3.2936,0,0,0,2.49-1.64,1.401,1.401,0,0,0,.15-.67v-4.88C34,15.1626,33.42,15.0027,33,15.0027Z"
      />
      <circle style={{ fill: 'currentColor' }} cx="24.98" cy="15.7427" r="1" />
      <circle style={{ fill: 'currentColor' }} cx="17.4967" cy="5.5" r="2.5" />
      <path
        style={{ fill: 'currentColor' }}
        d="M16.74,11.0027a7.5693,7.5693,0,0,0-1.95-2,7.4915,7.4915,0,0,0-11.81,6.13,7.2113,7.2113,0,0,0,.15,1.48v.03a7.3419,7.3419,0,0,0,1.98,3.71,7.41,7.41,0,0,0,5.37,2.28,7.5,7.5,0,0,0,6.26-11.63Zm-6.5343,2.59a8.0622,8.0622,0,0,0,1.1792.661,3.28,3.28,0,0,1,1.2817.9729,2.2508,2.2508,0,0,1,.3589,1.3087,2.4575,2.4575,0,0,1-.3369,1.2874,2.2637,2.2637,0,0,1-.9717.8743,6.2177,6.2177,0,0,1-.72.2588V20h-1V19a5.0549,5.0549,0,0,1-.9654-.1,4.1856,4.1856,0,0,1-1.0059-.3812V16.6419a5.464,5.464,0,0,0,1.1475.4824,3.9505,3.9505,0,0,0,1.0938.1732.9934.9934,0,0,0,.63-.1626.5145.5145,0,0,0,.2-.4185.49.49,0,0,0-.0806-.28.9684.9684,0,0,0-.2587-.2426,10.3737,10.3737,0,0,0-.95-.5011A4.4977,4.4977,0,0,1,8.76,15.0213a2.0574,2.0574,0,0,1-.5176-.7463,2.6781,2.6781,0,0,1-.1684-.9968,2.0731,2.0731,0,0,1,.7177-1.6793,2.5043,2.5043,0,0,1,1.2047-.5315V10h1v1.0035a3.3773,3.3773,0,0,1,2.0287.5528l-.5908,1.6259a4.0011,4.0011,0,0,0-1.7285-.5011.8433.8433,0,0,0-.5469.1439.4485.4485,0,0,0-.1709.3572A.5283.5283,0,0,0,10.2057,13.5927Z"
      />
    </g>
  </svg>
);
