// @flow

import * as React from 'react';
import { arc } from 'd3-shape';
import { scaleLinear, scaleOrdinal } from 'd3-scale';
import styles from './Activity.module.css';

const hours = [
  { index: 0, title: '12 am' },
  { index: 1, title: '1 am' },
  { index: 2, title: '2 am' },
  { index: 3, title: '3 am' },
  { index: 4, title: '4 am' },
  { index: 5, title: '5 am' },
  { index: 6, title: '6 am' },
  { index: 7, title: '7 am' },
  { index: 8, title: '8 am' },
  { index: 9, title: '9 am' },
  { index: 10, title: '10 am' },
  { index: 11, title: '11 am' },
  { index: 12, title: '12 pm' },
  { index: 13, title: '1 pm' },
  { index: 14, title: '2 pm' },
  { index: 15, title: '3 pm' },
  { index: 16, title: '4 pm' },
  { index: 17, title: '5 pm' },
  { index: 18, title: '6 pm' },
  { index: 19, title: '7 pm' },
  { index: 20, title: '8 pm' },
  { index: 21, title: '9 pm' },
  { index: 22, title: '10 pm' },
  { index: 23, title: '11 pm' },
];

const officeHours = [
  { index: 0, active: false, title: '12 am' },
  { index: 1, active: false, title: '1 am' },
  { index: 2, active: false, title: '2 am' },
  { index: 3, active: false, title: '3 am' },
  { index: 4, active: false, title: '4 am' },
  { index: 5, active: false, title: '5 am' },
  { index: 6, active: false, title: '6 am' },
  { index: 7, active: false, title: '7 am' },
  { index: 8, active: true, title: '8 am' },
  { index: 9, active: true, title: '9 am' },
  { index: 10, active: true, title: '10 am' },
  { index: 11, active: true, title: '11 am' },
  { index: 12, active: true, title: '12 pm' },
  { index: 13, active: true, title: '1 pm' },
  { index: 14, active: true, title: '2 pm' },
  { index: 15, active: true, title: '3 pm' },
  { index: 16, active: true, title: '4 pm' },
  { index: 17, active: true, title: '5 pm' },
  { index: 18, active: true, title: '6 pm' },
  { index: 19, active: false, title: '7 pm' },
  { index: 20, active: false, title: '8 pm' },
  { index: 21, active: false, title: '9 pm' },
  { index: 22, active: false, title: '10 pm' },
  { index: 23, active: false, title: '11 pm' },
];

const energyConsumption = [
  { index: 0, value: 1.8 },
  { index: 1, value: 1.9 },
  { index: 2, value: 2 },
  { index: 3, value: 2.1 },
  { index: 4, value: 2.2 },
  { index: 5, value: 2.3 },
  { index: 6, value: 2.4 },
  { index: 7, value: 2.5 },
  { index: 8, value: 2.6 },
  { index: 9, value: 2.7 },
  { index: 10, value: 2.8 },
  { index: 11, value: 2.9 },
  { index: 12, value: 3 },
  { index: 13, value: 3.1 },
  { index: 14, value: 3.2 },
  { index: 15, value: 3.3 },
  { index: 16, value: 3.2 },
  { index: 17, value: 3.1 },
  { index: 18, value: 3 },
  { index: 19, value: 2.9 },
  { index: 20, value: 2.8 },
  { index: 21, value: 2.7 },
  { index: 22, value: 2.6 },
  { index: 23, value: 2.7 },
  { index: 24, value: 2.8 },
  { index: 25, value: 2.9 },
  { index: 26, value: 3 },
  { index: 27, value: 3.1 },
  { index: 28, value: 3.2 },
  { index: 29, value: 3.3 },
  { index: 30, value: 3.4 },
  { index: 31, value: 3.5 },
  { index: 32, value: 3.6 },
  { index: 33, value: 3.7 },
  { index: 34, value: 3.8 },
  { index: 35, value: 3.9 },
  { index: 36, value: 4 },
  { index: 37, value: 4.1 },
  { index: 38, value: 4.2 },
  { index: 39, value: 4.3 },
  { index: 40, value: 4.2 },
  { index: 41, value: 4.1 },
  { index: 42, value: 4 },
  { index: 43, value: 3.9 },
  { index: 44, value: 3.8 },
  { index: 45, value: 3.7 },
  { index: 46, value: 3.6 },
  { index: 47, value: 3.5 },
  { index: 48, value: 3.4 },
  { index: 49, value: 3.3 },
  { index: 50, value: 3.2 },
  { index: 51, value: 3.1 },
  { index: 52, value: 3 },
  { index: 53, value: 2.9 },
  { index: 54, value: 2.8 },
  { index: 55, value: 2.7 },
  { index: 56, value: 2.6 },
  { index: 57, value: 2.5 },
  { index: 58, value: 2.4 },
  { index: 59, value: 2.3 },
  { index: 60, value: 2.1 },
  { index: 61, value: 2 },
  { index: 62, value: 1.9 },
  { index: 63, value: 1.8 },
  { index: 64, value: 1.9 },
  { index: 65, value: 2 },
  { index: 66, value: 2.1 },
  { index: 67, value: 2.2 },
  { index: 68, value: 2.1 },
  { index: 69, value: 2.2 },
  { index: 70, value: 2.1 },
  { index: 71, value: 2 },
  { index: 72, value: 1.9 },
  { index: 73, value: 1.8 },
  { index: 74, value: 1.9 },
  { index: 75, value: 2 },
  { index: 76, value: 2.1 },
  { index: 77, value: 2.2 },
  { index: 78, value: 2.3 },
  { index: 79, value: 2.4 },
  { index: 80, value: 2.5 },
  { index: 81, value: 2.4 },
  { index: 82, value: 2.3 },
  { index: 83, value: 2.4 },
  { index: 84, value: 2.5 },
  { index: 85, value: 2.6 },
  { index: 86, value: 2.7 },
  { index: 87, value: 2.6 },
  { index: 88, value: 2.7 },
  { index: 89, value: 2.8 },
  { index: 90, value: 2.9 },
  { index: 91, value: 3 },
  { index: 92, value: 3.1 },
  { index: 93, value: 3.2 },
  { index: 94, value: 3.3 },
  { index: 95, value: 3.4 },
  { index: 96, value: 3.5 },
  { index: 97, value: 3.4 },
  { index: 98, value: 3.5 },
  { index: 99, value: 3.4 },
  { index: 100, value: 3.5 },
  { index: 101, value: 3.6 },
  { index: 102, value: 3.7 },
  { index: 103, value: 3.8 },
  { index: 104, value: 3.7 },
  { index: 105, value: 3.8 },
  { index: 106, value: 3.9 },
  { index: 107, value: 4 },
  { index: 108, value: 4.1 },
  { index: 109, value: 4.2 },
  { index: 110, value: 4.3 },
  { index: 111, value: 4.4 },
  { index: 112, value: 4.5 },
  { index: 113, value: 4.6 },
  { index: 114, value: 4.5 },
  { index: 115, value: 4.4 },
  { index: 116, value: 4.5 },
  { index: 117, value: 4.4 },
  { index: 118, value: 4.5 },
  { index: 119, value: 4.6 },
  { index: 120, value: 4.7 },
  { index: 121, value: 4.8 },
  { index: 122, value: 4.9 },
  { index: 123, value: 5 },
  { index: 124, value: 4.9 },
  { index: 125, value: 4.8 },
  { index: 126, value: 4.7 },
  { index: 127, value: 4.8 },
  { index: 128, value: 4.9 },
  { index: 129, value: 4.8 },
  { index: 130, value: 4.7 },
  { index: 131, value: 4.6 },
  { index: 132, value: 4.5 },
  { index: 133, value: 4.6 },
  { index: 134, value: 4.7 },
  { index: 135, value: 4.8 },
  { index: 136, value: 4.7 },
  { index: 137, value: 4.6 },
  { index: 138, value: 4.7 },
  { index: 139, value: 4.6 },
  { index: 140, value: 4.5 },
  { index: 141, value: 4.6 },
  { index: 142, value: 4.7 },
  { index: 143, value: 4.8 },
  { index: 144, value: 4.9 },
];

const notifications = [
  { index: 1, priority: 'high', date: '2018-07-04T09:39:58' },
  { index: 2, priority: 'normal', date: '2018-07-04T10:15:22' },
  { index: 3, priority: 'medium', date: '2018-07-04T10:55:02' },
  { index: 4, priority: 'low', date: '2018-07-04T09:25:11' },
  { index: 5, priority: 'low', date: '2018-07-04T14:30:32' },
];

const colorScale = scaleOrdinal()
  .domain(['normal', 'low', 'medium', 'high'])
  .range(['#0c0f12', '#ffce34', '#ff9248', '#ff407b']);

let dataSetKeys = notifications.map(item => item.priority);
dataSetKeys = dataSetKeys.filter(
  (elem, index, self) => index === self.indexOf(elem)
);

const shift = (2 * Math.PI) / 12;
const fullCircle = 2 * Math.PI - shift;
const slice = fullCircle / 24;

const barSlice = fullCircle / energyConsumption.length;

const circleScale = scaleLinear()
  .domain([0, 6])
  .range([55, 163]);

const circleAxis = [2, 4, 6];

const notifcationPosition = date => {
  const notificationDate = new Date(Date.parse(date));
  const minutesInHours = notificationDate.getMinutes() / 60;
  const secondsInHours = notificationDate.getSeconds() / 60 / 60;
  const hours = notificationDate.getHours() + minutesInHours + secondsInHours;

  const radius = circleScale(circleAxis[1]);
  const angle = slice * (hours + shift * 2) - Math.PI / 2;
  const x = radius * Math.cos(angle);
  const y = radius * Math.sin(angle);

  return {
    x,
    y,
  };
};

const drawRadials = (circleAxis, value, index) => {
  if (index !== 1) {
    return arc()
      .innerRadius(circleScale(value) - 1)
      .outerRadius(circleScale(value))
      .startAngle(0.25)
      .endAngle(6.01)();
  }

  return null;
};

const arcFn = (
  hour: number,
  radius: number,
  height: number = 0,
  gap = true
) => {
  const startAngle = slice * (hour + shift * 2);
  const endAngle = startAngle + slice;

  return arc()
    .innerRadius(radius)
    .outerRadius(radius + height)
    .startAngle(startAngle)
    .endAngle(endAngle)
    .padAngle(gap ? slice / 18 : 0)(); // some gap
};

const barArcfn = (
  hour: number,
  radius: number,
  height: number = 0,
  gap = true
) => {
  const startAngle =
    barSlice * (hour + shift * (energyConsumption.length / 12));
  const endAngle = startAngle + barSlice;

  return arc()
    .innerRadius(0)
    .outerRadius(circleScale(height))
    .startAngle(startAngle)
    .endAngle(endAngle)
    .padAngle(gap ? barSlice / 10 : 0)(); // some gap
};

class Activity extends React.Component<{}, { active: ?number }> {
  state = {
    active: null,
  };

  onHoverHandler = (hour: number) =>
    this.setState({
      active: hour,
    });

  render() {
    return (
      <div>
        <svg height={340} width={330} xmlns="http://www.w3.org/2000/svg">
          {/* <g
            transform={`translate(${svgDimensions.width /
              2}, ${(svgDimensions.height - MARGINS.top) / 2})`}
            className={styles.legendGroup}
          > */}
          <g transform="translate(163, 170)" className={styles.legendGroup}>
            {circleAxis.map(number => (
              <text
                id={`text${number}`}
                key={number.toString()}
                className={styles.circleText}
                x={0}
                y={-circleScale(number) + 5}
              >
                {number}
              </text>
            ))}

            <text
              id="textkwh"
              className={styles.circleText}
              x={0}
              y={-circleScale(circleAxis[circleAxis.length - 1]) + 15}
            >
              kWh
            </text>

            <text
              id="textstby"
              className={styles.circleText}
              x={0}
              y={-circleScale(circleAxis[0]) + 15}
            >
              STBY
            </text>

            {circleAxis.map((number, index) => (
              <path
                id={`arcTimeHour${number}`}
                key={number.toString()}
                d={drawRadials(circleAxis, number, index)}
                className={styles.radial}
              />
            ))}

            {energyConsumption.map(hour => (
              <path
                id={`arcTimeHour${hour.index}`}
                key={hour.index.toString()}
                d={barArcfn(hour.index, circleScale(4), hour.value)}
                className={styles.bar}
                onMouseEnter={() => this.onHoverHandler(hour.index)}
              />
            ))}

            {hours.map(hour => (
              <path
                id={`arcTimeHour${hour.index}`}
                key={hour.index.toString()}
                d={arcFn(hour.index, circleScale(4), 1)}
                className={styles.legendTimeScale}
                onMouseEnter={() => this.onHoverHandler(hour.index)}
              />
            ))}

            {hours.map(hour => (
              <path
                id={`arcText${hour.index}`}
                key={`arcText${hour.index.toString()}`}
                d={arcFn(hour.index, circleScale(4) - 11)}
                opacity={0}
              />
            ))}

            {officeHours.map(item => (
              <path
                id={`arcText${item.index}`}
                key={`arcOfficeHours${item.index.toString()}`}
                d={arcFn(item.index, circleScale(4) + 1, 5, false)}
                className={styles.legendOfficeHours}
                opacity={item.active ? 0.5 : 0}
              />
            ))}

            {hours.map(
              (hour, i) =>
                (i + 1) % 2 ? (
                  <text className={styles.legendTimeScaleText}>
                    <textPath
                      key={`arcTextHour${hour.index.toString()}`}
                      textAnchor="center"
                      href={`#arcText${hour.index}`}
                    >
                      {hour.title}
                    </textPath>
                  </text>
                ) : null
            )}

            <circle r="55" className={styles.middleCircle} />
            {notifications.map(notification => (
              <circle
                r="3"
                fill={colorScale(notification.priority)}
                cx={notifcationPosition(notification.date).x}
                cy={notifcationPosition(notification.date).y}
              />
            ))}
          </g>
        </svg>
        <div className={styles.legendWrapper}>
          <div className={styles.legendCircleWrapper}>
            {dataSetKeys.map(item => (
              <div
                className={styles.legendCircle}
                style={{ backgroundColor: colorScale(item) }}
              />
            ))}
            <div className={styles.legendText}>Notifications</div>
          </div>
          <div className={styles.legendTypeWrapper}>
            <div className={styles.legendText}>Energy Consumption</div>
            <div className={styles.legendText}>Business Hours</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Activity;
