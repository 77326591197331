export const APP_SERVICE_WORKER_DID_UPDATE = 'APP_SERVICE_WORKER_DID_UPDATE';
export const serviceWorkerDidUpdate = () => ({
  type: APP_SERVICE_WORKER_DID_UPDATE,
});

export const APP_SET_TOKEN = 'APP_SET_TOKEN';
export const appSetToken = payload => ({
  type: APP_SET_TOKEN,
  payload,
});
