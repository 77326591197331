// @flow

import * as React from 'react';
import Loadable from 'react-loadable';
import CircularProgress from '@material-ui/core/CircularProgress';
import type { IconFactory } from '../../types/icon';

const Loading = ({ size = 40 }: { size: number }) => (
  <CircularProgress size={size} />
);

const IconGateway = Loadable({
  loader: () => import('./gateways/IconGateway'),
  loading: Loading,
});

export default ({ classes, size, id }: IconFactory) => {
  switch (id) {
    case 'gateway':
      return <IconGateway classes={classes} size={size} />;
    default:
      return <IconGateway classes={classes} size={size} />;
  }
};
