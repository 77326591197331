// @flow

import * as React from 'react';
import * as d3Axis from 'd3-axis';
import { select } from 'd3-selection';

type Props = {
  orient: string,
  scale: Function,
  translate: string,
  tickSize: number,
};

export default class Axis extends React.Component<Props> {
  componentDidMount() {
    this.renderAxis();
  }

  componentDidUpdate() {
    this.renderAxis();
  }

  axisNode = React.createRef();

  renderAxis() {
    const axisType = `axis${this.props.orient}`;
    const axis = d3Axis[axisType]()
      .scale(this.props.scale)
      .tickSize(this.props.tickSize)
      .ticks(3);

    select(this.axisNode.current).call(axis);
    select(this.axisNode.current)
      .select('.domain')
      .remove();
    if (this.props.orient === 'Left') {
      const el = select(this.axisNode.current).select('.tick text');
      if (el.text() === '0') {
        el.remove();
      }
    }
  }

  render() {
    return (
      <g
        className={`axis axis-${this.props.orient}`}
        ref={this.axisNode}
        transform={this.props.translate}
      />
    );
  }
}
