// @flow

import LineSeparator from '../components/LineSeparator';
import type { SensorType } from './sensors';
import { sensorType } from './sensors';

export const sentinelTypes = {
  TEMPERATURE: 'BasicTemperatureThreshold',
  HUMIDITY: 'BasicHumidityThreshold',
  DOOR: 'BasicDoorSwitch',
};

export type SentinelType = $Values<typeof sentinelTypes>;

export const sentinelTypesList = [
  { type: sentinelTypes.TEMPERATURE, title: 'Temperature' },
  { type: sentinelTypes.DOOR, title: 'Door' },
];

// export const OPEN / CLOSED

export const targetType = {
  EQUIPMENT: 'EQUIPMENT',
  DOOR: 'DOOR',
};

export const comparisonTypes = {
  default: '',
  eq: 'eq',
  gt: 'gt',
  lt: 'lt',
};

// TODO IS THIS CORRECT?
export const comparisonTypesToSymbol = {
  default: '',
  [comparisonTypes.eq]: '=',
  [comparisonTypes.gt]: '>',
  [comparisonTypes.lt]: '<',
  rg: '-',
};

export type ComparisonTypes = $Keys<typeof comparisonTypes>;

export type TargetType = $Keys<typeof targetType>;

export type SentinelTarget = {
  targetId: number,
  targetType: TargetType,
};

export type ParametersTemperature = {
  threshold?: number,
  comparisonType?: ComparisonTypes,
};

export type ParametersHumidity = {
  threshold?: number,
  comparisonType?: ComparisonTypes,
};

export type ParametersSwitch = {
  openClosed?: 'OPEN' | 'CLOSED',
};

export type Parameters = {
  threshold?: number,
  comparisonType?: ComparisonTypes,
  openClosed?: 'OPEN' | 'CLOSED',
};

export const defaultTemperatureParams: ParametersTemperature = {
  comparisonType: comparisonTypes.eq,
  threshold: 0,
};

export const defaultDoorParameters: LineSeparator = {
  openClosed: 'OPEN',
};

export const sentinelHandlerChannel = {
  USER_GROUP_EMAIL: 'USER_GROUP_EMAIL',
};

export type SentinelHandler = {
  channel?: $Keys<typeof sentinelHandlerChannel>,
  delay: number,
  ix: number,
  recipients: number[],
};

export type Sentinel = {
  id: string,
  title: string,
  description: string,
  sentinelType: SentinelType,
  targets: SentinelTarget[],
  parameters: Parameters,
  handlers: SentinelHandler[],
  reason?: string,
  mutedUntil?: string, // does not
  muteReason?: string,
  muted?: boolean,
};

export type Wizard = {
  currentStep?: string,
};

export type SentinelsApiFilter = {
  siteIds?: number[],
  equipmentIds?: number[],
  spaceIds?: number[],
};

export const sentinelTypeToSensorType = (type: SentinelType): SensorType => {
  switch (type) {
    case sentinelTypes.DOOR:
      return sensorType.BINARY;
    case sentinelTypes.TEMPERATURE:
      return sensorType.TEMPERATURE;
    case sentinelTypes.HUMIDITY:
      return sensorType.HUMIDITY;
    default:
      throw new Error('unknown sentinelTypes');
  }
};
