// @flow

import * as React from 'react';
import styles from './SiteCard.module.css';
import LabelButton from '../ui/LabelButton';
import classNames from '../../utils/classNames';
import type { SiteStatus, Site } from '../../types/site';
import { siteStatus } from '../../types/site';
import IconSitesFactory from '../icons/IconSitesFactory';
import IconUiFactory from '../icons/IconUiFactory';
import { fetchGatewaysByResource } from '../../actions/gateways';
import DateChip from '../Tables/Cells/DateChip';
import { determineSiteOnlineStatus } from '../../utils/site';
import getHighlightedText from '../../utils/getHightlightedText';

type Props = {
  site: Site,
  onClick: string => void,
  highlight?: string,
};

type State = {
  siteStatusInfo: {
    status: SiteStatus,
    mostRecentOnline: string,
    offlineGatewaysCount: number,
  },
};

function getStatus(s: string) {
  if (s === siteStatus.on) {
    return 'Online';
  }

  if (s === siteStatus.on || s === siteStatus.partiallyOff) {
    return 'Partially Online';
  }

  if (s === '') {
    return 'No gateway installed';
  }

  return 'Offline';
}

// <DateChip extraClassNames={[ styles.]} value={ date} />
class SiteCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      siteStatusInfo: {
        mostRecentOnline: '',
        status: '',
        offlineGatewaysCount: 0,
      },
    };
  }

  componentDidMount() {
    fetchGatewaysByResource(this.props.site.id).then(gateways => {
      const siteStatusInfo = determineSiteOnlineStatus(gateways);
      this.setState({ siteStatusInfo });
    });
  }

  gatewayText = (count: number = 0) =>
    count === 1
      ? `${count} gateway is offline`
      : `${count} gateways are offline`;

  render() {
    const { site, onClick, highlight = '' } = this.props;
    const { siteStatusInfo } = this.state;
    const { status, mostRecentOnline, offlineGatewaysCount } = siteStatusInfo;

    return (
      <div
        className={classNames(
          styles.root,
          status === siteStatus.off ? styles.rootStatusOffline : undefined,
          status === '' ? styles.rootStatusNotInstalled : undefined
        )}
        onClick={onClick}
        onKeyPress={onClick}
        tabIndex="0"
        role="button"
      >
        <div
          className={classNames(
            styles.icon,
            status === siteStatus.off || status === '' ? styles.offlineIcon : ''
          )}
        >
          <IconSitesFactory id={site.typeId || 'gasstation'} />
        </div>
        <div className={styles.status}>
          <LabelButton
            extraClassNames={
              status === siteStatus.off || status === ''
                ? [styles.siteStateOff]
                : []
            }
          >
            {getStatus(status)}
          </LabelButton>
        </div>
        <h5 className={styles.title}>
          {getHighlightedText(site.title, highlight, styles.highlightedText)}
        </h5>
        <p className={styles.location}>
          {getHighlightedText(site.address, highlight, styles.highlightedText)}
        </p>
        {status === siteStatus.off &&
          mostRecentOnline && (
            <div className={styles.warning}>
              <div className={styles.paddingRight}>
                <IconUiFactory id="offline" />
              </div>
              <DateChip
                prefixText="Site is offline since "
                value={mostRecentOnline}
              />
            </div>
          )}
        {status === siteStatus.partiallyOff && (
          <div className={styles.warning}>
            {this.gatewayText(offlineGatewaysCount)}
          </div>
        )}
      </div>
    );
  }
}

export default SiteCard;
