// @flow

import * as React from 'react';
import type { NotificationIcon } from '../../../types/icon';

const IconSize = () => (
  <g>
    <path
      d="M63 45h-.6v-9.7c0-9.4-6.6-18-16-19.9V15c0-2.2-1.8-4-4-4s-4 1.8-4 4v.4c-9.4 2-16 10.6-16 19.9v9.6c0 12.6-4.1 16.9-5.9 21.4-.5 1.3.5 2.6 2 2.6h16.2c.9 3.4 4 6 7.7 6s6.8-2.6 7.7-6h.9c2.7 3.7 7.1 6.1 12 6.1 8.3 0 15-6.7 15-15s-6.7-15-15-15zM42.4 14.9zm0 59c-3.2 0-5.8-2.1-6.7-5h13.4c-.8 2.9-3.5 5-6.7 5zm30.7-18.3L62.8 65.8c-.2.2-.5.3-.7.3s-.5-.1-.7-.3l-6.5-6.5c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l5.8 5.8 9.5-9.5c.4-.4 1-.4 1.4 0s.4 1 .1 1.4z"
      fill="currentColor"
      opacity=".1"
    />
    <path
      d="M62.1 20.1c.8 1.9 1.1 4 .8 6 0 .3.2.5.4.6h.1c.2 0 .5-.2.5-.4.3-2.2 0-4.5-.9-6.5s-2.3-3.8-4.1-5.2c-.2-.2-.5-.1-.7.1-.2.2-.1.5.1.7 1.8 1.2 3 2.8 3.8 4.7zM66.8 18.2C68 21 68.4 24 68 27c0 .3.2.5.4.6h.1c.2 0 .5-.2.5-.4.4-3.2-.1-6.4-1.3-9.4-1.3-2.9-3.3-5.5-5.8-7.4-.2-.2-.5-.1-.7.1-.2.1-.2.5 0 .6 2.5 1.8 4.4 4.3 5.6 7.1zM71.4 16.2c1.6 3.7 2.1 7.7 1.6 11.7 0 .3.2.5.4.6h.1c.2 0 .5-.2.5-.4.5-4.1-.1-8.4-1.7-12.2-1.7-3.9-4.3-7.3-7.6-9.8-.2-.2-.5-.1-.7.1-.2.2-.1.5.1.7 3.2 2.4 5.7 5.6 7.3 9.3zM33 17.9c-4.6 4-7.4 9.9-7.4 16 0 .3.2.5.5.5s.5-.2.5-.5c0-5.7 2.6-11.4 7.1-15.2.2-.2.2-.5.1-.7-.3-.2-.6-.3-.8-.1z"
      fill="currentColor"
    />
    <path
      d="M60.6 41H60v-9.7c0-9.4-6.6-18-16-19.9V11c0-2.2-1.8-4-4-4s-4 1.8-4 4v.4c-9.4 2-16 10.6-16 19.9V41c0 12.6-4.1 16.9-5.9 21.4-.5 1.3.5 2.6 2 2.6h16.2c.9 3.4 4 6 7.7 6s6.8-2.6 7.7-6h.8c2.7 3.7 7.1 6.1 12 6.1 8.3 0 15-6.7 15-15S68.8 41 60.6 41zM40 11zM16.1 63c.3-.8.8-1.7 1.3-2.7 2-3.8 4.6-9 4.6-19.3v-9.6c0-10 8-18.2 17.9-18.3h.2C50 13.1 58 21.3 58 31.3v10c-7 1.2-12.4 7.4-12.4 14.8 0 2.5.6 4.9 1.7 6.9H16.1zM40 70c-3.2 0-5.8-2.1-6.7-5h13.4c-.9 2.8-3.5 5-6.7 5zm30.6-18.4L60.4 61.8c-.2.2-.5.3-.7.3s-.5-.1-.7-.3l-6.5-6.5c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l5.8 5.8 9.5-9.5c.4-.4 1-.4 1.4 0s.4 1 0 1.4z"
      fill="currentColor"
    />
  </g>
);

const getIconBySize = (size: number) => {
  switch (size) {
    default:
      return { viewBox: '0 0 80 80', icon: <IconSize /> };
  }
};

export default ({ classes = {}, size = 80 }: NotificationIcon) => {
  const { viewBox, icon } = getIconBySize(size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={classes.icon}
      width={size}
      height={size}
    >
      {icon}
    </svg>
  );
};
