// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';

type Props = {
  classes: any,
  title: string,
  description?: string,
};

const CardTitle = ({ classes, title, description }: Props) => (
  <div className={classes.root}>
    <h3 className={classes.title}>{title}</h3>
    {description && <p className={classes.description}>{description}</p>}
  </div>
);

const styles = () => ({
  root: {
    marginBottom: '1rem',
  },
  title: {
    fontSize: '1.25rem',
    height: '1.875rem',
    lineHeight: '1.875rem',
    margin: 0,
  },
  description: {
    fontSize: '0.75rem',
    height: '1.25rem',
    margin: 0,
  },
});

export default withStyles(styles)(CardTitle);
