// @flow

import * as React from 'react';
import { scaleOrdinal } from 'd3-scale';

type Props = {
  // data: any,
  oldData: any,
  scales: any,
  svgDimensions: any,
  margins: any,
  onMouseOverCallback: Function,
  onMouseLeaveCallback: Function,
};

export default class Bars extends React.Component<Props> {
  color = scaleOrdinal().range(['#3FBCF4', '#77EB85']);

  render() {
    const {
      // data,
      oldData,
      scales,
      svgDimensions,
      margins,
      onMouseOverCallback,
      onMouseLeaveCallback,
    } = this.props;
    const { x0Scale, x1Scale, yScale } = scales;
    const { height } = svgDimensions;

    const oldBars = oldData.map(item => (
      <g
        transform={`translate(${x0Scale(item.Month)}, 0)`}
        className="chartData"
        key={item.Month}
        onMouseOver={() => onMouseOverCallback(item)}
        onFocus={() => onMouseOverCallback(item)}
        onMouseLeave={() => onMouseLeaveCallback()}
      >
        {item.years.map(year => (
          <rect
            key={item.Month + year.name}
            x={x1Scale(year.name)}
            y={yScale(year.value)}
            width={x1Scale.bandwidth()}
            height={height - margins.bottom - margins.top - yScale(year.value)}
            fill={this.color(year.name)}
          />
        ))}
      </g>
    ));

    /*
    const bars = Object.entries(dataNew).map(([key, item]) => (
      <g
        transform={`translate(${x0Scale(key)}, 0)`}
        className="chartData"
        key={key}
        onMouseOver={() => onMouseOverCallback(item)}
        onFocus={() => onMouseOverCallback(item)}
        onMouseLeave={() => onMouseLeaveCallback()}
      >
        {Object.entries(item).map(([name, value]) => (
          <rect
            key={key + name}
            x={x1Scale(name)}
            y={yScale(value)}
            width={x1Scale.bandwidth()}
            height={height - margins.bottom - margins.top - yScale(value)}
            fill={this.color(name)}
          />
        ))}
      </g>
    )); */

    return (
      <g
        transform={`translate(${margins.left}, ${margins.top})`}
        className="bars"
      >
        {oldBars}
      </g>
    );
  }
}
