// @flow

import * as React from 'react';
import styles from './ListCell.module.css';
import classNames from '../../../utils/classNames';

type Props = {
  children: React.Node,
  extraClassNames?: string[],
  isControlCell?: boolean,
  withIcon?: boolean,
  onClick?: Function,
};

const ListCell = ({
  onClick,
  children,
  withIcon,
  extraClassNames = [],
}: Props) => (
  <div
    className={classNames(
      styles.listCell,
      withIcon ? styles.withIcon : undefined,
      ...extraClassNames
    )}
    tabIndex={0}
    onClick={onClick}
    role="button"
    onKeyDown={onClick}
  >
    {children}
  </div>
);

export default ListCell;
